import { SET_POSITION } from '../../actions/index.js';

export const initialState = {
  pinLat: null,
  pinLng: null,
};

export default (state = initialState, { type, ...rest }) => {
  if (type === SET_POSITION) {
    return {
      ...state,
      ...rest,
    };
  }

  return state;
};
