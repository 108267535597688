import React, { Fragment } from 'react';
import { func, oneOfType, shape, string, number } from 'prop-types';
import moment from 'moment-timezone';
import 'moment/locale/fi';
import Calendar from 'rc-calendar/lib/Calendar';
import fiFI from 'rc-calendar/lib/locale/fi_FI';
import Picker from 'rc-calendar/lib/Picker';
import styled from 'styled-components';
import { darken } from 'polished';
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints';
import DatePickerStyles from '../DateRangePicker/GlobalDatePickerStyles';
import {
  typeColor,
  dividerColor,
  inputThemes,
} from '../../constants/styles-old';
import CalendarIcon from '../../assets/calendar.svg';
import Input, { StyledLabel } from '../Input';

moment.tz.setDefault('Europe/Helsinki');
moment.locale('fi');
const PropTypes = {
  defaultValue: string,
  className: string,
  onChange: func,
  label: string,
  error: string,
  id: oneOfType([string, number]),
  theme: shape({}),
};

const DefaultProps = {
  defaultValue: null,
  className: null,
  onChange: null,
  label: '',
  error: null,
  id: null,
  theme: inputThemes.light,
};

const formatStr = 'DD.MM.YYYY';
const format = v => (v ? v.format(formatStr) : '');

const InputWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 244px;
  align-self: stretch;
  height: 100%;

  &:after {
    position: absolute;
    content: "";
    width: 24px;
    height: 100%;
    right: 10px;
    top: 1px;
    background-position: center 40%;
    background-repeat: no-repeat;
    pointer-events: none;
    background-image: url('${CalendarIcon}');
  }

  input {
    cursor: pointer;
  }

  ${mediaBreakpointDownSm`
    width: 100%;
    margin-bottom: 1rem;
  `}
`;

const LightInput = styled(Input)`
  background: white;
  border: 1px solid ${dividerColor};
  color: ${typeColor};
  height: 100%;

  &:focus {
    outline: 0;
    border-color: ${darken(0.1, dividerColor)};
  }
`;

const DatePicker = ({
  defaultValue,
  className,
  onChange,
  label,
  error,
  id,
  theme = inputThemes.light,
}) => (
  <div className={className}>
    <Fragment>
      {label ? (
        <StyledLabel error={error} htmlFor={id} theme={theme}>
          {label}
        </StyledLabel>
      ) : null}
      <DatePickerStyles />
      <Picker
        animation="slide-up"
        onChange={onChange}
        calendar={<Calendar locale={fiFI} />}
        defaultValue={defaultValue && moment(defaultValue)}
      >
        {({ value }) => (
          <InputWrapper>
            <LightInput
              placeholder="Valitse päivä"
              readOnly
              value={(value && `${format(moment(value))}`) || ''}
              className="ant-calendar-picker-input ant-input"
            />
          </InputWrapper>
        )}
      </Picker>
    </Fragment>
  </div>
);

DatePicker.propTypes = PropTypes;
DatePicker.defaultProps = DefaultProps;

export default DatePicker;
