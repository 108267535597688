import React from 'react';
import { func, shape } from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import EmptyStatePage from '../../components/EmptyStatePage';

const checkAccess = ({ Profile, validator }) => validator(Profile);

const PropTypes = {
  Profile: shape({}),
  validator: func.isRequired,
};

const DefaultProps = {
  Profile: null,
};

const ProtectedRoute = ({ Profile, validator, ...props }) => {
  if (!Profile) {
    return null;
  }

  return !checkAccess({ Profile, validator }) ? (
    <EmptyStatePage
      title="Pääsy estetty"
      description="Sinulla ei ole oikeutta tähän sivuun"
      link="/"
      linkText="Palaa alkuun"
    />
  ) : (
    <Route {...props} />
  );
};

ProtectedRoute.propTypes = PropTypes;
ProtectedRoute.defaultProps = DefaultProps;

const mapStateToProps = ({ Profile }) => ({ Profile });

export default connect(mapStateToProps)(ProtectedRoute);
