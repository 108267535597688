import React, { Fragment } from 'react';
import { Field } from 'formik';
import { string, shape, oneOf, oneOfType, number } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { StyledInput, StyledLabel, Message } from '../Input';
import { inputThemes } from '../../constants/styles-old';

const PropTypes = {
  label: string,
  id: oneOfType([string, number]),
  type: oneOf(['text', 'password', 'email', 'number']),
  error: string,
  theme: shape({}),
  values: shape({}),
  title: string,
  autoComplete: string,
};

const DefaultProps = {
  label: null,
  id: null,
  type: 'text',
  error: null,
  theme: inputThemes.light,
  values: null,
  title: null,
  autoComplete: 'on',
};

const StyledField = StyledInput.withComponent(Field);

const FieldInput = ({
  theme = inputThemes.light,
  label,
  id,
  type,
  error,
  values,
  title,
  autoComplete,
  ...rest
}) => (
  <ThemeProvider theme={theme}>
    <Fragment>
      {label && (
        <StyledLabel error={error} hidden={rest.hidden || false} htmlFor={id}>
          {label}
        </StyledLabel>
      )}
      <StyledField
        id={id}
        type={type}
        error={error}
        autoComplete={autoComplete}
        {...rest}
      />
      {error && <Message error={error}>{error}</Message>}
    </Fragment>
  </ThemeProvider>
);

FieldInput.propTypes = PropTypes;
FieldInput.defaultProps = DefaultProps;

export default FieldInput;
