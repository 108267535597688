export const UPDATE_SEARCH_PARAMETERS = 'UPDATE_SEARCH_PARAMETERS';
export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';

export const updateSearchParameters = value => ({
  type: UPDATE_SEARCH_PARAMETERS,
  value,
});

export const clearSearchParameters = () => ({
  type: CLEAR_SEARCH_PARAMETERS,
  value: '',
});
