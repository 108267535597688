import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
// import GoogleAnalytics from 'react-ga';
// import RealLogRocket from 'logrocket';
import createRootReducer from '../reducers/index.js';

// let LogRocket = RealLogRocket;

// const {
//   NODE_ENV,
//   REACT_APP_LOGROCKET_ENDPOINT,
//   // REACT_APP_GOOGLE_ANALYTICS_ID,
// } = process.env;

// if (NODE_ENV !== 'production') {
//   LogRocket = {
//     init: () => ({}),
//     reduxMiddleware: () => () => next => action => next(action),
//     captureException: () => ({}),
//     identify: () => ({}),
//   };
// }

// LogRocket.init(REACT_APP_LOGROCKET_ENDPOINT, {
//   network: {
//     requestSanitizer: request => {
//       // if the url contains 'callback'
//       if (request.url.toLowerCase().includes('callback')) {
//         // eslint-disable-next-line
//         request.url = request.url.split('callback')[0];
//       }

//       if (request.url.toLowerCase().includes('auth0') && request.body) {
//         if (typeof request.body === 'string') {
//           const body = JSON.parse(request.body);
//           body.password = null;
//           request.body = JSON.stringify(body);
//         } else {
//           request.body.password = null;
//         }
//       }

//       if (request.headers.Authorization) {
//         request.headers.Authorization = null;
//       }

//       if (request.headers.authorization) {
//         request.headers.authorization = null;
//       }

//       // otherwise log the request normally
//       return request;
//     },
//   },
// });

// GoogleAnalytics.initialize(REACT_APP_GOOGLE_ANALYTICS_ID);

export default (browserHistory) => {
  const middlewares = [
    (thunk.default ? thunk.default : thunk).withExtraArgument({
      // LogRocket,
      // GoogleAnalytics,
    }),
    // LogRocket.reduxMiddleware(),
  ];

  const composer =
    process.env.NODE_ENV !== 'production' ? composeWithDevTools : compose;

  return createStore(
    createRootReducer(browserHistory),
    composer(applyMiddleware(...middlewares)),
  );
};
