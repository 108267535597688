/* eslint-disable react/no-array-index-key */

import { Box, Flex } from '@rebass/grid';
import { FieldArray, Formik } from 'formik';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { arrayOf, bool, func, shape, string, number } from 'prop-types';
import React, { Fragment } from 'react';
import MediaQuery from 'react-responsive';
import styled, { ThemeProvider } from 'styled-components';
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints';
import Yup from 'yup';

import AddIcon from './AddIcon';
import Notification from './Notification';
import RemoveIcon from './RemoveIcon';

import Button from '../../HelenButton';
import { Heading1, Heading6 } from '../../HelenHeading';
import Input from '../../HelenInput';
import Select from '../../HelenSelect';
import RadioButtonGroup from '../../HelenRadio';
import { TextButton } from '../../TextButton';

import { colors, themes } from '../../../constants/styles';
import {
  findPriceGroup,
  roundToDecimalsLocale,
} from '../../../lib/math-helpers';

const formBreakpoint = 640;

const Background = styled.div`
  background: transparent;
  padding-bottom: 5rem;
  padding-top: 0rem;
  width: 100%;
  margin-top: -8.75rem;

  ${mediaBreakpointDownMd`
    margin-top: 2rem;
    padding-bottom: 0rem;
  `};
`;

const Container = styled.div`
  background: white;
  font-family: ${colors.helenFontFamilySansSerif};
  margin-left: auto;
  margin-right: auto;
  max-width: 913px;
  padding: 5rem 5rem 2.5rem 5rem;

  ${mediaBreakpointDownMd`
    padding: 2.5rem;
  `};

  @media (max-width: ${formBreakpoint}px) {
    padding: 1rem 1rem 1.5rem 1rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3.5rem;
  padding-right: 38px;

  ${mediaBreakpointDownMd`
    margin-bottom: 2rem;
  `};

  @media (max-width: ${formBreakpoint}px) {
    padding-right: 0;
  }
`;

const TitleBallot = styled.div`
  align-self: center;
  background-color: #ffc807;
  border-radius: 50%;
  color: white;
  flex-shrink: 0;
  font-size: 1.25rem;
  font-weight: 700;
  height: 2rem;
  padding-left: 12px;
  padding-top: 1px;
  width: 2rem;
`;

const TitleText = styled(Heading1)`
  align-self: center;
  color: ${colors.helenTypeColorLight};
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 0rem;
  margin-left: 1.25rem;
  margin-top: 0.15rem;
`;

const SubSection = styled.div`
  margin-top: 1.25rem;
  width: 100%;

  @media (max-width: ${formBreakpoint}px) {
    padding-right: 0 !important;
  }
`;

const SubSectionTitle = styled(Heading6)`
  color: ${colors.helenTypeColorLight};
  font-size: 1.125rem;
  text-transform: uppercase;
`;

const SubSectionSubTitle = styled.div`
  color: ${colors.helenTypeColorLight};
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 0.75rem;
`;

const Divider = styled.div`
  border-top: 1px solid ${colors.helenGrayLight};
  margin-top: 2rem;
  padding-top: 1.5rem;
  width: 100%;
`;

const Form = styled.form`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${props => props.heightProp || 'auto'};
  max-width: ${props => props.maxWidthProp || '100%'};
  overflow: ${props => props.overflowProp || 'initial'};
  width: ${props => props.widthProp || 'auto'};

  label {
    letter-spacing: -0.67px;
  }
`;

const AddUser = styled.div`
  cursor: pointer;
  display: flex;

  svg {
    align-self: center;
  }

  span {
    align-self: center;
    color: ${colors.helenAltTextLinkColor};
    font-weight: 500;
    margin-left: 0.75rem;

    &:hover {
      color: ${colors.helenAltTextLinkColorHover};
    }
  }
`;

const RemoveUser = styled.div`
  cursor: pointer;
  display: flex;

  svg {
    align-self: center;
  }

  span {
    align-self: center;
    color: ${colors.helenAltTextLinkColor};
    font-weight: 500;
    margin-left: 0.5rem;

    &:hover {
      color: ${colors.helenAltTextLinkColorHover};
    }
  }
`;

const TextAlert = styled.div`
  color: ${colors.helenBrandDangerColor};
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const NextButton = styled(Button)`
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  padding: 0.875rem 2.5rem;
  background-color: ${colors.helenBackground};
  border-radius: 25px;
  align-self: center;
  &:hover,
  &:active {
    background-color: ${props =>
      !props.outline ? colors.helenPrimaryHover : colors.helenSecondaryHover};
    border-color: ${props =>
      props.outline ? colors.helenBorder : colors.helenBackground};
    color: ${colors.helenBackground};
    text-decoration: none;
  }
`;

const Field = styled.div`
  margin-bottom: 0;
  width: 400px;

  ${mediaBreakpointDownMd`
    max-width: 400px;
    width: auto;
  `};
`;

const FieldLabel = styled.label`
  color: ${colors.helenTypeColorLight};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
`;

const FieldValue = styled.div`
  color: ${colors.helenTypeColorLight};
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.5;

  ul {
    margin-block-start: 5px;
    margin-bottom: 0;
    padding-inline-start: 1.125rem;
  }

  li {
    margin-block-end: 0.5rem;
    margin-block-start: 0.25rem;
  }
`;

const enhanceField = enhancements => ({ id, ...rest }) => ({
  ...rest,
  id,
  info: enhancements[id] && enhancements[id].info,
  label: enhancements[id] && enhancements[id].label,
  placeholder: enhancements[id] && enhancements[id].placeholder,
});

export const housingCompanyFields = (fields, quotationUuid) =>
  [
    {
      id: 'housingCompanyName',
      required: true,
    },
    {
      id: 'housingCompanyAddress',
      required: true,
    },
    {
      id: 'housingCompanyZip',
      required: true,
    },
    {
      id: 'housingCompanyCity',
      required: true,
    },
    {
      id: 'managerName',
      required: true,
    },
    {
      id: 'apartmentCount',
      required: true,
      type: 'number',
      min: 0,
      max: 10000,
    },
    {
      disabled: !!quotationUuid,
      id: 'meterCount',
      required: !quotationUuid,
      type: 'number',
      min: 0,
      max: 999999,
    },
  ].map(enhanceField(fields));

const billingFields = ({
  meterCount,
  annualPrice,
  baseFee,
  meterBaseFee,
  fields,
  monthlyPrice,
}) =>
  [
    {
      id: 'prices',
      type: 'static',
      pb: ['20px', '0px'],
      value: fields.prices.value
        .replace('$annualPrice', annualPrice)
        .replace('$monthlyPrice', roundToDecimalsLocale(monthlyPrice))
        .replace('$monthlyPriceAlv', roundToDecimalsLocale(monthlyPrice * 1.24))
        .replace('$baseFee', roundToDecimalsLocale(baseFee))
        .replace('$baseFeeAlv', roundToDecimalsLocale(Number(baseFee) * 1.24))
        .replace(
          '$meterBaseFee',
          roundToDecimalsLocale(Number(meterBaseFee) * meterCount),
        )
        .replace(
          '$meterBaseFeeAlv',
          roundToDecimalsLocale(Number(meterBaseFee) * meterCount * 1.24),
        ),
    },
    {
      id: 'priceIncludes',
      type: 'static',
      pb: ['20px', '24px'],
      value: fields.priceIncludes.value,
    },
    {
      id: 'contractPeriod',
      type: 'static',
      pb: ['20px', '24px'],
      value: fields.contractPeriod.value,
    },
    {
      id: 'priceIncludes-right',
      type: 'empty',
    },
    {
      defaultValue: 0,
      id: 'isHelenCustomer',
      options: [{ name: 'Ei', value: 0 }, { name: 'Kyllä', value: 1 }],
      required: true,
      type: 'select',
    },
    {
      id: 'companyId',
      required: true,
    },
    {
      id: 'billingAddress',
      required: true,
    },
    {
      id: 'heatingGroupId',
      required: false,
    },
  ]
    .filter(Boolean)
    .map(enhanceField(fields));

export const ordererFields = (fields, roleOptions) =>
  [
    {
      id: 'ordererFirstName',
      required: true,
    },
    {
      id: 'ordererLastName',
      required: true,
    },
    {
      id: 'ordererEmail',
      required: true,
      type: 'email',
    },
    {
      id: 'ordererPhone',
      required: true,
    },
    {
      defaultValue: 'manager',
      id: 'ordererRole',
      options: roleOptions,
      required: true,
      type: 'select',
    },
    {
      id: 'ordererAddress',
      required: true,
    },
    {
      id: 'ordererZip',
      required: true,
    },
    {
      id: 'ordererCity',
      required: true,
    },
  ].map(enhanceField(fields));

const userFields = (fields, roleOptions) =>
  [
    {
      id: 'email',
    },
    {
      defaultValue: 'board',
      id: 'role',
      options: roleOptions,
      required: true,
      type: 'select',
    },
  ].map(enhanceField(fields));

const subSections = ({
  meterCount,
  annualPrice,
  baseFee,
  meterBaseFee,
  formFields,
  monthlyPrice,
  quotationUuid,
  roleOptions,
  sectionTitles,
  values,
}) =>
  [
    {
      fields: housingCompanyFields(formFields, quotationUuid),
      id: 'housingCompany',
      subtitle: sectionTitles.housingCompany.subtitle,
      title: sectionTitles.housingCompany.title,
    },
    {
      fields: billingFields({
        meterCount,
        annualPrice,
        baseFee,
        meterBaseFee,
        fields: formFields,
        monthlyPrice,
        values,
      }),
      id: 'billing',
      subtitle: sectionTitles.billing.subtitle,
      title: sectionTitles.billing.title,
    },
    {
      fields: ordererFields(formFields, roleOptions),
      id: 'orderer',
      subtitle: sectionTitles.orderer.subtitle,
      title: sectionTitles.orderer.title,
      divider: false,
    },
  ].filter(Boolean);

const validationShape = ({
  formFieldErrorText,
  formFields,
  roleOptions,
  sectionTitles,
}) =>
  subSections({
    formFields,
    roleOptions,
    sectionTitles,
  }).reduce((acc, section) => {
    section.fields.forEach(({ id, required, type }) => {
      if (type === 'number') {
        acc[id] = Yup.number()
          .min(1, 'Arvon tulee olla suurempi kuin yksi')
          .max(1000000, 'Arvo ei voi olla suurempi kuin 1000000')
          .integer(formFieldErrorText)
          .required(formFieldErrorText)
          .strict();
      } else if (type === 'email') {
        acc[id] = Yup.string()
          .email(formFieldErrorText)
          .required(formFieldErrorText);
      } else if (required) {
        acc[id] = Yup.string().required(formFieldErrorText);
      } else {
        acc[id] = Yup.string();
      }
    });
    return acc;
  }, {});

const validationSchema = ({
  formFieldErrorText,
  formFields,
  roleOptions,
  sectionTitles,
}) =>
  Yup.object().shape({
    ...validationShape({
      formFieldErrorText,
      formFields,
      roleOptions,
      sectionTitles,
    }),
    otherUsers: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().email(formFieldErrorText),
        role: Yup.string().required(),
      }),
    ),
  });

const boxWidths = [1, 0.5];
const boxSpacings = [0, 25];
const boxPaddings = ['14px', '24px'];

const getBoxPadding = (idx, pb) => {
  if (idx % 2 === 0) {
    return { pl: 0, pr: boxSpacings, pb: pb || boxPaddings };
  }

  return { pl: boxSpacings, pr: 0, pb: pb || boxPaddings };
};

const addUser = array => {
  array.push({
    email: '',
    role: 'board',
  });
};

const renderField = ({
  id,
  index,
  initialValues,
  pb,
  setFieldValue,
  type,
  width,
  values,
  ...rest
}) => {
  switch (type) {
    case 'select': {
      return (
        <Box key={id} width={width} {...getBoxPadding(index, pb)}>
          <Select
            {...rest}
            id={id}
            theme={themes.helenBrandInputTheme}
            onChange={e => {
              setFieldValue(id, e.value);
            }}
          />
        </Box>
      );
    }
    case 'radio': {
      return (
        <Box key={id} width={width} {...getBoxPadding(index, pb)}>
          <RadioButtonGroup
            {...rest}
            selectedValue={values[id] || initialValues[id]}
            id={id}
            onChange={e => {
              setFieldValue(id, e.target.value);
            }}
          />
        </Box>
      );
    }
    case 'empty':
      return (
        <MediaQuery key={id} minWidth={formBreakpoint}>
          <Box width={width} {...getBoxPadding(index, pb)} />
        </MediaQuery>
      );
    case 'static':
      return (
        <Box key={id} width={width} {...getBoxPadding(index, pb)}>
          <Field key={id}>
            {rest.label && <FieldLabel>{rest.label}</FieldLabel>}
            <FieldValue dangerouslySetInnerHTML={{ __html: rest.value }} />
          </Field>
        </Box>
      );
    default:
      return (
        <Box key={id} width={width} {...getBoxPadding(index, pb)}>
          <Input
            id={id}
            type={type}
            {...rest}
            theme={themes.helenBrandInputTheme}
          />
        </Box>
      );
  }
};

renderField.propTypes = {
  id: string.isRequired,
  index: number.isRequired,
  initialValues: arrayOf(shape({})),
  pb: number,
  setFieldValue: func.isRequired,
  type: string,
  width: number,
  values: arrayOf(shape({})),
};

renderField.defaultProps = {
  initialValues: [],
  pb: 0,
  type: 'text',
  width: 1,
  values: [],
};

const FormSection = ({
  expiredDesc,
  expiredTitle,
  formFieldErrorText,
  formFields,
  formInvalidErrorText,
  formSections: sectionTitles,
  formSubmitButtonText,
  formTitle,
  formUsersAddButtonText,
  formUsersRemoveButtonText,
  hasExpired,
  initialValues,
  isInitialValid,
  onSubmit,
  quotationUuid,
  roleOptions,
  priceGroups,
}) => (
  <Background>
    <Container>
      {hasExpired && (
        <Notification title={expiredTitle} description={expiredDesc} />
      )}
      <TitleContainer>
        <TitleBallot>1</TitleBallot>
        <TitleText>{formTitle}</TitleText>
      </TitleContainer>
      <Formik
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        validationSchema={validationSchema({
          formFieldErrorText,
          formFields,
          roleOptions,
          sectionTitles,
        })}
        onSubmit={onSubmit}
        render={({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          touched,
          values,
        }) => {
          const { meterCount, baseFee, meterBaseFee, monthlyBaseFee } = values;
          const {
            monthlyFee: normalMonthlyBaseFee,
            baseFee: normalBaseFee,
          } = findPriceGroup(priceGroups, meterCount);

          const monthlyPrice = monthlyBaseFee || normalMonthlyBaseFee;
          const annualPrice = monthlyPrice * 12;

          return (
            <ThemeProvider theme={{}}>
              <Form onSubmit={handleSubmit}>
                {subSections({
                  meterCount,
                  annualPrice,
                  baseFee: baseFee || normalBaseFee,
                  meterBaseFee,
                  monthlyPrice,
                  formFields,
                  quotationUuid,
                  roleOptions,
                  sectionTitles,
                  values,
                }).map(
                  ({ divider, fields, id: sectionId, subtitle, title }) => (
                    <SubSection
                      key={sectionId}
                      style={{ paddingRight: '38px' }}
                    >
                      <SubSectionTitle>{title}</SubSectionTitle>
                      {subtitle && (
                        <SubSectionSubTitle>{subtitle}</SubSectionSubTitle>
                      )}
                      <Flex
                        alignItems="stretch"
                        flexDirection="row"
                        flexWrap="wrap"
                        justifyContent="space-between"
                        mt={['1.5rem', '2rem']}
                      >
                        {fields.map(({ defaultValue, id, ...rest }, index) =>
                          renderField({
                            ...rest,
                            defaultValue: initialValues[id] || defaultValue,
                            error: touched[id] && errors[id],
                            id,
                            index,
                            initialValues,
                            name: id,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            setFieldValue,
                            touched: touched[id],
                            width: boxWidths,
                            values,
                          }),
                        )}
                      </Flex>
                      {divider && <Divider />}
                    </SubSection>
                  ),
                )}
                <SubSection key="otherUsers">
                  <SubSectionTitle>
                    {sectionTitles.otherUsers.title}
                  </SubSectionTitle>
                  <SubSectionSubTitle>
                    {sectionTitles.otherUsers.subtitle}
                  </SubSectionSubTitle>
                  <div style={{ marginTop: '2rem' }}>
                    <FieldArray
                      name="otherUsers"
                      render={array => (
                        <Fragment>
                          {values.otherUsers.map((user, userIdx) => (
                            <Flex
                              alignItems="stretch"
                              flexDirection="row"
                              flexWrap="wrap"
                              justifyContent="space-between"
                              key={userIdx}
                            >
                              {userFields(formFields, roleOptions).map(
                                (
                                  { defaultValue, id, type, ...rest },
                                  index,
                                ) => {
                                  const path = `otherUsers.${userIdx}.${id}`;
                                  const initialValue = get(initialValues, path);
                                  const fieldTouched = get(touched, path);
                                  const fieldError = get(errors, path);
                                  return renderField({
                                    ...rest,
                                    defaultValue:
                                      type === 'select'
                                        ? initialValue || defaultValue
                                        : undefined,
                                    error: fieldTouched && fieldError,
                                    id: path,
                                    index,
                                    name: path,
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                    setFieldValue,
                                    touched: fieldTouched,
                                    type,
                                    value: user[id],
                                    width: [1, 0.475],
                                  });
                                },
                              )}
                              <Box
                                key={`${userIdx}-remove`}
                                pb={[28, 0]}
                                pl={['2px', 15]}
                                pt={[0, 40]}
                                width={[1, 0.05]}
                              >
                                <RemoveUser
                                  onClick={() => array.remove(userIdx)}
                                  onKeyUp={e => {
                                    if (e.keyCode === 13) {
                                      array.remove(userIdx);
                                    }
                                  }}
                                  role="button"
                                  tabIndex={0}
                                >
                                  <RemoveIcon />
                                  <MediaQuery maxWidth={formBreakpoint}>
                                    <TextButton
                                      onClick={() => array.remove(userIdx)}
                                      role="button"
                                    >
                                      {formUsersRemoveButtonText}
                                    </TextButton>
                                  </MediaQuery>
                                </RemoveUser>
                              </Box>
                            </Flex>
                          ))}
                          <AddUser>
                            <AddIcon
                              onClick={() => addUser(array)}
                              role="button"
                            />
                            <TextButton
                              onClick={() => addUser(array)}
                              role="button"
                            >
                              {formUsersAddButtonText}
                            </TextButton>
                          </AddUser>
                        </Fragment>
                      )}
                    />
                  </div>
                </SubSection>
                <Divider />
                <NextButton
                  disabled={!isValid || isSubmitting || !isEmpty(errors)}
                  secondary
                  type="submit"
                >
                  {formSubmitButtonText}
                </NextButton>
                {!isValid && (
                  <TextAlert role="alert">{formInvalidErrorText}</TextAlert>
                )}
              </Form>
            </ThemeProvider>
          );
        }}
      />
    </Container>
  </Background>
);

FormSection.propTypes = {
  expiredDesc: string.isRequired,
  expiredTitle: string.isRequired,
  formFieldErrorText: string.isRequired,
  formFields: shape({}).isRequired,
  formInvalidErrorText: string.isRequired,
  formSections: shape({}).isRequired,
  formSubmitButtonText: string.isRequired,
  formTitle: string.isRequired,
  formUsersAddButtonText: string.isRequired,
  formUsersRemoveButtonText: string.isRequired,
  hasExpired: bool.isRequired,
  initialValues: shape({}).isRequired,
  isInitialValid: bool.isRequired,
  onSubmit: func.isRequired,
  quotationUuid: string,
  roleOptions: arrayOf(shape({})).isRequired,
  priceGroups: arrayOf(shape({})).isRequired,
};

FormSection.defaultProps = {
  quotationUuid: null,
};

export default FormSection;
