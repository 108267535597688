import React from 'react';

const Logout = props => (
  <svg
    alt="LogOut"
    width="23"
    height="27"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 16H8v-5h6V8l8.794 6.088L14 19.5V16zm3 4.017l3-1.984V25a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v7.533l-3-2.266V3H3v21h14v-3.983z"
      fillRule="evenodd"
    />
  </svg>
);

export default Logout;
