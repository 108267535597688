export default doc => {
  if (doc.type === 'faq') {
    return `/faq/${doc.uid}`;
  }

  if (doc.type === 'order_form') {
    return `/order`;
  }

  if (doc.type === 'quotation_form') {
    return `/quotation`;
  }

  return '/';
};
