import { SET_USER_PROFILE, REMOVE_USER_PROFILE } from '../../actions/index.js';

const initialState = null;

export default (state = initialState, action) => {
  const { type, profile } = action;

  if (type === SET_USER_PROFILE) {
    return {
      ...state,
      ...profile,
    };
  }

  if (type === REMOVE_USER_PROFILE) {
    return null;
  }

  return state;
};
