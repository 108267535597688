import React from 'react';
import styled from 'styled-components';
import {
  arrayOf,
  shape,
  number,
  string,
  func,
  oneOfType,
  element,
} from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Pin from '../../Pin';
import {
  brandColor,
  fontSizeSmall,
  fontFamilySansSerif,
  tableThemes,
  typeColor,
  typeLightColor,
  RowHoverFocus,
  white,
} from '../../../constants/styles-old';
import colors from '../../../constants/chart-colors';
import NotificationsIcon from '../../NotificationsIcon';

import { getStaircasesPath } from '../../../lib/path-helpers';
import { nbsp } from '../../../lib/utils';

const rowRendererPropTypes = {
  columns: arrayOf(element).isRequired,
  index: number.isRequired,
  onRowClick: func,
  onRowDoubleClick: func,
  onRowMouseOut: func,
  onRowMouseOver: func,
  onRowRightClick: func,
  rowData: shape({}).isRequired,
};

const rowRendererDefaultProps = {
  onRowClick: null,
  onRowDoubleClick: null,
  onRowMouseOut: null,
  onRowMouseOver: null,
  onRowRightClick: null,
};

const cellRendererPropTypes = {
  dataKey: string.isRequired,
  cellData: oneOfType([string, number]),
  rowIndex: number.isRequired,
};

const cellRendererDefaultProps = {
  cellData: null,
};

const headerRowRendererPropTypes = {
  className: string.isRequired,
  columns: arrayOf(string).isRequired,
  style: shape({
    width: number,
  }).isRequired,
};

const StyledId = styled.span`
  display: flex;
  user-select: text;
  cursor: text;

  &:before {
    content: '•';
    font-size: 1.75rem;
    position: absolute;
    left: ${(props) => (props.isLevelColor ? '16px' : '0')};
    top: 8px;
    line-height: 1rem;
    color: ${(props) => props.color};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${(props) => (props.isActive ? white : props.theme.rowColor)};
  background-color: ${(props) =>
    props.isActive ? brandColor : props.theme.rowBackgroundColor};
  font-family: ${fontFamilySansSerif};
  font-size: ${fontSizeSmall};
  padding-left: 1.25rem;
  white-space: nowrap;
  border-bottom: 1px solid ${(props) => props.theme.rowBorderBottom};
  border-right: 1px solid ${(props) => props.theme.rowBorderBottom};
  border-left: 1px solid ${(props) => props.theme.rowBorderBottom};
  cursor: ${(props) => props.cursor || 'default'};

  div.address {
    font-size: 0.75rem;
  }

  div.value {
    font-size: 0.625rem;
    color: ${(props) => (props.isActive ? white : typeLightColor)};
  }

  div.changed {
    font-size: 0.625rem;
    color: ${(props) => (props.isActive ? white : typeLightColor)};
  }

  &:hover,
  &:focus {
    color: ${(props) => (props.isActive ? white : brandColor)};
    background-color: ${(props) =>
      props.isActive ? brandColor : RowHoverFocus};
    outline: none;

    div.value {
      color: ${(props) => (props.isActive ? white : brandColor)};
    }

    div.changed {
      color: ${(props) => (props.isActive ? white : brandColor)};
    }
  }
`;

Row.defaultProps = {
  theme: tableThemes.light,
};

const PinWrapper = styled.div`
  display: ${(props) => (props.isActive ? 'inline-block' : 'none')};
  padding-right: 0.5rem;
  margin-top: 0.15rem;

  svg {
    fill: #f5f5f5;
  }
`;

const Header = styled.div`
  color: ${typeColor};
  width: ${(props) => props.width}px;
  justify-content: space-between;
  font-size: ${fontSizeSmall};
  line-height: ${fontSizeSmall};
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

Header.defaultProps = {
  theme: tableThemes.light,
};

export const cellRenderer = ({
  cellData,
  dataKey,
  rowData: { staircase, apartmentNumber },
}) => {
  if (!cellData && cellData !== 0) {
    return '';
  }

  const color =
    colors[parseInt(`${staircase}${apartmentNumber}`, 36) % colors.length];

  if (dataKey === 'deviceId') {
    return (
      <StyledId color={color} data-cy-device-id={cellData}>
        {cellData}
        {nbsp}
      </StyledId>
    );
  }

  if (dataKey === 'levelColor') {
    return <StyledId isLevelColor color={cellData} />;
  }

  if (
    ['temperature', 'mean', 'recommendation', 'waterTempAdjustment'].includes(
      dataKey,
    )
  ) {
    return `${cellData.toFixed(1)}${nbsp}°C`;
  }

  if (
    [
      'bucket',
      'weightedMean',
      'temperatureDiff',
      'outsideTemperature',
      'waterTemperatureDiff',
      'average',
      'optimalWaterTemperature',
      'currentWaterTemperature',
    ].includes(dataKey)
  ) {
    return `${Number(cellData).toFixed(1) || 0}${nbsp}°C`;
  }

  if (dataKey === 'humidity') {
    return `${cellData} %`;
  }

  if (dataKey === 'createdAt') {
    return `${moment(cellData).format('DD.MM.YYYY HH:mm')}`;
  }

  if (dataKey === 'amountOfNotifications') {
    return <NotificationsIcon>{cellData}</NotificationsIcon>;
  }

  if (dataKey === 'changeCount') {
    return cellData > 1 ? <FontAwesomeIcon icon={faCheck} /> : <span />;
  }

  return String(cellData);
};

cellRenderer.propTypes = cellRendererPropTypes;
cellRenderer.defaultProps = cellRendererDefaultProps;

export const headerRowRenderer = ({ className, columns, style }) => (
  <Header className={className} role="row" width={style.width}>
    {columns}
  </Header>
);

headerRowRenderer.propTypes = headerRowRendererPropTypes;

export const rowRenderer = (
  {
    columns,
    index,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    ...rest
  },
  pathname,
) => {
  const a11yProps = {};
  if (
    onRowClick ||
    onRowDoubleClick ||
    onRowMouseOut ||
    onRowMouseOver ||
    onRowRightClick
  ) {
    a11yProps['aria-label'] = 'row';
    a11yProps.tabIndex = 0;
    if (onRowClick) {
      a11yProps.onClick = () => onRowClick({ index, rowData });
    }
    if (onRowDoubleClick) {
      a11yProps.onDoubleClick = () => onRowDoubleClick({ index, rowData });
    }
    if (onRowMouseOut) {
      a11yProps.onMouseOut = () => onRowMouseOut({ index, rowData });
    }
    if (onRowMouseOver) {
      a11yProps.onMouseOver = () => onRowMouseOver({ index, rowData });
    }
    if (onRowRightClick) {
      a11yProps.onContextMenu = () => onRowRightClick({ index, rowData });
    }
  }

  const [
    {
      props: { title },
    },
  ] = columns;

  const pathArray = (pathname && pathname.split('/')) || [];
  const isActive =
    rowData &&
    pathArray.includes(rowData.slug) &&
    pathArray.includes(getStaircasesPath(rowData.staircases));

  return (
    <Row
      {...a11yProps}
      {...rest}
      role="row"
      isActive={isActive}
      data-cy-title={title}
    >
      <PinWrapper isActive={isActive}>
        <Pin />
      </PinWrapper>
      {columns}
    </Row>
  );
};

rowRenderer.propTypes = rowRendererPropTypes;
rowRenderer.defaultProps = rowRendererDefaultProps;
