import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import Yup from 'yup';
import gql from 'graphql-tag';
import Forms, { formCustomStatuses } from '../../components/Forms';
import { successToast } from '../../components/Toastr';
import showError from '../../lib/ShowError';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Täytä sähköposti'),
  phone: Yup.string(),
  moreInfo: Yup.string(),
});

const submitForm = gql`
  mutation submitForm($email: String!, $phone: String, $moreInfo: String) {
    submitForm(input: { email: $email, phone: $phone, moreInfo: $moreInfo })
      @rest(type: "Form", path: "/contact", method: "POST") {
      email
      phone
      moreInfo
    }
  }
`;

const getFormFields = ({
  emailPlaceholder,
  phonePlaceholder,
  moreInfoPlaceholder,
  emailLabel,
  phoneLabel,
  moreInfoLabel,
}) => [
  {
    id: 'email',
    label: `${emailLabel}*`,
    placeholder: emailPlaceholder,
    width: 1,
  },
  {
    id: 'phone',
    label: phoneLabel,
    placeholder: phonePlaceholder,
    width: 1,
  },
  {
    id: 'moreInfoPlaceholder',
    label: moreInfoLabel,
    placeholder: moreInfoPlaceholder,
    type: 'textarea',
    width: 1,
  },
];

export default compose(
  graphql(submitForm, {
    name: 'submitFormMutation',
    props: ({ submitFormMutation, ownProps }) => ({
      ...ownProps,
      formFields: getFormFields(ownProps),
      buttonText: ownProps.send,
      handleSubmit: async (formProps, setSubmitting, setStatus) => {
        try {
          await submitFormMutation({
            variables: {
              ...formProps,
            },
          });
          successToast(ownProps.submitSuccess);
          setStatus(formCustomStatuses.submitted);
        } catch (err) {
          showError(err, ownProps.submitError);
          setSubmitting(false);
        }
      },
    }),
  }),
)(Forms(validationSchema, true));
