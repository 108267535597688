import React from 'react';
import { Formik, Field, FieldArray } from 'formik';
import { string, func, shape, number, arrayOf, bool } from 'prop-types';
import styled from 'styled-components';
import { orderByDistance } from 'geolib';

import Checkbox from '../HelenCheckbox';
import AddressInput from '../AddressInput';
import { roundToDecimalsLocale } from '../../lib/math-helpers';
import { AbsoluteLoadingSpinner } from '../LoadingSpinner';
import Button from '../Button';
import { infoToast } from '../Toastr';

const Container = styled.div`
  height: 100%;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  height: auto;
  width: auto;
  overflow: initial;
  max-width: 50%;
`;

const StyledBox = styled.div`
  padding: 1rem;
`;
const StyledAddress = styled(AddressInput)`
  padding-bottom: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const checkDisabled = (initialValues, { value: { fmisid: id, active } }) => {
  const org = initialValues?.observationStations?.find(
    ({ fmisid }) => fmisid === id,
  );
  return active && org.active === active;
};

const CustomInputComponent = ({
  field,
  form: { setFieldValue, dirty, initialValues },
}) => {
  return (
    <Checkbox
      checked={field.value.active}
      id={field.name}
      label={field.value.name}
      disabled={checkDisabled(initialValues, field)}
      onChange={() => {
        setFieldValue(field.name, {
          ...field.value,
          active: !field.value.active,
        });
      }}
      onClick={() => {
        if (field.value.active && !dirty) {
          infoToast('Tiedoksi', 'Sääasemia voidaan vain aktivoida');
        }
      }}
    />
  );
};

CustomInputComponent.propTypes = {
  field: shape({}).isRequired,
  form: shape({}).isRequired,
};

const orderStations = (latLng, stations) => {
  const ordered = orderByDistance(
    latLng,
    stations.map(i => ({
      latitude: i.lat,
      longitude: i.lng,
    })),
  );
  return ordered.map(({ key, distance }) => ({
    ...stations[Number(key)],
    distance,
  }));
};

const handleChange = ({ setFieldValue }, stations) => latLng => {
  setFieldValue('observationStations', orderStations(latLng, stations));
  return false;
};

const EditObservationStationForm = ({
  observationStations,
  streetName,
  latLng,
  loading,
  buttonText,
  onSubmit,
}) => {
  if (loading) {
    return <AbsoluteLoadingSpinner />;
  }

  const observationStationsOrdered = latLng
    ? orderStations(latLng, observationStations)
    : observationStations;

  return (
    <Container>
      <Formik
        initialValues={{
          observationStations: observationStationsOrdered,
          streetName,
          latLng,
        }}
        onSubmit={onSubmit}
        render={({ values, handleSubmit, isValid, isSubmitting, ...rest }) => {
          return (
            <StyledForm onSubmit={handleSubmit}>
              <FieldArray
                name="observationStations"
                render={() => {
                  return (
                    <StyledBox key="box1">
                      <>
                        <StyledAddress
                          label={`Etsi lähin havaintoasema`}
                          id="streetName"
                          defaultValue={streetName}
                          placeholder={'Esimerkkikatu 5'}
                          onChange={() => {}}
                          onSelect={handleChange(
                            rest,
                            values.observationStations,
                          )}
                          {...rest}
                        />
                        {values.observationStations.map((station, index) => (
                          <Row key={`row-${index + 1}`}>
                            <Field
                              component={CustomInputComponent}
                              name={`observationStations.${index}`}
                            />
                            <div>{`${
                              station.distance
                                ? `${roundToDecimalsLocale(
                                    station.distance / 1000,
                                    2,
                                  )}km`
                                : ''
                            }`}</div>
                          </Row>
                        ))}
                        <StyledBox>
                          <Button
                            disabled={
                              values.observationStations.length === 0 ||
                              !isValid ||
                              isSubmitting
                            }
                            type="submit"
                          >
                            {buttonText}
                          </Button>
                        </StyledBox>
                      </>
                    </StyledBox>
                  );
                }}
              />
            </StyledForm>
          );
        }}
      />
    </Container>
  );
};

EditObservationStationForm.propTypes = {
  observationStations: arrayOf(
    shape({
      name: string,
      fmisid: number,
    }),
  ),
  onSubmit: func.isRequired,
  streetName: string,
  latLng: shape({
    lat: number,
    lng: number,
  }),
  buttonText: string.isRequired,
  loading: bool,
};
EditObservationStationForm.defaultProps = {
  observationStations: [],
  streetName: null,
  latLng: null,
  loading: false,
};
export default EditObservationStationForm;
