import React from 'react';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import styled from 'styled-components';
import {
  fontFamilySansSerif,
  fontSizeSmall,
  green,
  red,
  blue,
  white,
  brandDangerColor,
  brandDangerColorHover,
  brandDangerColorFocus,
  brandColor,
  brandColorHover,
  brandColorFocus,
  typeColor,
} from '../../constants/styles-old';
import SuccessIcon from './Icons/Success';
import ErrorIcon from './Icons/Error';
import InfoIcon from './Icons/Info';

export default styled(ReduxToastr)`
  > span {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  & .top-center {
    width: 96%;
    max-width: 475px;
    margin-left: unset;
    margin-right: unset;
    left: unset;
  }

  & .toastr {
    min-height: 40px;
    border-radius: 3px;
    box-shadow: none;

    &.rrt-success {
      background-color: ${green};
    }

    &.rrt-error {
      background-color: ${red};
    }

    &.rrt-info {
      background-color: ${blue};
    }
  }

  & .toastr .rrt-left-container,
  & .toastr .rrt-middle-container,
  & .toastr .rrt-right-container {
    width: auto;
    position: static;
  }

  & .toastr .rrt-left-container .rrt-holder {
    width: 32px;
    left: 10px;
    margin-top: 0;
    height: 40px;
  }

  & .toastr.rrt-success .rrt-left-container .rrt-holder {
    top: -2%;
  }

  & .toastr.rrt-error .rrt-left-container .rrt-holder {
    top: -6%;
  }

  & .toastr.rrt-info .rrt-left-container .rrt-holder {
    top: -11%;
  }

  & .toastr svg.toastr-icon {
    background: white;
  }

  & .toastr.rrt-success .toastr-icon {
    fill: ${green};
  }

  & .toastr.rrt-error .toastr-icon {
    fill: ${red};
  }

  & .toastr.rrt-info .toastr-icon {
    fill: ${blue};
  }

  & .toastr .rrt-middle-container {
    margin-left: 47px;
    width: 80%;
  }

  & .rrt-title {
    font-size: 1rem;
  }

  & .rrt-text {
    font-size: 0.8125rem;
  }

  & .rrt-title,
  & .rrt-text {
    font-family: ${fontFamilySansSerif};
    font-weight: 500 !important;
    margin-top: 1px;
  }

  & .toastr .rrt-right-container button.close-toastr {
    color: white;
    opacity: 1;
    position: static;
    margin-top: 6px;
    margin-right: 6px;
  }

  & .rrt-confirm-holder {
    & .rrt-confirm {
      background-color: ${white};
      padding-bottom: 1.5rem;
      margin-left: -15.5rem;
      width: 31rem;
    }

    & .rrt-buttons-holder {
      justify-content: flex-start;

      & .rrt-button {
        background-color: ${brandColor};
        color: ${white};
        border: 2px solid transparent;
        font-size: ${fontSizeSmall};
        font-family: ${fontFamilySansSerif};
        font-weight: 500;
        margin: 0;
        text-align: center;
        cursor: pointer;
        padding: 0.75rem 3rem;
        display: inline-block;
        line-height: 1.5;
        text-transform: uppercase;
        letter-spacing: 1px;
        outline: none;
        position: relative;
        transition: all 0.3s;
        padding: ${props =>
          props.size === 'small' ? '0.55rem 1rem' : '0.75rem 3rem'};
        width: ${props => (props.fullWidth ? '100%' : 'auto')};
        max-width: 12.5rem;

        &:hover {
          background-color: ${brandColorHover};
          border: 2px solid transparent;
          color: ${white};
          text-decoration: none;
        }

        &:focus,
        &:active {
          outline: 0;
          background-color: ${brandColorFocus};
        }

        &.rrt-ok-btn {
          background-color: ${brandDangerColor};
          margin-left: 1.875rem;

          &:hover {
            background-color: ${brandDangerColorHover};
          }

          &:focus {
            background-color: ${brandDangerColorFocus};
          }
        }

        &.rrt-cancel-btn {
          margin-right: 1.875rem;
        }
      }

      & .rrt-button:not(:first-of-type) {
        margin-left: 1rem;
      }
    }

    & .rrt-message {
      background-color: ${white};
      color: ${typeColor};
      font-size: 1.25rem;
      font-weight: 500;
      font-family: ${fontFamilySansSerif};
      -webkit-font-smoothing: antialiased;
      padding-bottom: 2.125rem;
      padding-left: 1.875rem;
      padding-right: 8.125rem;
      text-align: left;
    }
  }
  @media (max-width: 480px) {
    & .rrt-confirm-holder {
      & .rrt-confirm {
        width: 20rem;
        margin-left: -10rem;
        height: 11.5rem;
      }

      & .rrt-message {
        padding-right: 1.875rem;
      }
    }
  }
`;

export const successToast = (title, message, options) =>
  toastr.success(title, message, { icon: <SuccessIcon />, ...options });

export const errorToast = (title, message, options) =>
  toastr.error(title, message, { icon: <ErrorIcon />, ...options });

export const infoToast = (title, message, options) =>
  toastr.info(title, message, { icon: <InfoIcon />, ...options });
