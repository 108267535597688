import styled from 'styled-components';

import {
  fontFamilySansSerif,
  fontSizeMini,
  NavNoticationColor,
  white,
  ComponentRadius,
} from '../../constants/styles-old';

const { REACT_APP_SHOW_NOTIFICATIONS_ICONS } = process.env;

export default styled.div`
  display: ${REACT_APP_SHOW_NOTIFICATIONS_ICONS ? 'block' : 'none'};

  ${props =>
    props.isAbsolute
      ? `
    position: absolute;
    right: 0.85rem;
    top: 0.85rem;
  `
      : `
    align-self: center;
  `};
  width: 1.25rem;
  height: 1.25rem;
  font-size: ${fontSizeMini};
  font-family: ${fontFamilySansSerif};
  border-radius: ${ComponentRadius};
  color: ${white};
  background-color: ${NavNoticationColor};
  text-align: center;
  line-height: 1.25rem;
  z-index: 99;
`;
