import React from 'react';
import { string } from 'prop-types';
import TextColumn from '../TextColumn';
import { Section, SectionTitle, SectionText, SectionImage } from '../Section';

const WhySection = ({ whyText, whyTitle, whyImage }) => (
  <Section reverse>
    <TextColumn>
      <SectionTitle>{whyTitle}</SectionTitle>
      <SectionText dangerouslySetInnerHTML={{ __html: whyText }} />
    </TextColumn>
    <SectionImage src={whyImage} alt={whyTitle} />
  </Section>
);

WhySection.propTypes = {
  whyText: string.isRequired,
  whyTitle: string.isRequired,
  whyImage: string.isRequired,
};

export default WhySection;
