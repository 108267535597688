import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { string, arrayOf, shape, bool, func, number } from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';

import idx from 'idx.macro';
import { Helmet } from 'react-helmet-async';
import { tableThemes } from '../../constants/styles-old';

import Page, { PageWrapper } from '../../components/Page';
import SubNavigation from '../../components/SubNavigation';

import temperatureIcon from '../../assets/temperature-icon.svg';

import ObservationStationsTable from '../../components/Tables/ObservationStationsList';
import EditObservationStationsModal from '../../components/EditObservationStationForm/EditObservationStationsModal';
import Button from '../../components/Button';
import ObservationStationsQuery from './ObservationStationsQuery';

const PropTypes = {
  data: shape({
    error: shape({}),
    loading: bool,
    stations: shape({
      nodes: arrayOf(
        shape({
          name: string,
          fmisid: number,
          active: bool,
        }),
      ),
    }),
  }),
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const DefaultProps = {
  data: {
    users: null,
    loading: true,
    error: false,
  },
};
const Icon = styled.img``;

const ObservationStationsContainer = ({
  data: { stations = null, loading, error },
  history: { push },
}) => {
  const [isOpen, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const data = idx(stations, _ => _.nodes);
  const title = `FMI Sääasemat`;

  return (
    <PageWrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SubNavigation
        title={title}
        icon={() => <Icon src={temperatureIcon} />}
        render={() => <Button onClick={openModal}>Aktivoi sääasemia</Button>}
      />
      <Page pageId="alerts" display="flex">
        <ThemeProvider theme={tableThemes.light}>
          <ObservationStationsTable
            noItemsMessage="Ei säähavaintopisteitä."
            data={data}
            loading={loading}
            error={error}
            push={push}
          />
          <EditObservationStationsModal
            isOpen={isOpen}
            closeModal={closeModal}
          />
        </ThemeProvider>
      </Page>
    </PageWrapper>
  );
};

ObservationStationsContainer.propTypes = PropTypes;
ObservationStationsContainer.defaultProps = DefaultProps;

export default graphql(ObservationStationsQuery)(ObservationStationsContainer);
