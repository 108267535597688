import React from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import Modal from 'react-modal';
import {
  white,
  fontSizeH3,
  typeColor,
  typeLightColor,
  fontFamilySansSerif,
  fontSizeSmall,
  ComponentRadius,
  dividerColor,
} from '../../constants/styles-old';

const PropTypes = {
  closeButtonMarginTop: string,
  closeModal: func,
  contentLabel: string,
  contentPadding: string,
  contentWidth: string,
  isOpen: bool,
  render: func,
  titlePadding: string,
};

const DefaultProps = {
  closeButtonMarginTop: '1rem',
  closeModal: () => {},
  contentLabel: '',
  contentPadding: '1rem',
  contentWidth: '31rem',
  isOpen: false,
  render: () => <div>Hello world</div>,
  titlePadding: '0rem 0rem 1.5rem 0rem',
};

const ModalWithStyles = styled(Modal)`
  align-self: center;
  background-color: ${white};
  background-color: white;
  border-radius: ${ComponentRadius};
  margin-left: auto;
  margin-right: auto;
  max-height: 95vh;
  overflow-y: auto;
  padding: ${props => props.contentPadding};
  position: relative;
  width: ${props => props.contentWidth};

  & h3 {
    padding: ${props => props.titlePadding};
  }
`;

const ModalTitle = styled.h3`
  -webkit-font-smoothing: antialiased;
  border-bottom: 1px solid ${dividerColor};
  color: ${typeColor};
  font-family: ${fontFamilySansSerif};
  font-size: ${fontSizeH3};
  font-weight: 400;
  letter-spacing: -1.25px;
  margin-bottom: 1.5rem;
`;

const ModalSubtitle = styled.small`
  color: ${typeColor};
  display: block;
  font-size: ${fontSizeSmall};
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${typeColor};
  cursor: pointer;
  font-size: 2rem;
  height: 2.5rem;
  line-height: 2rem;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: ${props => props.closeButtonMarginTop};
  width: 2.5rem;

  &:hover {
    color: ${typeLightColor};
  }
`;

const StyledModal = ({
  closeButtonMarginTop,
  closeModal,
  contentLabel,
  isOpen,
  render,
  contentPadding,
  contentWidth,
  titlePadding,
}) => (
  <ModalWithStyles
    contentLabel={contentLabel}
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        zIndex: '99999',
      },
    }}
    contentPadding={contentPadding}
    contentWidth={contentWidth}
    titlePadding={titlePadding}
  >
    <CloseButton
      className="modal-close"
      closeButtonMarginTop={closeButtonMarginTop}
      onClick={closeModal}
    >
      ×
    </CloseButton>
    {render({ ModalTitle, ModalSubtitle })}
  </ModalWithStyles>
);

StyledModal.propTypes = PropTypes;
StyledModal.defaultProps = DefaultProps;

export default StyledModal;
