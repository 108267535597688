import styled from 'styled-components';
import {
  mediaBreakpointDownMd,
  xl,
} from 'styled-bootstrap-responsive-breakpoints';
import ListIcon from '../../../assets/list-icon.svg';
import {
  dividerColor,
  fontFamilyNarrow,
  fontSizeH2,
  fontSizeNormal,
  typeColor,
  landingPageMargin,
  landingPageMarginSmall,
  massiveScreenBreakpoint,
} from '../../../constants/styles-old';

export const SectionTitle = styled.h2`
  font-family: ${fontFamilyNarrow};
  font-size: ${fontSizeH2};
  color: ${typeColor};
  margin-bottom: ${landingPageMargin / 2}rem;
`;

export const SectionText = styled.div`
  font-family: ${fontFamilyNarrow};
  font-size: ${fontSizeNormal};
  color: ${typeColor};
  margin-bottom: ${landingPageMargin / 2}rem;

  > p {
    margin-bottom: ${landingPageMargin / 2}rem;
  }
`;

export const SectionImage = styled.img`
  width: 50%;
  display: block;
  object-fit: contain;
  user-select: none;
  max-width: 35rem;

  ${mediaBreakpointDownMd`
    width: 100%;
  `};
`;

export const Section = styled.section`
position: relative;
display: flex;
align-items: center;
padding-top: ${landingPageMargin}rem;
padding-bottom: ${landingPageMargin}rem;
margin-left: ${landingPageMargin}rem;
margin-right: ${landingPageMargin}rem;
flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
line-height: 22px;
width: 100%;
max-width: ${xl}px;

  > ${SectionImage} {
    ${props =>
      props.reverse ? 'padding-right: 5rem;' : 'padding-left: 5rem;'};
  }

  &:after {
    content: " ";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 1px;
    background-color: ${dividerColor};
    right: 0;
    left: 0;
  }

  & ul {
    list-style-image: url('${ListIcon}');
    padding-left: 1.375rem;
  }

  & ul,
  & ol {
    margin-bottom: ${landingPageMargin / 2}rem;

    > li {
      margin-top: 6px;
      margin-bottom: 6px;
      padding-left: 12px;
    }
  }

  @media (min-width: ${massiveScreenBreakpoint}px) {
    margin-left: auto;
    margin-right: auto;
  }

  ${mediaBreakpointDownMd`
    padding-top: ${landingPageMargin / 2}rem;
    padding-bottom: ${landingPageMargin / 2}rem;
    margin-left: ${landingPageMarginSmall}rem;
    margin-right: ${landingPageMarginSmall}rem;
    flex-direction: column;

    > ${SectionImage} {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  `};
`;

export default Section;
