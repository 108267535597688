import Auth0Lock from 'auth0-lock';
import qs from 'qs';
import { brandColor } from '../../constants/styles-old';
import { setUserProfileAndIdentify, removeUserProfile } from '../../actions';
import helenLogo from '../../assets/helen-logo-only.svg';
import { getIdToken, ID_TOKEN } from './shallow';

const DOMAIN_CUSTOM = process.env.REACT_APP_AUTH0_DOMAIN_CUSTOM || '';
const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const REDIRECT_URL =
  process.env.REACT_APP_AUTH0_REDIRECT_URL || 'http://localhost:3000';
const API_URL = process.env.REACT_APP_REST_ENDPOINT;

export const ACCESS_TOKEN = 'access_token';
export const EXPIRES_AT = 'expires_at';
export const AUTH0_SCOPE = 'openid profile email user_metadata app_metadata';
export const STATE = 'browserstate';

const initLock = ({ state, email } = {}) =>
  new Auth0Lock(CLIENT_ID, DOMAIN_CUSTOM, {
    container: 'lock',
    allowSignUp: false,
    language: 'fi',
    oidcConformant: true,
    autoclose: false,
    closable: false,
    loginAfterSignUp: true,
    rememberLastLogin: process.env.REACT_APP_AUTH0_REMEMBER_LAST_LOGIN || false,
    languageDictionary: {
      error: {
        signUp: {
          social_signup_needs_terms_acception: '',
        },
      },
      lastLoginInstructions: 'Viimeksi kirjauduit alla olevalla tunnuksella',
      notYourAccountAction: 'En ole tämä käyttäjä',
      forgotPasswordTitle: 'Unohditko salasanasi?',
      forgotPasswordAction: 'Unohditko salasanasi?',
      forgotPasswordInstructions:
        'Ole hyvä ja anna sähköpostiosoitteesi. Lähetämme sinulle sähköpostin salasanan vaihtamista varten.',
      title: 'Kirjaudu sisään',
      success: {
        signUp:
          'Kiitos rekisteröitymisestä. Lähetimme sähköpostiisi vahvistusviestin. Paina sähköpostiviestin "Vahvista tili" painiketta, jonka jälkeen voit kirjautua sisään.',
      },
      unrecoverableError:
        'Jotain meni vikaan.<br />Jos ongelma jatkuu, ota yhteyttä asiakaspalvelu@kiinteistovahti.helen.fi.',
    },
    theme: {
      logo: helenLogo,
      primaryColor: brandColor,
    },
    auth: {
      redirectUrl: `${REDIRECT_URL}`,
      audience: `https://${DOMAIN}/userinfo`,
      responseType: 'token id_token',
      params: {
        scope: AUTH0_SCOPE,
        state,
        nonce: state,
      },
    },
    prefill: {
      email,
    },
    avatar: null,
  });

const handleError = async response => {
  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.error);
  }
  return response;
};

const randomString = length => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
  const result = [];

  const bytes = new Uint8Array(32);
  const random = (window.crypto || window.msCrypto).getRandomValues(bytes);

  for (let i = 0; i < length; i += 1) {
    result.push(charset[random[i] % length]);
  }
  return result.join('');
};

export const fetchWithHeaders = (url, opts = { method: 'GET', headers: {} }) =>
  fetch(url, {
    ...opts,
    headers: {
      authorization: `Bearer ${getIdToken()}`,
      ...opts.headers,
    },
  }).then(handleError);

const isValidRedirectPath = (path = '') =>
  !['/login', '/terms-of-use', '/order', '/quotation'].some(i =>
    path.startsWith(i),
  );

const getAppState = path => {
  const defaultNonce = `{"nonce": "${randomString(32)}", "path": "${
    isValidRedirectPath(path) ? path : ''
  }"}`;
  const state = JSON.parse(window.localStorage.getItem(STATE) || defaultNonce);

  if (!state.path && isValidRedirectPath(path)) {
    state.path = path;
  }

  window.localStorage.setItem(STATE, JSON.stringify(state));
  return state.nonce;
};

const getStatePath = appState => {
  const state = JSON.parse(window.localStorage.getItem(STATE) || '0');

  if (state && state.nonce === appState) {
    return state.path;
  }

  return null;
};

export default class Auth {
  constructor(history, dispatch) {
    const { pathname, search } = history.location;
    const state = getAppState(`${pathname}${search}`);
    const { email } = qs.parse(search.substring(1));

    this.lock = initLock({ state, email });

    this.handleAuthentication();
    this.history = history;
    this.dispatch = dispatch;
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.login = this.login.bind(this);
    this.setSession = this.setSession.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.refetchToken = this.refetchToken.bind(this);

    if (process.env.NODE_ENV !== 'test') {
      this.getProfileOnReload();
    }
  }

  login(options = {}) {
    const state = getAppState();
    const params = { auth: { params: { state, nonce: state } } };
    this.lock.show({ ...options, ...params });
  }

  logout() {
    window.localStorage.removeItem(ACCESS_TOKEN);
    window.localStorage.removeItem(ID_TOKEN);
    window.localStorage.removeItem(EXPIRES_AT);
    document.cookie = `kiinteistovahti_no_ssr=true;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    this.dispatch(removeUserProfile());
    this.lock.logout({ returnTo: `${REDIRECT_URL}/login` });
  }

  handleAuthentication() {
    this.lock.on('authenticated', authResult => {
      const { pathname, search } = this.history.location;
      const currentPath = `${pathname}${search}`;
      const redirectPath = getStatePath(authResult.appState);
      window.localStorage.removeItem(STATE);
      this.setSession(authResult);
      this.lock.hide();
      this.history.replace(redirectPath || currentPath);
    });

    this.lock.on('authorization_error', error => {
      this.lock.show({
        flashMessage: {
          type: 'error',
          text: error.errorDescription,
        },
      });
    });
  }

  isAuthenticated() {
    const expiresAt = JSON.parse(
      window.localStorage.getItem(EXPIRES_AT) || '0',
    );
    const isValid = Date.now() < expiresAt;

    if (expiresAt && !isValid) {
      this.logout();
    }

    return isValid;
  }

  async refetchToken() {
    return new Promise(resolve => {
      this.lock.checkSession(
        {
          scope: AUTH0_SCOPE,
          prompt: 'none',
        },
        async (err, authResult) => {
          if (err) {
            this.logout();
          } else {
            this.setSession(authResult);
            await this.updateProfileOnReload(authResult);
          }
          resolve();
        },
      );
    });
  }

  async updateProfileOnReload({ idToken }) {
    if (this.isAuthenticated() && idToken) {
      try {
        const { ...rest } = await (
          await fetchWithHeaders(`${API_URL}/profile`)
        ).json();
        this.dispatch(setUserProfileAndIdentify({ ...rest }));
      } catch (err) {
        // eslint-disable-next-line
        console.error('Could not fetch profile', err.message);
      }
    }
  }

  async getProfileOnReload() {
    const idToken = window.localStorage.getItem(ID_TOKEN);
    if (idToken) {
      await this.refetchToken();
    }
  }

  getProfile() {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    if (this.isAuthenticated() && token) {
      this.lock.getUserInfo(token, (error, profile) => {
        if (!error) {
          const {
            'https://raportointi.enne.helen.fi/features': features,
            ...rest
          } = profile;

          this.dispatch(setUserProfileAndIdentify({ features, ...rest }));
        }
      });
    }
  }

  setSession(authResult = {}) {
    const { accessToken, idToken, expiresIn, idTokenPayload } = authResult;

    // For some reason Auth0 doesn't add the payload while registering...
    // so lets work around it
    // see issue #97

    if (!idTokenPayload['https://raportointi.enne.helen.fi/role']) {
      return;
    }

    if (process.env.NODE_ENV !== 'test') {
      if (
        process.env.NODE_ENV === 'production' &&
        idTokenPayload.nickname.includes(
          process.env.REACT_APP_MOTLEY_TEST_USER_NICKNAME,
        ) &&
        !window.location.hostname.includes('localhost')
      ) {
        throw new Error(
          'Cannot login as test user in non-localhost production environment',
        );
      }
    }

    if (accessToken && idToken) {
      const expiresAt = expiresIn * 31536000 + Date.now();
      window.localStorage.setItem(ACCESS_TOKEN, accessToken);
      window.localStorage.setItem(ID_TOKEN, idToken);
      window.localStorage.setItem(EXPIRES_AT, expiresAt);

      // Set cookie to prevent SSR when logged in for now
      try {
        document.cookie = `kiinteistovahti_no_ssr=true;expires=${new Date(
          expiresAt,
        ).toUTCString()}`;
      } catch (err) {
        // eslint-disable-next-line
        console.log(
          'Cookie error: failed to set cookie to prevent SSR',
          err.message,
        );
      }

      this.getProfile();
    }
  }
}
