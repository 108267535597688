import React from 'react';
import { node, string, func } from 'prop-types';
import styled from 'styled-components';
import { appBackgroundColor } from '../../constants/styles-old';

export const Container = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  background: ${appBackgroundColor};
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  ${props => (props.displayProp ? `display: ${props.displayProp};` : '')};
`;

Container.displayName = 'PageContainer';

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  height: 100vh;
`;

PageWrapper.displayName = 'PageWrapper';

const Page = props => (
  <Container
    displayProp={props.display}
    data-cy-page={props.pageId || 'places'}
    ref={props.updateScrollElement}
  >
    {props.children}
  </Container>
);

Page.propTypes = {
  children: node.isRequired,
  pageId: string,
  display: string,
  updateScrollElement: func,
};

Page.defaultProps = {
  pageId: 'places',
  display: null,
  updateScrollElement: () => {},
};

export default Page;
