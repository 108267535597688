import React from 'react';

const Users = props => (
  <svg width="36" height="26" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.071 26A15.75 15.75 0 0 1 8 24.5c0-4.663 2.042-8.73 5.07-10.878A9.456 9.456 0 0 0 18 15a9.456 9.456 0 0 0 4.93-1.378C25.958 15.771 28 19.837 28 24.5c0 .508-.024 1.008-.071 1.5H8.07zM18 13a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zM6.93 22H.112A10.089 10.089 0 0 1 0 20.5c0-2.326.789-4.434 2.067-5.969A5.977 5.977 0 0 0 6 16a5.972 5.972 0 0 0 3.41-1.063A20.149 20.149 0 0 0 6.93 22zM6 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm23.07 8a20.149 20.149 0 0 0-2.48-7.063A5.972 5.972 0 0 0 30 16c1.505 0 2.88-.554 3.933-1.469C35.21 16.066 36 18.174 36 20.5c0 .512-.038 1.013-.111 1.5h-6.82zm.93-8a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
      fillRule="evenodd"
    />
  </svg>
);

export default Users;
