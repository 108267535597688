import gql from 'graphql-tag';

export default gql`
  query ObservationStations {
    stations: allObservationStations(orderBy: [ACTIVE_DESC, NAME_ASC]) {
      nodes {
        fmisid
        name
        lat
        lng
        active
      }
    }
  }
`;
