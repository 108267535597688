export const roundToOneDecimal = number =>
  Number((Math.round(number * 10) / 10).toFixed(1));

export const roundToDecimals = (number, decimals) =>
  Math.round(number * 10 ** decimals) / 10 ** decimals;

export const roundToDecimalsLocale = (number, decimals = 2) =>
  Number(number).toLocaleString('fi-FI', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

export const roundToPrevMultipleOf = (number, roundTo) =>
  Math.floor(number / roundTo) * roundTo;

export const roundToPrevMultipleOfLocale = (number, roundTo) =>
  Math.floor(number / roundTo) * roundTo;

export const roundToNextMultipleOf = (number, roundTo) =>
  Math.ceil(number / roundTo) * roundTo;

export const findPriceGroup = (priceGroups, apartmentCount) =>
  priceGroups.find(
    ({ maxApartmentCount }) => apartmentCount <= maxApartmentCount,
  );

export const calculateMonthlyPrice = ({
  monthlyBaseFee = 0,
  monthlyMeterPrice = 0,
  discountPercentage = 0,
}) => ({ meterCount }) => {
  const normalPrice = meterCount * monthlyMeterPrice + monthlyBaseFee;
  const discountedPrice =
    normalPrice * (1 - discountPercentage / 100); // prettier-ignore

  return parseFloat(discountedPrice.toFixed(2));
};

export default { roundToOneDecimal };
