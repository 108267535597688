import React from 'react';

const ChevronDownIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" {...props}>
    <g fill="#393939" fillRule="evenodd">
      <path d="M13.085.157L14.5 1.57 7.43 8.642 6.013 7.228z" />
      <path d="M.358 1.571L1.772.157l7.071 7.07L7.43 8.643z" />
    </g>
  </svg>
);

export default ChevronDownIcon;
