import React from 'react';

const Pin = props => (
  <svg
    width="9px"
    height="12px"
    viewBox="0 0 9 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs />
    <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g
        id="List-addresses-search"
        transform="translate(-30.000000, -148.000000)"
      >
        <g id="Group-5" transform="translate(10.000000, 68.000000)">
          <path
            d="M21.3884616,87.6571148 C20.5328249,86.8478487 20,85.7081689 20,84.445547 C20,81.9903392 22.0147186,80 24.5,80 C26.9852814,80 29,81.9903392 29,84.445547 C29,85.6801301 28.4905771,86.7971708 27.6680615,87.6027146 L27.6989381,87.6335912 L24.5130681,91.4545393 L21.3734811,87.6720952 L21.3884616,87.6571148 Z M24.5,86.5529943 C25.6296734,86.5529943 26.5454545,85.6361582 26.5454545,84.5051836 C26.5454545,83.374209 25.6296734,82.4573729 24.5,82.4573729 C23.3703266,82.4573729 22.4545455,83.374209 22.4545455,84.5051836 C22.4545455,85.6361582 23.3703266,86.5529943 24.5,86.5529943 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Pin;
