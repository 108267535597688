const colors = {
  night: '#000000',
  white: '#FFFFFF',
  stone: '#737576',
  sun: '#FFC800',
  lavender: '#8C6EC8',
  leaf: '#73C82D',
  wind: '#26C4FF',
  miami: '#FF3291',
  error: '#EE2645',
  notification: '#FF7D00',
  hover: '#FAF9FB',
  typeColor: '#4A4A4A',
  typeLightColor: '#9B9B9B',
  violetBright: '#8F73C0',
  inputBg: '#F5F5F5',
  divider: '#EAEAEA',
  naviBase: '#1D2027',
  listBase: '#1E232E',
  listBase2nd: '#242935',
  listHighlight: '#414B62',
  helenTypeColor: '#393939',
  helenGrayLight: '#EAEAEA',
  helenLightBackground: '#EFF0F6',
  helenBrandColor: '#FF419A',
  helenBrandColorHover: '#C03073',
  helenTypeColorLight: '#4A4A4A',
  helenBrandDangerColor: '#EE2645',
  helenBackground: '#100169',
  helenDivider: '#DFE0EC',
  helenFormBackground: '#F9F9FB',
  helenBorder: '#CACCCE',
  helenSecondaryHover: '#EFEFF6',
  helenPrimaryHover: '#170198',
  helenInputTheme: {
    backgroundColor: '#FFFFFF',
    backgroundColorDisabled: '#F2F2F2',
    borderColor: '#393939',
    borderColorDanger: '#EE2645',
    borderColorDisabled: '#ECECEC',
    borderColorFocus: '#568EF7',
    caretColor: '#568EF7',
    dropdownItemColor: '#393939',
    dropdownItemColorHover: '#FF419A',
    errorMessageColor: '#EE2645',
    inputColor: '#393939',
    labelColor: '#000000',
    labelColorDisabled: '#AEAEAE',
    placeholderColor: '#CFCFCF',
  },
  chartColors: [
    '#8ABEB2',
    '#F25F5C',
    '#F4B65A',
    '#76B2D1',
    '#C88ADA',
    '#BE55FF',
    '#75BE79',
    '#4A90E2',
    '#55C800',
    '#414B62',
    '#AFCCBE',
    '#1D2027',
    '#CCFFAA',
    '#123456',
    '#AB2124',
  ],
};

export default colors;
