import React from 'react';
import { string, number, oneOfType, func, shape, arrayOf } from 'prop-types';
import Downshift from 'downshift';
import styled, { ThemeProvider, css } from 'styled-components';
import idx from 'idx.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Input from '../Input';

import {
  brandColor,
  ComponentRadius,
  fontSizeSmall,
  inputThemes,
  violetBrightest,
} from '../../constants/styles-old';

const PropTypes = {
  placeholder: string,
  setFieldTouched: func.isRequired,
  setFieldValue: func.isRequired,
  id: string.isRequired,
  name: string,
  label: string,
  error: string,
  values: shape({
    selectOptions: shape({
      addresses: arrayOf(
        shape({
          value: oneOfType([number, string]),
          name: string,
        }),
      ),
    }),
  }),
  defaultValue: number,
  selectOptionsIds: string,
  theme: shape({}),
};

const DefaultProps = {
  placeholder: null,
  label: null,
  error: null,
  name: null,
  values: {},
  defaultValue: null,
  theme: inputThemes.light,
  selectOptionsIds: 'addresses',
};

export const Container = styled.div`
  user-select: none;
  position: relative;
`;

export const StyledInput = styled(Input)`
  cursor: pointer;
  outline: none;
  appearance: none;

  &:focus + span {
    color: ${(props) => props.theme.inputColor};
  }
`;

export const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 43px;
  right: 10px;
  color: ${(props) => props.theme.placeholderColor};
  font-size: ${fontSizeSmall};
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.6);
  margin-top: 0.25rem;
  border-radius: ${ComponentRadius};
`;

export const Dropdown = styled.div`
  background-color: ${(props) => props.theme.dropdownBackgroundColor};
  overflow: hidden;
`;

export const DropdownItemWrapper = styled.div`
  ${(props) =>
    !props.isHighlighted
      ? css`
          background-color: ${props.theme.dropdownItemBackgroundColor};
          color: ${props.theme.dropdownColor};
        `
      : css`
          background-color: ${props.theme.dropdownItemBackgroundColorHover};
          color: ${props.theme.dropdownColorHover};
        `};

  display: flex;
  font-size: 0.75rem;
  margin: 0.45rem 0.625rem;
  border-radius: 3px;
  cursor: pointer;

  &:hover,
  &:focus {
    font-weight: 500;
    color: ${brandColor};
    background-color: ${violetBrightest};
  }
`;

export const DropdownItem = styled.div`
  padding: 0.375rem 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const isHighlighted = (index, highlightedIndex) => highlightedIndex === index;

const isSelected = (index, selectedItem) =>
  index && selectedItem && selectedItem.index === index;

const itemToString = (value) => idx(value, (_) => _.name) || '';

const handleInputChange = (input, setFieldTouched) => {
  const { id } = input.target;
  setFieldTouched(id);
};

const handleDownShiftChange = (value, id, setFieldValue, rest) => {
  const fieldValue = value && parseInt(value.value, 10);
  setFieldValue(id, fieldValue);

  const fieldIdToUpdate = rest.refetchOnChange;
  if (fieldIdToUpdate) {
    const fieldToUpdate = rest?.initialValues?.formFields?.find(
      (val) => val.id === fieldIdToUpdate,
    );
    fieldToUpdate.refetch(fieldValue);
    setFieldValue(fieldIdToUpdate, null);
  }
};

const formatCyTag = (name, index) =>
  name
    ? `downshift-item-${name
        .replace(/,/g, '')
        .toLowerCase()
        .split(' ')
        .join('-')}`
    : `downshift-item-unknown-${index}`;

const DownshiftInput = ({
  theme = inputThemes.light,
  placeholder,
  id,
  name,
  label,
  error,
  values,
  defaultValue,
  setFieldValue,
  setFieldTouched,
  selectOptionsIds,
  ...rest
}) => {
  const list = idx(values, (_) => _.selectOptions[selectOptionsIds]);

  if (!list) {
    return null;
  }

  const defaultItem =
    defaultValue && list && list.find((i) => i.value === defaultValue);

  return (
    <ThemeProvider theme={theme}>
      <Downshift
        onChange={(value) =>
          handleDownShiftChange(value, id, setFieldValue, rest)
        }
        itemToString={itemToString}
        initialSelectedItem={defaultItem}
      >
        {({
          getInputProps,
          getItemProps,
          getRootProps,
          isOpen,
          highlightedIndex,
          selectedItem,
          inputValue,
        }) => (
          <Container {...getRootProps({})}>
            <StyledInput
              {...getInputProps({
                placeholder,
                name,
                id,
                label,
                error,
                onChange: (input) => handleInputChange(input, setFieldTouched),
                autoComplete: 'new-password',
                spellCheck: false,
              })}
            />
            <SearchIcon icon={faSearch} />
            {isOpen && (
              <DropdownWrapper>
                <Dropdown>
                  {list &&
                    list
                      .filter(
                        (i) =>
                          !inputValue ||
                          i.name
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()),
                      )
                      .map((item, index) => (
                        <DropdownItemWrapper
                          {...getItemProps({
                            item,
                            key: item.name,
                            isHighlighted: isHighlighted(
                              index,
                              highlightedIndex,
                            ),
                            isSelected: isSelected(index, selectedItem),
                          })}
                          data-cy={formatCyTag(item.name, index)}
                        >
                          <DropdownItem title={item.name}>
                            {item.name}
                          </DropdownItem>
                        </DropdownItemWrapper>
                      ))}
                </Dropdown>
              </DropdownWrapper>
            )}
          </Container>
        )}
      </Downshift>
    </ThemeProvider>
  );
};

DownshiftInput.propTypes = PropTypes;
DownshiftInput.defaultProps = DefaultProps;

export default DownshiftInput;
