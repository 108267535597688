import { func, string } from 'prop-types';
import React from 'react';
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints';
import styled from 'styled-components';

import Button from '../../HelenButton';
import { Heading1, Ingress } from '../../HelenHeading';

import { colors } from '../../../constants/styles';

const Container = styled.div`
  background-color: #f5f5f5;
  max-width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3.5rem;
`;

const TitleBallot = styled.div`
  align-self: center;
  background-color: #ffc807;
  border-radius: 50%;
  color: white;
  flex-shrink: 0;
  font-size: 1.25rem;
  font-weight: 700;
  height: 2rem;
  padding-left: 7px;
  padding-top: 1px;
  width: 2rem;
`;

const TitleText = styled(Heading1)`
  align-self: center;
  color: ${colors.helenTypeColorLight};
  font-size: 1.875rem;
  font-weight: 700;
  margin-left: 1.25rem;
  margin-top: 0.15rem;
`;

const Content = styled.div`
  background-color: white;
  margin: 0 auto;
  max-width: 958px;
  padding: 5rem;

  ${mediaBreakpointDownMd`
    padding: 1.5rem 1rem;
  `};
`;

const Paragraph = styled(Ingress)`
  color: ${colors.helenTypeColorLight};
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.7;
  margin-top: 1rem;

  a {
    color: ${colors.helenBrandColor};
    font-weight: 500;
  }

  a:hover {
    color: ${colors.helenBrandColorHover};
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${colors.helenDivider};
  margin-top: 2rem;
  padding-top: 1rem;
`;

const OkButton = styled(Button)`
  margin-top: 2.5rem !important;
  min-width: 180px;
  background-color: ${colors.helenBackground};
  border-radius: 25px;
  &:hover,
  &:active {
    background-color: ${props =>
      !props.outline ? colors.helenPrimaryHover : colors.helenSecondaryHover};
    border-color: ${props =>
      props.outline ? colors.helenBorder : colors.helenBackground};
    color: ${colors.helenBackground};
    text-decoration: none;
  }
`;

const ThanksSection = ({
  goBack,
  thanksPageButtonText,
  thanksPageContactText,
  thanksPageSubtitle,
  thanksPageTitle,
}) => (
  <Container>
    <Content>
      <TitleContainer>
        <TitleBallot>✓</TitleBallot>
        <TitleText>{thanksPageTitle}</TitleText>
      </TitleContainer>
      <Paragraph dangerouslySetInnerHTML={{ __html: thanksPageSubtitle }} />
      <Divider />
      <Paragraph dangerouslySetInnerHTML={{ __html: thanksPageContactText }} />
      <OkButton
        onClick={() => {
          goBack();
        }}
      >
        {thanksPageButtonText}
      </OkButton>
    </Content>
  </Container>
);

ThanksSection.propTypes = {
  goBack: func.isRequired,
  thanksPageButtonText: string.isRequired,
  thanksPageContactText: string.isRequired,
  thanksPageSubtitle: string.isRequired,
  thanksPageTitle: string.isRequired,
};

export default ThanksSection;
