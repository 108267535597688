import React from 'react';

const RemoveIcon = props => (
  <svg
    width="23"
    height="23"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    {...props}
  >
    <circle cx="11.5" cy="11.5" r="11" fill="none" stroke="#9076BE" />
    <path
      fill="#9076BE"
      d="M7.02 8.44l1.42-1.42 7.6 7.61-1.41 1.41-7.61-7.6z"
    />
    <path
      fill="#9076BE"
      d="M16.04 8.44l-1.41-1.42-7.61 7.61 1.42 1.41 7.6-7.6z"
    />
  </svg>
);

export default RemoveIcon;
