import { errorToast } from '../../components/Toastr';

export default (err = { message: 'No error' }, message) => {
  if (process.env.NODE_ENV !== 'production') {
    errorToast(
      'Järjestelmässä tapahtui virhe',
      `Virhe: ${err.message}, ${message || ''}`,
    );
    return;
  }

  errorToast(
    'Järjestelmässä tapahtui virhe',
    message || `Yritä myöhemmin uudelleen`,
  );
};
