import React from 'react';
import { shape, arrayOf, string, func, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  mediaBreakpointDownMd,
  mediaBreakpointDownLg,
} from 'styled-bootstrap-responsive-breakpoints';
import Pin from '../Pin';
import {
  white,
  typeLightColor,
  dividerColor,
  fontFamilySansSerif,
  fontSizeNormal,
  typeColor,
} from '../../constants/styles-old';
import Tabs, { tabItems } from '../Tabs';
import Content from '../SubNavigationContent';

import arrow from '../../assets/back-arrow.svg';

const Container = styled.div`
  position: static;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  background: ${white};
  color: ${typeLightColor};
  font-family: ${fontFamilySansSerif};
  z-index: 999;
  width: 100%;
  min-height: 4rem;
  padding-right: 4rem;
  padding-left: 4rem;
  border-bottom: 1px solid ${dividerColor};

  ${mediaBreakpointDownLg`
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
  `};
`;

const LocationText = styled.span`
  font-size: ${fontSizeNormal};
  font-weight: 400;
  font-family: ${fontFamilySansSerif};
  color: ${typeColor};
  flex-direction: row;
  display: flex;
  flex: 1;
  min-width: 20px;

  > svg {
    margin-top: 2px;
    min-width: 14px;
    margin-right: 0.5rem;
  }

  ${mediaBreakpointDownLg`
    padding-left: 2rem;

    ${props => props.hiddenTextInLgDown && 'display: none'};
  `};
`;

const PinText = styled.span`
  padding-left: 0.625rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;

  ${mediaBreakpointDownMd`
    display: none;
  `};
`;

const Arrow = styled.img`
  padding-right: 1.25rem;
  height: 14px;

  ${mediaBreakpointDownLg`
    display: none;
  `};
`;

const SubNavigation = ({
  links,
  title,
  icon: PinIcon,
  iconWidth,
  iconHeight,
  render,
  backUrl,
  hiddenTextInLgDown,
}) => (
  <Container>
    {backUrl ? (
      <StyledLink to={backUrl} data-cy="back-navigation">
        <Arrow src={arrow} />
      </StyledLink>
    ) : null}
    <LocationText hiddenTextInLgDown={hiddenTextInLgDown} title={title}>
      <PinIcon width={iconWidth} height={iconHeight} />
      <PinText>{title}</PinText>
    </LocationText>
    {links ? (
      <Tabs>{tabItems(links)}</Tabs>
    ) : (
      <Content>{render ? render() : null}</Content>
    )}
  </Container>
);

SubNavigation.propTypes = {
  links: arrayOf(
    shape({
      name: string,
      slug: string,
    }),
  ),
  title: string.isRequired,
  icon: func,
  iconWidth: string,
  iconHeight: string,
  render: func,
  backUrl: string,
  hiddenTextInLgDown: bool,
};

SubNavigation.defaultProps = {
  icon: Pin,
  iconWidth: '14px',
  iconHeight: '18px',
  links: null,
  render: null,
  backUrl: null,
  hiddenTextInLgDown: false,
};

export default SubNavigation;
