import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints';
import Button from '../../HelenButton';
import { Heading1, Heading6, Ingress } from '../../HelenHeading';

import { colors } from '../../../constants/styles';

const Container = styled.div`
  max-width: 100%;
  padding-top: 10rem;
  padding-bottom: 10rem;
`;

const Content = styled.div`
  background-color: white;
  margin: 0 auto;
  max-width: 958px;
  padding: 4.5rem 5rem;

  ${mediaBreakpointDownMd`
    padding: 1.5rem 1rem;
  `};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`;

const TitleBallot = styled.div`
  align-self: center;
  border-radius: 50%;
  color: ${colors.helenBackground};
  flex-shrink: 0;
  font-size: 1.25rem;
  font-weight: 700;
  height: 2rem;
  padding-left: 7px;
  padding-top: 1px;
  width: 2rem;
`;

const TitleText = styled(Heading1)`
  align-self: center;
  color: ${colors.helenTypeColorLight};
  font-size: 1.875rem;
  font-weight: 700;
  margin-left: 1.25rem;
  margin-top: 0.15rem;
`;

const Subtitle = styled(Heading6)`
  color: ${colors.helenTypeColorLight};
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 2rem;
`;

const Paragraph = styled(Ingress)`
  color: ${colors.helenTypeColorLight};
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: -0.25px;
  line-height: 1.7;
  margin-top: 1rem;

  a {
    color: ${colors.helenAltBrandColor};
    font-weight: 500;
  }

  a:hover {
    color: ${colors.helenAltBrandColorHover};
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${colors.helenGrayLight};
  margin-top: 2rem;
  padding-top: 1rem;
`;

const AdButton = styled(Button)`
  margin-top: 1.5rem;
  min-width: 180px;
  background-color: ${colors.helenBackground};
  border-radius: 25px;
  align-self: center;
  &:hover,
  &:active {
    background-color: ${props =>
      !props.outline ? colors.helenPrimaryHover : colors.helenSecondaryHover};
    border-color: ${props =>
      props.outline ? colors.helenBorder : colors.helenBackground};
    color: ${colors.helenBackground};
    text-decoration: none;
  }
`;

const ThanksSection = ({
  thanksAdButtonLink,
  thanksAdButtonText,
  thanksAdText,
  thanksAdTitle,
  thanksContactText,
  thanksContactTitle,
  thanksText,
  thanksTitle,
}) => (
  <Container>
    <Content>
      <TitleContainer>
        <TitleBallot>✓</TitleBallot>
        <TitleText>{thanksTitle}</TitleText>
      </TitleContainer>
      <Paragraph>{thanksText}</Paragraph>
      <Subtitle>{thanksContactTitle}</Subtitle>
      <Paragraph dangerouslySetInnerHTML={{ __html: thanksContactText }} />
      <Divider />
      <Subtitle>{thanksAdTitle}</Subtitle>
      <Paragraph dangerouslySetInnerHTML={{ __html: thanksAdText }} />
      <AdButton
        href={thanksAdButtonLink}
        protocolLink
        secondary
        target="_blank"
      >
        {thanksAdButtonText}
      </AdButton>
    </Content>
  </Container>
);

ThanksSection.propTypes = {
  thanksAdButtonLink: string.isRequired,
  thanksAdButtonText: string.isRequired,
  thanksAdText: string.isRequired,
  thanksAdTitle: string.isRequired,
  thanksContactText: string.isRequired,
  thanksContactTitle: string.isRequired,
  thanksText: string.isRequired,
  thanksTitle: string.isRequired,
};

export default ThanksSection;
