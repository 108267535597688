import gql from 'graphql-tag';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import qs from 'qs';
import { createGlobalStyle } from 'styled-components';
import uuidv4 from 'uuid/v4';

import OrderForm from '../../components/OrderForm';
import { helenFontFaces } from '../../constants/styles-old';
import { findPriceGroup } from '../../lib/math-helpers';
import { KIINTEISTOVAHTI_SERVICE_TYPES } from '../../constants/kiinteistovahti-service-types';

const LoadFonts = createGlobalStyle`${helenFontFaces}`;

const getOrderFormContentQuery = gql`
  query getOrderFormContent {
    allOrderForms: allOrder_forms {
      edges {
        node {
          heroProductButtonText: hero_product_button_text
          heroLoginButtonText: hero_login_button_text
          heroTitle: hero_title
          heroSubtitle: hero_subtitle
          heroIngress: hero_ingress
          heroContactText: hero_contact_text
          expiredTitle: expired_title
          expiredDesc: expired_desc
          formTitle: form_title
          formFieldErrorText: form_field_error_text
          formFields: form_fields {
            id
            info
            label
            placeholder
            value
          }
          formSections: form_sections {
            id
            title
            subtitle
          }
          formUsersAddButtonText: form_users_add_button_text
          formUsersRemoveButtonText: form_users_remove_button_text
          formSubmitButtonText: form_submit_button_text
          formInvalidErrorText: form_invalid_error_text
          summaryTitle: summary_title
          summaryFields: summary_fields {
            id
            label
            value
          }
          summarySections: summary_sections {
            id
            title
          }
          summaryActions: summary_actions {
            id
            title
            desc
          }
          summaryPriceMonthly: summary_price_monthly
          summaryPriceAnnual: summary_price_annual
          summaryCheckboxLabel: summary_checkbox_label
          summarySubtext: summary_subtext
          summaryCheckboxError: summary_checkbox_error
          summarySubmitButtonText: summary_submit_button_text
          thanksTitle: thanks_title
          thanksText: thanks_text
          thanksContactTitle: thanks_contact_title
          thanksContactText: thanks_contact_text
          thanksAdTitle: thanks_ad_title
          thanksAdText: thanks_ad_text
          thanksAdButtonText: thanks_ad_button_text
          thanksAdButtonLink: thanks_ad_button_link
        }
      }
    }
    allQuotationForms: allQuotation_forms {
      edges {
        node {
          meterBaseFee: meter_base_fee
          monthlyBaseFee: monthly_base_fee
          monthlyBaseFeeAuto: monthly_base_fee_auto
          monthlyMeterPrice: monthly_meter_price
          roleOptions: role_options {
            value
            name
          }
          priceGroups: price_groups {
            maxApartmentCount: price_group_max_apartment_count
            baseFee: price_group_base_fee
            monthlyFee: price_group_monthly_fee
          }
        }
      }
    }
  }
`;

const getQuotationQuery = gql`
  query getQuotationQuery($uuid: String) {
    getQuotation(uuid: $uuid)
      @rest(type: "Quotation", path: "/order/quotation/:uuid", method: "GET") {
      address
      apartmentCount
      email
      expiresAt
      firstName
      info
      lastName
      meterCount
      baseFee
      meterBaseFee
      monthlyBaseFee
      monthlyMeterPrice
      phone
      role
      uuid
      zip
      serviceType
    }
  }
`;

const createOrderMutation = gql`
  mutation createOrder($order: OrderInput!) {
    createOrder(input: { order: $order })
      @rest(type: "Order", path: "/order/order", method: "POST") {
      uuid
    }
  }
`;

const getInitialValues = quotation => ({
  apartmentCount: quotation.apartmentCount,
  meterCount: quotation.meterCount,
  ordererFirstName: quotation.firstName,
  ordererLastName: quotation.lastName,
  ordererEmail: quotation.email,
  ordererPhone: quotation.phone,
  ordererRole: quotation.role,
  housingCompanyAddress: quotation.address,
  housingCompanyZip: quotation.zip,
  serviceType: quotation.serviceType || KIINTEISTOVAHTI_SERVICE_TYPES.AUTO,
  baseFee: quotation.baseFee,
  meterBaseFee: quotation.meterBaseFee,
  monthlyBaseFee: quotation.monthlyBaseFee,
});

const getFormFields = fields =>
  fields.reduce((acc, curr) => {
    acc[curr.id] = {
      info: curr.info,
      label: curr.label,
      placeholder: curr.placeholder,
      value: curr.value,
    };
    return acc;
  }, {});

const getSummaryFields = labels =>
  labels.reduce((acc, curr) => {
    acc[curr.id] = {
      label: curr.label,
      value: curr.value,
    };
    return acc;
  }, {});

const getSections = labels =>
  labels.reduce((acc, curr) => {
    acc[curr.id] = {
      title: curr.title,
      subtitle: curr.subtitle,
    };
    return acc;
  }, {});

const getRoleOptions = roles =>
  roles.map(({ value, name }) => ({
    value,
    name,
  }));

const getSummaryActions = labels =>
  labels.reduce((acc, curr) => {
    acc[curr.id] = {
      title: curr.title,
      desc: curr.desc,
    };
    return acc;
  }, {});

const getHasExpired = quotation => {
  if (!quotation || !quotation.expiresAt) {
    return false;
  }

  const expiresAt = moment(quotation.expiresAt);
  const now = moment();
  return expiresAt.isBefore(now);
};

const mapData = ({
  allOrderForms: {
    edges: [
      {
        node: {
          formFields,
          formSections,
          summaryActions,
          summaryFields,
          summarySections,
          ...node
        },
      },
    ],
  },
  allQuotationForms: {
    edges: [
      {
        node: { meterBaseFee: normalMeterBaseFee, roleOptions, priceGroups },
      },
    ],
  },
  getQuotation: quotation,
  quotationUuid,
}) => {
  const meterCount = quotation?.meterCount || 0;
  const group = findPriceGroup(priceGroups, meterCount) || {};

  const hasExpired = getHasExpired(quotation);
  const {
    baseFee: normalBaseFee = 0,
    monthlyFee: normalMonthlyFee = 0,
  } = group;

  const quotationMonthlyBaseFee = Number(quotation?.monthlyBaseFee || 0);

  const quotationBaseFee = Number(quotation?.baseFee || 0);
  const quotationMeterBaseFee = Number(quotation?.meterBaseFee || 0);

  const baseFee = (!hasExpired && quotationBaseFee) || normalBaseFee;

  const meterBaseFee =
    (!hasExpired && quotationMeterBaseFee) || normalMeterBaseFee;

  const monthlyBaseFee =
    (!hasExpired && quotationMonthlyBaseFee) || normalMonthlyFee;

  return {
    priceGroups,
    formFields: getFormFields(formFields),
    formSections: getSections(formSections),
    hasExpired,
    initialValues: quotation
      ? getInitialValues(quotation)
      : {
          meterBaseFee,
          serviceType: KIINTEISTOVAHTI_SERVICE_TYPES.FIXED,
        },
    baseFee,
    meterBaseFee,
    monthlyBaseFee,
    quotationUuid,
    roleOptions: getRoleOptions(roleOptions),
    summaryActions: getSummaryActions(summaryActions),
    summaryFields: getSummaryFields(summaryFields),
    summarySections: getSections(summarySections),
    ...node,
  };
};

const handleSubmit = createOrder => async values => {
  const order = {
    ...values,
    otherUsers: JSON.stringify(values.otherUsers),
    uuid: uuidv4(),
  };

  try {
    await createOrder({ variables: { order } });
  } catch (err) {
    throw err;
  }
};

export default compose(withRouter)(ownProps => (
  <Query query={getOrderFormContentQuery}>
    {({ loading: contentLoading, data: contentData, error: contentError }) => {
      const { search } = ownProps.location;
      const { uuid } = search.length > 0 ? qs.parse(search.substring(1)) : {};

      return (
        <Fragment>
          <Query query={getQuotationQuery} variables={{ uuid }}>
            {({ loading: quotationLoading, data: quotationData }) => {
              if (contentLoading || contentError || quotationLoading) {
                return null;
              }
              return (
                <Fragment>
                  <LoadFonts />
                  <Mutation mutation={createOrderMutation}>
                    {createOrder => (
                      <OrderForm
                        {...mapData({
                          ...contentData,
                          ...quotationData,
                          ...ownProps,
                          quotationUuid: uuid,
                        })}
                        handleSubmit={handleSubmit(createOrder)}
                      />
                    )}
                  </Mutation>
                </Fragment>
              );
            }}
          </Query>
        </Fragment>
      );
    }}
  </Query>
));
