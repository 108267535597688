import {
  // func,
  shape,
  string,
} from 'prop-types';
import React, { Fragment, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import styled, { ThemeProvider } from 'styled-components';
import { mediaBreakpointUpMd } from 'styled-bootstrap-responsive-breakpoints';
import Toaster from '../components/Toastr';
import { appBackgroundColor, tableThemes } from '../constants/styles-old';
// import withAnalytics from '../lib/Analytics';
import {
  PropTypes as AuthPropTypes,
  withAuth,
  adminAccessValidator,
  superAdminAccessValidator,
  writeAccessValidator,
} from '../lib/Auth/shallow';
import ProtectedRoute from '../lib/ProtectedRoute';
import VerticalNavbar from './VerticalNavbar';
import { AbsoluteLoadingSpinner } from '../components/LoadingSpinner';
import LandingPage from './LandingPage';
import OrderForm from './OrderFormContainer';
import QuotationForm from './QuotationFormContainer';
import Preview from './Preview';
import ObservationStationsList from './ObservationStationsList';

const LoginScreen = React.lazy(() => import('../components/LoginScreen'));
const AddMeterForm = React.lazy(() => import('./AddMeterForm'));
const ChangeMeterForm = React.lazy(() => import('./ChangeMeterForm'));
const AddNewAddressForm = React.lazy(() => import('./AddNewAddressForm'));
const AddLocationForm = React.lazy(() => import('./AddLocationForm'));
const AuditEvent = React.lazy(() => import('../components/AuditEvent'));
const AuditLogs = React.lazy(() => import('./AuditLogs'));
const CreateNewMenu = React.lazy(() => import('./CreateNewMenu'));
const InternalMenu = React.lazy(() => import('../components/InternalMenu'));

const Faq = React.lazy(() => import('./Faq'));
const Alerts = React.lazy(() => import('./Alerts'));
const PlaceView = React.lazy(() => import('./PlaceView'));
const PlacesSelector = React.lazy(() => import('./PlacesSelector'));
const OwnUserProfile = React.lazy(() => import('./UserProfile/OwnUserProfile'));
const UserListSuperAdmin = React.lazy(() => import('./UserListSuperAdmin'));

const UserListAdmin = React.lazy(() => import('./UserListAdmin'));
const ClientList = React.lazy(() => import('./ClientList'));
const OrderList = React.lazy(() => import('./OrderList'));
const OrderDetails = React.lazy(() => import('./OrderDetails'));
const TermsOfUse = React.lazy(() => import('../components/TermsOfUsePage'));

const UserProfileAdmin = React.lazy(() => import('./UserProfileAdmin'));
const UserProfileSuperAdmin = React.lazy(
  () => import('./UserProfileSuperAdmin'),
);
const ApiDocs = React.lazy(() => import('./ApiDocs'));

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${appBackgroundColor};

  ${mediaBreakpointUpMd`
    padding-left: 80px;
  `};
`;

AppContainer.displayName = 'AppContainer';

const PropTypes = {
  auth: AuthPropTypes.isRequired,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
  Profile: shape({}),
  // onGaPageView: func.isRequired,
  // onGaSet: func.isRequired,
};

const DefaultProps = {
  Profile: null,
};

const parentPath = (path) => path.substring(0, path.lastIndexOf('/'));

const LoggedInRoutes = ({ location: { pathname }, Profile: profile }) => (
  <ThemeProvider theme={{ ...tableThemes.light }}>
    <AppContainer>
      <VerticalNavbar />
      <Suspense fallback={<AbsoluteLoadingSpinner />}>
        <Switch>
          <Route path={'/'} exact render={() => <Redirect to="/places" />} />
          <Route path={'/faq/:uid?'} exact={false} component={Faq} />
          <ProtectedRoute
            key="internal"
            path={'/internal'}
            exact
            component={InternalMenu}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            key="/internal/api-docs"
            path={'/internal/api-docs'}
            exact
            component={ApiDocs}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            key="/internal/orders"
            path={'/internal/orders'}
            exact
            component={OrderList}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            key="/internal/orders"
            path={'/internal/orders/:id'}
            exact
            component={OrderDetails}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            key="/internal/clients"
            path={'/internal/clients'}
            exact
            component={ClientList}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            key="clients/client/user"
            path={'/internal/clients/:client/users/:userId'}
            exact
            component={(props) => (
              <UserProfileSuperAdmin
                {...props}
                linkToUsers={parentPath(pathname)}
              />
            )}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            key="clients/client"
            path={'/internal/clients/:client/users'}
            component={UserListSuperAdmin}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            path="/internal/logs"
            exact
            component={AuditLogs}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            path="/internal/logs/:eventId"
            exact
            component={AuditEvent}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            key="/internal/fmi-ids"
            path={'/internal/fmi-ids'}
            exact
            component={ObservationStationsList}
            validator={superAdminAccessValidator}
          />
          <ProtectedRoute
            key="users"
            path={'/admin/users'}
            exact
            component={UserListAdmin}
            validator={adminAccessValidator}
          />
          <ProtectedRoute
            key="users/user"
            path={'/admin/users/:userId'}
            exact
            validator={adminAccessValidator}
            component={(props) => (
              <UserProfileAdmin
                {...props}
                profile={profile}
                linkToUsers={parentPath(pathname)}
              />
            )}
          />
          <Route
            key="profile"
            path={'/profile'}
            exact
            component={(props) => <OwnUserProfile {...props} ownProfile />}
          />
          <Route path={'/places/:boroughs?'} exact component={PlacesSelector} />
          <Route
            path={'/places/:boroughs/:address/:staircases/:tab?'}
            component={PlaceView}
          />
          <ProtectedRoute
            key="create"
            path={'/create'}
            exact
            validator={writeAccessValidator}
            component={CreateNewMenu}
          />
          <ProtectedRoute
            key={'create/address'}
            path={'/create/address'}
            exact
            validator={writeAccessValidator}
            component={AddNewAddressForm}
          />
          <ProtectedRoute
            key={'create/location'}
            path={'/create/location'}
            exact
            validator={writeAccessValidator}
            component={AddLocationForm}
          />
          <ProtectedRoute
            key={'create/meter'}
            path={'/create/meter'}
            exact
            validator={writeAccessValidator}
            component={AddMeterForm}
          />
          <ProtectedRoute
            key={'create/change'}
            path={'/create/change'}
            exact
            validator={writeAccessValidator}
            component={ChangeMeterForm}
          />
          <Route path={'/alerts/:page?'} component={Alerts} />
          <Route render={() => <Redirect to="/places" />} />
        </Switch>
      </Suspense>
    </AppContainer>
  </ThemeProvider>
);

LoggedInRoutes.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
  Profile: shape({}),
};

LoggedInRoutes.defaultProps = {
  Profile: null,
};

const LoginRoute = (isAuthenticated) => () => {
  if (!isAuthenticated) {
    return (
      <Suspense fallback={<AbsoluteLoadingSpinner />}>
        <LoginScreen />
      </Suspense>
    );
  }

  return <Redirect to={'/places'} />;
};

const LandingPageRoute = (isAuthenticated) => () => {
  if (isAuthenticated) {
    return <Redirect to="/places" />;
  }

  return <LandingPage />;
};

export const App = (props) => {
  const {
    auth,
    // location: { pathname },
    // Profile: profile,
    // onGaPageView,
    // onGaSet,
  } = props;

  // onGaPageView(pathname);

  // if (profile) {
  //   onGaSet({ auth0id: profile.sub });
  // }

  const isAuthenticated = auth.isAuthenticated();

  return (
    <Fragment>
      <Helmet
        titleTemplate="%s - Helen Kiinteistövahti"
        defaultTitle="Helen Kiinteistövahti"
      >
        {/* <script
          async
          defer
          src="https://static.cdn.prismic.io/prismic.js?repo=kiinteistovahti&new=true>"
        />  */}
      </Helmet>

      <Switch>
        <Route
          path={'/terms-of-use'}
          render={(routeProps) => (
            <Suspense fallback={<AbsoluteLoadingSpinner />}>
              <TermsOfUse {...routeProps} />
            </Suspense>
          )}
        />
        {/* Temporarily keep the /price path for backwards compatibility */}
        <Route path={['/quotation*', '/price*']} component={QuotationForm} />
        <Route path={'/order*'} component={OrderForm} />
        <Route path={'/callback'} exact component={() => <div />} />
        <Route exact path={'/login'} component={LoginRoute(isAuthenticated)} />
        {isAuthenticated ? <LoggedInRoutes {...props} /> : null}
        <Route path={'/preview'} component={Preview} />
        <Route exact path={'/'} component={LandingPageRoute(isAuthenticated)} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      <Toaster
        data-cy="toaster"
        timeOut={7500}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
      />
    </Fragment>
  );
};

App.propTypes = PropTypes;
App.defaultProps = DefaultProps;

const mapStateToProps = ({ Profile }) => ({ Profile });

const Application = compose(
  withRouter,
  withAuth,
  // withAnalytics,
  connect(mapStateToProps),
)(App);

// for ssr

export const ApplicationSSR = (props) => () => App(props);

export default Application;
