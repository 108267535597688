/* eslint-disable react/no-array-index-key */

import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  mediaBreakpointDownMd,
  mediaBreakpointDownSm,
} from 'styled-bootstrap-responsive-breakpoints';

import { Heading1, Ingress } from '../../HelenHeading';
import Button from '../../HelenButton';

import Hero from '../../../assets/order-form-hero-v2.png';
import { colors } from '../../../constants/styles';

const Container = styled.div`
  background-color: ${colors.helenBackground};
  background-image: url('${Hero}');
  background-position-x: 670px;
  background-repeat: no-repeat;
  max-width: 100%;
  min-height: 760px;
  padding: 1rem;

  ${mediaBreakpointDownMd`
    background-image: none;
    min-height: auto;
  `};

  @media (min-width: 1455px) {
    background-position-x: 100%;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;

  ${mediaBreakpointDownMd`
    flex-direction: column;
    margin-top: 80px;
  `};
`;

const NavLinks = styled.div`
  margin-top: 1.25rem;
  margin-right: 1.75rem;

  a {
    margin: 0rem 0.4375rem;
    min-width: 215px;
  }

  ${mediaBreakpointDownMd`
    margin-top: 0.5rem;

    a {
      margin: 0.25rem 0.4375rem;
    }
  `};
`;

const Content = styled.div`
  margin-left: 6.5rem;
  margin-top: 8rem;
  max-width: 550px;

  ${mediaBreakpointDownMd`
    margin-left: 1rem;
    margin-top: 5rem;
  `};
`;

const Title = styled(Heading1)`
  color: ${colors.white};
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.43px;

  ${mediaBreakpointDownSm`
    font-size: 2rem;
  `};
`;

const Subtitle = styled(Ingress)`
  color: ${colors.white};
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.75px;
  margin-top: 1.25rem;
  line-height: 1.4;
`;

const StyledIngress = styled(Ingress)`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.5;
  margin-top: 2.75rem;
  max-width: 525px;
`;

const MoreInfo = styled(Ingress)`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: -0.67px;
  line-height: 1.5;
  margin-top: 1rem;

  a {
    color: ${colors.helenAltTextLinkColor};
    cursor: pointer;
    font-weight: 400;

    &:hover {
      color: ${colors.helenAltTextLinkColorHover};
    }
  }
`;
const StyledButton = styled(Button)`
  background-color: ${colors.helenBackground};
  border-radius: 25px;
  align-self: center;
  &:hover,
  &:active {
    background-color: ${props =>
      !props.outline ? colors.helenPrimaryHover : colors.helenSecondaryHover};
    border-color: ${props =>
      props.outline ? colors.helenBorder : colors.helenBackground};
    color: ${colors.helenBackground};
    text-decoration: none;
  }
`;

const HeroSection = ({
  heroContactText,
  heroIngress,
  heroLoginButtonText,
  heroProductButtonText,
  heroSubtitle,
  heroTitle,
}) => (
  <Container>
    <TopRow>
      <NavLinks>
        <StyledButton
          href="https://www.helen.fi/kiinteistovahti"
          protocolLink
          secondary
        >
          {heroProductButtonText}
        </StyledButton>
        <StyledButton to="/login" link secondary>
          {heroLoginButtonText}
        </StyledButton>
      </NavLinks>
    </TopRow>
    <Content>
      <Title>{heroTitle}</Title>
      <Subtitle>{heroSubtitle}</Subtitle>
      <StyledIngress>{heroIngress}</StyledIngress>
      <MoreInfo dangerouslySetInnerHTML={{ __html: heroContactText }} />
    </Content>
  </Container>
);

HeroSection.propTypes = {
  heroContactText: string.isRequired,
  heroIngress: string.isRequired,
  heroLoginButtonText: string.isRequired,
  heroProductButtonText: string.isRequired,
  heroSubtitle: string.isRequired,
  heroTitle: string.isRequired,
};

export default HeroSection;
