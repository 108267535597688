import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Heading1, Ingress } from '../../HelenHeading';

import { fonts } from '../../../constants/styles';

const Top = styled.div`
  padding: 2rem;
  padding-top: 1.75rem;
  padding-bottom: 1rem;
  text-align: center;
`;

const Title = styled(Heading1)`
  font-weight: bold;
  font-size: ${fonts.helenSizes.headline};
  font-family: ${fonts.families.helenFontFamilySansSerif};
  text-align: start;
`;

const Subtitle = styled(Ingress)`
  margin-top: 1.1rem;
  font-weight: 500;
  font-size: ${fonts.sizes.base};
  line-height: 150%;
  text-align: start;
`;

const TopSection = ({ title, subtitle }) => (
  <Top>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Top>
);

TopSection.propTypes = {
  subtitle: string.isRequired,
  title: string.isRequired,
};

TopSection.defaultProps = {};

export default TopSection;
