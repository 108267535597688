import React, { Component } from 'react';
import { bool, string, func, shape, number, oneOfType } from 'prop-types';
import idx from 'idx.macro';
import Downshift from 'downshift';
import styled, { ThemeProvider } from 'styled-components';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  DropdownWrapper,
  Dropdown,
  DropdownItemWrapper,
  DropdownItem,
  SearchIcon,
  StyledInput,
} from '../DownshiftInput';
import { inputThemes } from '../../constants/styles-old';

const PropTypes = {
  onChange: func.isRequired,
  placeholder: string,
  setFieldTouched: func.isRequired,
  setFieldValue: func.isRequired,
  id: oneOfType([string, number]).isRequired,
  name: string,
  label: string,
  error: string,
  theme: shape({}),
  defaultValue: string,
  disabled: bool,
  onSelect: func,
};

const DefaultProps = {
  placeholder: null,
  label: null,
  error: null,
  name: null,
  theme: inputThemes.light,
  defaultValue: '',
  disabled: false,
  onSelect: null,
};

const isHighlighted = (index, highlightedIndex) => highlightedIndex === index;

const isSelected = (index, selectedItem) =>
  index && selectedItem && selectedItem.index === index;

const itemToString = (value) => idx(value, (_) => _.name) || '';

const formatCyTag = (name, index) =>
  name
    ? `address-item-${name
        .replace(/,/g, '')
        .toLowerCase()
        .split(' ')
        .join('-')}`
    : `address-item-unknown-${index}`;

const AddressInputContainer = styled(Container)`
  flex-direction: column;
`;

AddressInputContainer.displayName = 'AddressInputContainer';

export class BaseAddressInput extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDownShiftChange = this.handleDownShiftChange.bind(this);
  }

  componentDidMount() {
    const googleMaps = (window.google && window.google.maps) || this.googleMaps;

    if (!googleMaps) {
      throw new Error('Google map api was not found in the page.');
    }

    this.googleMaps = googleMaps;
    this.autocompleteService = new googleMaps.places.AutocompleteService();
    this.geocoder = new googleMaps.Geocoder();
  }

  handleInputChange(input) {
    const value = input && input.target && input.target.value;
    if (value) {
      this.autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ['geocode'],
          componentRestrictions: {
            country: 'FI',
          },
        },
        (suggestsGoogle) => {
          const items =
            suggestsGoogle &&
            suggestsGoogle.map((item) => ({
              name: idx(item, (_) => _.description),
              short: idx(item, (_) => _.structured_formatting.main_text),
              placeId: idx(item, (_) => _.place_id),
            }));
          this.setState({ items });
        },
      );
    }
    this.props.onChange(input);
  }

  handleDownShiftChange(value) {
    const { id } = this.props;
    this.props.setFieldTouched(id, true);
    this.props.setFieldValue(id, value.name);
    this.geocoder.geocode(
      {
        placeId: value.placeId,
      },
      (results, status) => {
        if (status === this.googleMaps.GeocoderStatus.OK) {
          const gmaps = results[0];
          const { location } = gmaps.geometry;
          const lat = location.lat();
          const lng = location.lng();
          this.props.setFieldValue('latLng', { lat, lng });
          if (this.props.onSelect) {
            this.props.onSelect({ lat, lng });
          }
        }
      },
    );
  }

  render() {
    const { items } = this.state;
    const {
      theme = inputThemes.light,
      placeholder,
      id,
      name,
      label,
      error,
      defaultValue,
      disabled,
    } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Downshift
          onChange={this.handleDownShiftChange}
          itemToString={itemToString}
          initialInputValue={defaultValue || ''}
        >
          {({
            getInputProps,
            getItemProps,
            getRootProps,
            isOpen,
            highlightedIndex,
            selectedItem,
          }) => (
            <AddressInputContainer {...getRootProps({})}>
              <StyledInput
                {...getInputProps({
                  placeholder,
                  name,
                  id,
                  label,
                  error,
                  onChange: this.handleInputChange,
                  autoComplete: 'off',
                  disabled,
                })}
              />
              <SearchIcon name="search" icon={faSearch} />
              {isOpen && (
                <DropdownWrapper>
                  <Dropdown>
                    {items &&
                      items.map((item, index) => (
                        <DropdownItemWrapper
                          {...getItemProps({
                            item,
                            key: item.placeId,
                            isHighlighted: isHighlighted(
                              index,
                              highlightedIndex,
                            ),
                            isSelected: isSelected(index, selectedItem),
                          })}
                          data-cy={formatCyTag(item.name, index)}
                        >
                          <DropdownItem>{item.name}</DropdownItem>
                        </DropdownItemWrapper>
                      ))}
                  </Dropdown>
                </DropdownWrapper>
              )}
            </AddressInputContainer>
          )}
        </Downshift>
      </ThemeProvider>
    );
  }
}

BaseAddressInput.propTypes = PropTypes;
BaseAddressInput.defaultProps = DefaultProps;

export default BaseAddressInput;
