/* eslint camelcase: ["error", {allow: ["UNSAFE_componentWillReceiveProps"]}] */

import React from 'react';
import { compose, withState, withHandlers, lifecycle } from 'recompose';
import styled from 'styled-components';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { appBackgroundColor } from '../../constants/styles-old';

import mapStyle from '../../constants/map-styles';

const defaultLatLng = { lat: 60.15562, lng: 24.9425 };

const getInitialLatLng = ({ defaultValue }) => {
  if (defaultValue) {
    return {
      lat: defaultValue.lat || defaultLatLng.lat,
      lng: defaultValue.lng || defaultLatLng.lng,
    };
  }

  return defaultLatLng;
};

const Container = styled.div`
  > div {
    width: 100%;
    height: 100%;
  }
`;

const MapPicker = compose(
  withState('centerLatLng', 'onCenterChange', getInitialLatLng),
  withState('markerLatLng', 'onMarkerChange', getInitialLatLng),
  withHandlers(() => {
    const refs = {
      map: undefined,
    };
    return {
      onMapMounted: () => ref => {
        refs.map = ref;
      },
      onMarkerMounted: () => ref => {
        refs.marker = ref;
      },
      onMarkerChanged: ({ onMarkerChange, setFieldValue, id }) => () => {
        const latLng = refs.marker.getPosition().toJSON() || null;
        setFieldValue(id, latLng);
        onMarkerChange(latLng);
      },
    };
  }),
  lifecycle({
    async UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        this.props.values &&
        this.props.values.latLng !== nextProps.values.latLng
      ) {
        const { latLng } = nextProps.values || null;
        if (latLng) {
          this.props.onMarkerChange(latLng);
          this.props.onCenterChange(latLng);
        }
      }
    },
  }),
)(props => {
  const { centerLatLng } = props;

  return (
    <div style={{ backgroundColor: appBackgroundColor, height: `250px` }}>
      <Container style={{ height: `100%`, width: '100%' }}>
        <GoogleMap
          center={centerLatLng}
          zoom={15}
          options={{
            styles: mapStyle,
            mapTypeControl: false,
            streetViewControl: false,
            maxZoom: 18,
            minZoom: 5,
            fullscreenControl: false,
          }}
          onLoad={map => {
            props.onMapMounted(map);
            if (map) {
              map.panTo({ lat: centerLatLng.lat, lng: centerLatLng.lng });
            }
          }}
        >
          <Marker
            setValue={props.setValue}
            draggable
            onLoad={props.onMarkerMounted}
            defaultPosition={{ lat: 60.15562, lng: 24.9425 }}
            onDragEnd={props.onMarkerChanged}
            position={props.markerLatLng}
          />
        </GoogleMap>
      </Container>
    </div>
  );
});

export default MapPicker;
