import { ApolloClient, ApolloProvider } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';

import cookie from 'js-cookie';
import Prismic from 'prismic-javascript';
import { errorToast } from '../components/Toastr';

const { REACT_APP_GRAPHQL_ENDPOINT, REACT_APP_REST_ENDPOINT } = process.env;

const client = auth => {
  const httpLink = createUploadLink({
    uri: `${REACT_APP_GRAPHQL_ENDPOINT}`,
  });

  const auth0RestLink = new RestLink({
    uri: REACT_APP_REST_ENDPOINT,
    credentials: 'same-origin',
    headers: {},
  });

  const middlewareLink = setContext(() => {
    // the undefined and null get coersed to strings, so don't use
    // ternaries here. Possible fixed in https://github.com/apollographql/apollo-link/issues/874,
    // but most likely not in `apollo-upload-client`
    const headers = {};

    if (window.localStorage.getItem('id_token')) {
      headers.authorization = `Bearer ${window.localStorage.getItem(
        'id_token',
      )}`;
    }

    if (cookie.get(Prismic.previewCookie)) {
      headers['prismic-ref'] = cookie.get(Prismic.previewCookie);
    }

    return { headers };
  });

  const errorLink = onError(({ graphQLErrors, networkError = {} }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        // eslint-disable-next-line
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}`,
        );
      });
    }

    if (networkError.statusCode === 401) {
      auth.logout();
      errorToast('Sessio vanhentui', 'Ole hyvä ja kirjaudu uudelleen.');
    }
  });

  // eslint-disable-next-line no-underscore-dangle
  const state = window.__APOLLO_STATE__;

  return new ApolloClient({
    link: middlewareLink
      .concat(errorLink)
      .concat(auth0RestLink)
      .concat(httpLink),
    cache: new InMemoryCache().restore(state),
  });
};

export { ApolloProvider, client };
