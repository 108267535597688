import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import Downshift from 'downshift';

import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import ChevronDown from './ChevronDownIcon';
import ChevronUp from './ChevronUpIcon';
import { Asterisk, Message, StyledLabel } from '../HelenInput';

import {
  dividerColor,
  helenComponentRadius,
  helenFontFamilySansSerif,
  helenInputTheme,
} from '../../constants/styles-old';

const PropTypes = {
  disabled: bool,
  error: string,
  id: oneOfType([string, number]),
  isControlled: bool,
  label: string,
  onChange: func,
  options: arrayOf(
    shape({
      value: oneOfType([number, string]),
      name: string,
    }),
  ).isRequired,
  placeholder: string,
  required: bool,
  selectedItem: shape({}),
  selectedValue: oneOfType([number, string]),
  theme: shape({}),
  defaultValue: oneOfType([number, string]).isRequired,
};

const DefaultProps = {
  disabled: false,
  error: null,
  id: null,
  isControlled: true,
  label: '',
  onChange: null,
  placeholder: 'Ei tiedossa',
  required: false,
  selectedValue: null,
  selectedItem: undefined,
  theme: helenInputTheme,
};

const SelectContainer = styled.div`
  color: ${props => props.theme.inputColor};
  position: relative;
  user-select: none;
`;

const SelectButton = styled.button`
  -webkit-appearance: none !important;
  appearance: none;
  background-color: ${props => props.theme.backgroundColor};
  border-color: ${props =>
    props.theme.borderColor ? props.theme.borderColor : dividerColor};
  border-radius: ${props => props.theme?.inputComponentRadius};
  border: 1px solid;
  cursor: pointer;
  display: flex;
  font-family: ${helenFontFamilySansSerif};
  font-size: 1rem;
  justify-content: space-between;
  line-height: 1.5;
  margin: 0;
  outline: none;
  padding: 0.6875rem 0.9375rem;
  position: relative;
  width: 100%;

  &&:focus,
  &&:active {
    border: 2px solid;
    border-color: ${props => props.theme.borderColorFocus};
  }

  &&:disabled {
    background-color: ${props => props.theme.helen};
    opacity: 0.8;
  }

  span {
    align-self: center;
    color: ${props => props.theme.inputColor};
    display: inline-block;
    font-size: 0.625rem;
    width: 1.25rem;
  }

  svg {
    align-self: center;
  }
`;

const OptionTitle = styled.div`
  display: inline-block;
`;

const Options = styled.ul`
  background: white;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  border-left: 1px solid ${props => props.theme.borderColor};
  border-top: 1px solid ${props => props.theme.borderColor};
  border-radius: ${helenComponentRadius};
  border-radius: ${props =>
    props.theme.inputComponentRadius
      ? props.theme.inputComponentRadius
      : helenComponentRadius};
  border-right: 1px solid ${props => props.theme.borderColor};
  padding: 0rem;
  position: absolute;
  right: 0rem;
  width: 100%;
  z-index: 10;
`;

const Option = styled.li`
  border-top: 1px solid ${props => props.theme.borderColor};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => props.theme.dropdownItemColor};
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.45rem 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.dropdownItemBackcolorHover};
    color: ${props => props.theme.dropdownItemColorHover};
    font-weight: 500;
  }

  ${props =>
    !props.isHighlighted
      ? css`
          color: ${props.theme.dropdownItemColor};
        `
      : css`
          color: ${props.theme.dropdownItemColorHover};
          font-weight: 500;
        `};

  ${props =>
    props.disabled &&
    css`
      background-color: white !important;
      color: ${props.theme.dropdownItemColor} !important;
      font-weight: 400 !important;
    `};
`;

const HelenSelect = ({
  defaultValue,
  disabled,
  error,
  id,
  label,
  onChange,
  options,
  placeholder,
  required,
  theme,
  selectedItem: controlledSelectedItem,
}) => {
  let downshiftProps = {
    onChange,
    onSelect: onChange,
    selectedItem: controlledSelectedItem,
    itemToString: item => (item ? item.name : ''),
  };

  if (defaultValue || defaultValue === 0 || options.some(i => i.isDefault)) {
    downshiftProps = {
      ...downshiftProps,
      initialSelectedItem: options.find(
        i => i.value === defaultValue || i.isDefault,
      ),
    };
  }

  return (
    <ThemeProvider theme={theme}>
      <Downshift {...downshiftProps}>
        {({
          getItemProps,
          getMenuProps,
          getRootProps,
          getToggleButtonProps,
          highlightedIndex,
          isOpen,
          selectedItem,
        }) => (
          <SelectContainer
            {...getRootProps({
              id,
            })}
          >
            {label ? (
              <StyledLabel error={error}>
                {label}
                {required ? <Asterisk>*</Asterisk> : null}
              </StyledLabel>
            ) : null}
            <SelectButton {...getToggleButtonProps()} disabled={disabled}>
              {selectedItem ? (
                <OptionTitle
                  dangerouslySetInnerHTML={{ __html: selectedItem.name }}
                />
              ) : (
                <OptionTitle
                  dangerouslySetInnerHTML={{
                    __html: placeholder || options[0].name,
                  }}
                />
              )}
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </SelectButton>
            {isOpen && (
              <Options
                {...getMenuProps()}
                style={{ top: label ? '80px' : '49px' }}
              >
                {options.map((item, index) => {
                  return (
                    <Option
                      {...getItemProps({
                        'data-cy': `select-${id}-${item.name}`,
                        disabled: item.disabled,
                        index,
                        isHighlighted: highlightedIndex === index,
                        item,
                        key: item.name,
                      })}
                      dangerouslySetInnerHTML={{ __html: item.name }}
                    />
                  );
                })}
              </Options>
            )}
            {error ? <Message error={error}>{error}</Message> : null}
          </SelectContainer>
        )}
      </Downshift>
    </ThemeProvider>
  );
};

HelenSelect.propTypes = PropTypes;
HelenSelect.defaultProps = DefaultProps;

export default HelenSelect;
