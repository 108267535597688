import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  string,
  number,
  shape,
  arrayOf,
  oneOfType,
  func,
  bool,
} from 'prop-types';
import Downshift from 'downshift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints';

import { StyledLabel, Message } from '../Input';
import { OptionTitle, Options, Option } from '../Options';
import {
  ComponentRadius,
  dividerColor,
  fontFamilySansSerif,
  inputThemes,
  colorKallio,
} from '../../constants/styles-old';

const PropTypes = {
  label: string,
  id: oneOfType([string, number]),
  options: arrayOf(
    shape({
      value: oneOfType([number, string]),
      name: string,
    }),
  ).isRequired,
  onChange: func,
  error: string,
  theme: shape({}),
  placeholder: string,
  isControlled: bool,
  disabled: bool,
  selectedItem: shape({}),
  className: string,
  defaultValue: oneOfType([number, string, arrayOf(shape({}))]),
  alignDropdown: string,
};

const DefaultProps = {
  label: '',
  id: null,
  onChange: null,
  error: null,
  theme: inputThemes.light,
  placeholder: 'Valitse...',
  isControlled: true,
  disabled: false,
  selectedItem: undefined,
  className: null,
  defaultValue: null,
  alignDropdown: undefined,
};

export const SelectContainer = styled.div`
  user-select: none;
  position: relative;
  color: ${props => props.theme.inputColor};
`;

export const SelectButton = styled.button`
  border: 1px solid;
  border-color: ${dividerColor};
  width: 100%;
  min-width: 160px;
  border-radius: ${ComponentRadius};
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0;
  line-height: 1.5;
  padding: 0.5925rem 0.25rem;
  font-size: 0.8rem;
  font-family: ${fontFamilySansSerif};
  cursor: pointer;
  outline: none;
  appearance: none;
  background-color: ${props => props.theme.backgroundColor};
  -webkit-appearance: none !important;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  + ul {
    width: ${props => (props.submenu ? '200px' : 'auto')};

    ${mediaBreakpointDownSm`
      box-shadow: none;
      border: 2px solid ${dividerColor};
      left: 0rem;
      right: auto;
    `};
  }

  &&:focus,
  &&:active {
    border-color: ${props => props.theme.focusBorderColor};
    background-color: ${props => props.theme.backgroundColorFocus};
    outline: none;
  }

  &&:disabled {
    background-color: ${props => props.theme.backgroundColorDisabled};
    opacity: 0.8;
  }

  span,
  svg.svg-inline--fa {
    font-size: 1rem;
    display: inline-block;
    width: 2rem;
    align-self: center;
  }
`;
const validSelection = (selectedItem, options) =>
  options?.some(option => option?.name === selectedItem?.name);

const Select = ({
  options,
  label,
  id,
  error,
  theme,
  placeholder,
  defaultValue,
  onChange,
  disabled,
  selectedItem: controlledSelectedItem,
  className,
  alignDropdown,
}) => {
  let downshiftProps = {
    onChange,
    onSelect: onChange,
    itemToString: item => (item ? item.name : ''),
  };

  if (defaultValue || defaultValue === 0 || options.some(i => i.isDefault)) {
    downshiftProps = {
      ...downshiftProps,
      initialSelectedItem: options.find(
        i => i.value === defaultValue || i.isDefault,
      ),
    };
  }

  return (
    <ThemeProvider theme={theme}>
      <Downshift {...downshiftProps} selectedItem={controlledSelectedItem}>
        {({
          getRootProps,
          getToggleButtonProps,
          getItemProps,
          getMenuProps,
          isOpen,
          selectedItem,
          highlightedIndex,
        }) => (
          <SelectContainer
            className={className}
            {...getRootProps({
              id,
            })}
          >
            {label ? <StyledLabel error={error}>{label}</StyledLabel> : null}
            <SelectButton {...getToggleButtonProps()} disabled={disabled}>
              {validSelection(selectedItem, options) ? (
                <OptionTitle>{selectedItem.name}</OptionTitle>
              ) : (
                <OptionTitle>{placeholder || options[0].name}</OptionTitle>
              )}
              <FontAwesomeIcon
                icon={faCaretDown}
                style={{ color: colorKallio }}
              />
            </SelectButton>

            {isOpen && (
              <Options {...getMenuProps()} alignDropdown={alignDropdown}>
                {options.map((item, index) => (
                  <Option
                    {...getItemProps({
                      item,
                      index,
                      key: item.name,
                      isHighlighted: highlightedIndex === index,
                      disabled: item.disabled,
                      'data-cy': `select-${id}-${item.name}`,
                    })}
                    selected={
                      selectedItem &&
                      item.value === selectedItem.value &&
                      !item.disabled
                    }
                  >
                    {item.name}
                  </Option>
                ))}
              </Options>
            )}
            {error ? <Message error={error}>{error}</Message> : null}
          </SelectContainer>
        )}
      </Downshift>
    </ThemeProvider>
  );
};

Select.propTypes = PropTypes;
Select.defaultProps = DefaultProps;

export default Select;
