import {
  TOGGLE_NAVIGATION,
  SET_NAVIGATION_FILTER_VALUE,
} from '../../actions/index.js';

const getInitialFilter = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const {
    location: { pathname },
  } = window;

  if (pathname.includes('/places')) {
    const [, , zone, next] = pathname.split('/');
    return zone && !next ? zone : null;
  }

  return null;
};

export const initialState = {
  open: false,
  filter: getInitialFilter(),
};

export default (state = initialState, { type, filter }) => {
  if (type === TOGGLE_NAVIGATION) {
    return {
      ...state,
      open: !state.open,
    };
  }

  if (type === SET_NAVIGATION_FILTER_VALUE) {
    return {
      ...state,
      filter,
    };
  }

  if (type === '@@router/LOCATION_CHANGE') {
    return {
      ...state,
      open: false,
    };
  }

  return state;
};
