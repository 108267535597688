import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { mediaBreakpointDownXs } from 'styled-bootstrap-responsive-breakpoints';
import { typeLightColor, brandColor } from '../../constants/styles-old';
import Content from '../SubNavigationContent';

const Tabs = Content.withComponent('ul');

export const TabItem = styled.li`
  display: flex;
  list-style: none;
  margin-bottom: -2px;
  margin-right: 1rem;
  height: 65px;

  ${mediaBreakpointDownXs`
    margin-right: 0;
  `};
`;

export const TabText = styled.span`
  cursor: pointer;
  color: ${typeLightColor};
  padding: 1rem 0rem;

  ${mediaBreakpointDownXs`
    width: 100%;
    margin-right: 1rem;
  `};

  ${props =>
    typeof props.isActive === 'boolean' &&
    props.isActive &&
    `
    color: ${brandColor};
    border-bottom: 2px solid ${brandColor};
  `};

  &.active {
    color: ${brandColor};
    border-bottom: 2px solid ${brandColor};
  }

  &:hover {
    color: ${brandColor};
    text-decoration: none;
  }
`;

export const TabLink = TabText.withComponent(NavLink);

const isLinkActive = to => (match, location) => {
  const { pathname, search } = location;
  const fullPath = `${pathname}${search}`;
  return fullPath === to;
};

export const tabItems = links =>
  links.map(link => (
    <TabItem key={link.slug}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <TabLink
        to={link.slug}
        title={link.name}
        isActive={isLinkActive(link.slug)}
      >
        {link.name}
      </TabLink>
    </TabItem>
  ));

export default Tabs;
