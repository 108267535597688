const colors = [
  '#8ABEB2',
  '#F25F5C',
  '#F4B65A',
  '#76B2D1',
  '#C88ADA',
  '#BE55FF',
  '#75BE79',
  '#4A90E2',
  '#55C800',
  '#414B62',
  '#AFCCBE',
  '#1D2027',
  '#CCFFAA',
  '#123456',
  '#AB2124',
];

export default colors;
