export const roleNames = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  REGULAR_USER: 'regular_user',
  READ_ONLY: 'read_only',
};

export default {
  super_admin: 400,
  admin: 300,
  regular_user: 200,
  read_only: 100,
};
