import React from 'react';

const Pin = props => (
  <svg
    width="22px"
    height="28px"
    viewBox="0 0 22 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs />
    <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="NAV-5" transform="translate(-29.000000, -82.000000)">
        <g id="NAV">
          <path
            d="M32.3940171,100.717392 C30.3024608,98.7391857 29,95.9533017 29,92.8668927 C29,86.8652736 33.9248678,82 40,82 C46.0751322,82 51,86.8652736 51,92.8668927 C51,95.8847624 49.7547439,98.6153063 47.7441502,100.584413 L47.8196264,100.65989 L40.0319441,109.999985 L32.3573983,100.754011 L32.3940171,100.717392 Z M40,98.0184306 C42.7614237,98.0184306 45,95.7772757 45,93.012671 C45,90.2480664 42.7614237,88.0069115 40,88.0069115 C37.2385763,88.0069115 35,90.2480664 35,93.012671 C35,95.7772757 37.2385763,98.0184306 40,98.0184306 Z"
            id="Addresses"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Pin;
