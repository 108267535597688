import styled from 'styled-components';
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: 0;
  margin-left: 1rem;
  align-items: center;

  ${mediaBreakpointDownMd`
    justify-content: normal;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
`};
`;

export default Content;
