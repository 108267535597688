import React, { useState } from 'react';
import { func, number } from 'prop-types';
import PriceSlider from '../../components/PriceSlider';

const PriceSliderContainer = ({ defaultValue, onChangeValue, ...props }) => {
  const [sliderValue, setSliderValue] = useState(defaultValue);

  return (
    <PriceSlider
      setSliderValue={value => {
        setSliderValue(value);
        onChangeValue(value);
      }}
      sliderValue={sliderValue}
      {...props}
    />
  );
};

PriceSliderContainer.propTypes = {
  defaultValue: number.isRequired,
  formatTooltip: func.isRequired,
  onChangeValue: func.isRequired,
};

export default PriceSliderContainer;
