export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const REMOVE_USER_PROFILE = 'REMOVE_USER_PROFILE';

export const setUserProfile = profile => ({
  type: SET_USER_PROFILE,
  profile,
});

export const removeUserProfile = () => ({
  type: REMOVE_USER_PROFILE,
});

export const setUserProfileAndIdentify = profile => (
  dispatch,
  // _,
  // { LogRocket },
) => {
  if (profile) {
    // const { email } = profile;

    // try {
    //   LogRocket.identify(email, profile);
    // } catch (err) {
    //   if (process.env.NODE_ENV !== 'production') {
    //     console.error(`Failed to connect to LogRocket`, err.message); // eslint-disable-line
    //   }
    // }

    dispatch(setUserProfile(profile));
  }
};
