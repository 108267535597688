export const GA_PAGE_VIEW = 'GA_PAGE_VIEW';
export const GA_EVENT = 'GA_EVENT';
export const GA_SET = 'GA_SET';

/**
 * @param {object} args
 * @param {string} args.path - e.g. '/get-involved/other-ways-to-help'
 * @param {string[]} args.trackerNames - A list of extra trackers to run the command on.
 * @param {string} args.title - e.g. 'Other Ways to Help'
 */
export const gaPageView = ({ path, trackerNames, title }) => async (
  dispatch,
  getState,
  { GoogleAnalytics },
) => {
  try {
    GoogleAnalytics.pageview(path, trackerNames, title);
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err); // eslint-disable-line no-console
    }
  }
};

/**
 * @param {object} args
 * @param {string} args.category - A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
 * @param {string} args.action - A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
 * @param {string} [args.label] - More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
 * @param {integer} [args.value] - A means of recording a numerical value against an event. E.g. a rating, a score, etc.
 * @param {boolean} [args.nonInteraction] - If an event is not triggered by a user interaction, but instead by our code (e.g. on page load, it should be flagged as a nonInteraction event to avoid skewing bounce rate data.
 * @param {string} [args.transport] - This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.
 */
export const gaEvent = args => async (
  dispatch,
  getState,
  { GoogleAnalytics },
) => {
  try {
    GoogleAnalytics.event(args);
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err); // eslint-disable-line no-console
    }
  }
};

/**
 * @param {object} fields - Object. e.g. { userId: 123 }
 */
export const gaSet = fields => async (
  dispatch,
  getState,
  { GoogleAnalytics },
) => {
  try {
    GoogleAnalytics.set(fields);
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err); // eslint-disable-line no-console
    }
  }
};
