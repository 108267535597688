import React from 'react';
import { bool, string, func, shape, oneOfType, number } from 'prop-types';
import { StyledInput, StyledLabel, Message } from '../Input';
import { inputThemes } from '../../constants/styles-old';

const PropTypes = {
  className: string,
  label: string,
  id: oneOfType([string, number]),
  defaultValue: string,
  error: string,
  onFocus: func,
  onBlur: func,
  onChange: func,
  theme: shape({}),
  disabled: bool,
};

const DefaultProps = {
  className: null,
  label: null,
  id: null,
  defaultValue: '',
  onFocus: null,
  onBlur: null,
  onChange: null,
  error: null,
  theme: inputThemes.light,
  disabled: false,
};

const StyledTextArea = StyledInput.withComponent('textarea');

const TextArea = ({
  className,
  label,
  id,
  defaultValue,
  onBlur,
  onFocus,
  onChange,
  error,
  theme = inputThemes.light,
  disabled,
}) => (
  <div className={className}>
    {label ? (
      <StyledLabel error={error} htmlFor={id} theme={theme}>
        {label}
      </StyledLabel>
    ) : null}
    <StyledTextArea
      id={id}
      defaultValue={defaultValue}
      error={error}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      theme={theme}
      disabled={disabled}
    />
    {error ? <Message error={error}>{error}</Message> : null}
  </div>
);

TextArea.propTypes = PropTypes;
TextArea.defaultProps = DefaultProps;

export default TextArea;
