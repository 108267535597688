/* eslint-disable import/prefer-default-export */

export const KIINTEISTOVAHTI_SERVICE_TYPES = {
  BASIC: 'kiinteistovahti',
  AUTO: 'kiinteistovahti-auto',
  FIXED: 'kiinteistovahti-fixed',
};

export const KIINTEISTOVAHTI_SERVICE_NAMES = {
  BASIC: 'Kiinteistövahti basic',
  AUTO: 'Kiinteistövahti Auto',
  FIXED: 'Kiinteistövahti',
};
