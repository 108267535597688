import styled, { css } from 'styled-components';
import {
  ComponentRadius,
  dividerColor,
  appBackgroundColor,
  black,
  fontSizeSmaller,
  fontFamilyNarrow,
} from '../../constants/styles-old';

export const OptionTitle = styled.div`
  margin-left: 0.75rem;
  margin-right: 1.75rem;
  display: inline-block;
`;

export const Options = styled.ul`
  z-index: 10;
  position: absolute;
  width: 100%;

  ${props =>
    props.alignDropdown !== 'left'
      ? css`
          right: 0rem;
        `
      : css`
          left: 0rem;
        `};

  border: 1px solid ${dividerColor};
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
  padding: 0.5rem;
  margin-top: 0.25rem;
  border-radius: ${ComponentRadius};
`;

export const Option = styled.li`
  font-size: ${fontSizeSmaller};
  font-family: ${fontFamilyNarrow};
  border-radius: ${ComponentRadius};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: inline-block;
  font-weight: 400;
  line-height: 1.6rem;
  padding: 0.25rem 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-weight: ${props => (props.selected ? '600' : '400')};
  &:hover {
    background-color: ${appBackgroundColor};
    color: ${black};
  }

  span,
  svg.svg-inline--fa {
    display: inline-block;
    font-size: 0.5625rem;
    width: 1.25rem;
  }

  ${props =>
    props.isHighlighted
      ? css`
          background-color: ${appBackgroundColor};
          color: ${black};
        `
      : css`
          background-color: ${props.theme.dropdownItemBackgroundColor};
          color: ${props.theme.dropdownColor};
        `};

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props.theme.backgroundColorDisabled} !important;
      color: ${props.theme.dropdownColor} !important;
      font-weight: 400 !important;
    `};
`;
