import { compose } from 'recompose';

export const nbsp = '\u00a0'; // non breaking space

export const capitalizeFirstLetter = str =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const flatMap = (f, arr) => arr.reduce((x, y) => [...x, ...f(y)], []);

const roundToOneDecimal = x => Math.round(x * 10) / 10;
const replacePeriod = x => x.toString().replace('.', ',');
const toCelsius = x => `${x}${nbsp}°C`;
const toPercentage = x => `${x}${nbsp}%`;
const toEuros = x => `${x}${nbsp}€`;

export const formatTemp = compose(
  toCelsius,
  replacePeriod,
  roundToOneDecimal,
);

export const formatHumid = compose(
  toPercentage,
  replacePeriod,
  Math.round,
);

export const formatEuros = compose(
  toEuros,
  replacePeriod,
  Math.round,
);

export const formatPercentage = compose(
  toPercentage,
  replacePeriod,
  roundToOneDecimal,
);

export const getMean = arr =>
  (arr.reduce((acc, value) => acc + value, 0) * 1.0) / arr.length;

export const getMin = arr =>
  arr.reduce(
    (acc, value) => (value < acc ? value : acc),
    Number.MAX_SAFE_INTEGER,
  );

export const getMax = arr =>
  arr.reduce(
    (acc, value) => (value > acc ? value : acc),
    Number.MIN_SAFE_INTEGER,
  );

/*
 * @url https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
 * from the comments as stuff was bad
 */

/* eslint-disable */
export const shadeColor = (color, amount) =>
  '#' +
  color
    .replace(/^#/, '')
    .replace(/../g, color =>
      (
        '0' +
        Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
      ).substr(-2),
    );
/* eslint-enable */
