const fonts = {
  sizes: {
    Spotlight: '4rem',
    H1: '2.5rem',
    H2: '2rem',
    H3: '1.75rem',
    H4: '1.25rem',
    base: '1rem',
    link: '1rem',
    small: '0.875rem',
    smaller: '0.75rem',
    smallLink: '0.875rem',
    mini: '0.75rem',
  },
  helenSizes: {
    calculatorPriceTag: '2.625rem',

    teaserPrice: '3.125rem',
    teaserHeading: '1.875rem',
    headline: '1.875rem',
    base: '1.125rem',
    small: '1rem',
    smaller: '0.875rem',
  },
  weights: {
    Spotlight: 600,
    link: 'bold',
    smallLink: 'bold',
    stronger: 500,
    mini: 400,
    regular: 400,
  },
  families: {
    fontFamilySansSerif:
      '"Inter UI", "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontFamilyNarrow:
      '"Inter UI", "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontFamilySerif: 'Georgia, "Times New Roman", Times, serif',
    fontFamilyMonoSpace:
      'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    helenFontFamilySansSerif:
      '"Gotham Narrow SSm A", "Gotham Narrow SSm B", "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    helenFontFamilyChap:
      '"Chap Bold","Gotham SSm 7r","Gotham Narrow SSm A", "Gotham Narrow SSm B","Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  },
};

export default fonts;
