import { arrayOf, func, number, string, shape, oneOfType } from 'prop-types';
import React, { Fragment } from 'react';
import { mediaBreakpointUpMd } from 'styled-bootstrap-responsive-breakpoints';
import styled from 'styled-components';
import { Heading6 } from '../../HelenHeading';
import Select from '../../HelenSelect';
import ArrowSvg from '../../../assets/quotation-arrow.svg';

import PriceSlider from '../../../containers/PriceSliderContainer';

import { colors, fonts, themes } from '../../../constants/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: 1rem;
  position: relative;
  min-height: 393px;
  height: 100%;

  max-width: 512px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  ${mediaBreakpointUpMd`
    flex-direction: row;
    min-height: 393px;
    max-width: 938px; 
  `};
`;

const PriceSliderContainer = styled(PriceSlider)`
  align-self: center;
`;

const Boxy = styled.div`
  flex: 1 1 100%;
  max-width: 100%;
  background: ${colors.white};
  padding: ${props => props.padding || '2.5rem'};
  display: flex;
  flex-direction: column;

  min-height: 393px;
  order: ${props => (props.leftSide ? 1 : 2)};
  ${props =>
    !props.leftSide
      ? `
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  `
      : ''}
  ${mediaBreakpointUpMd`
    order: ${props => (props.leftSide ? 1 : 3)};
    flex-basis: 50%;
    max-width: 50%;  
  `};
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 2.5rem;
  padding-top: 1rem;
`;

const ArrowDown = styled.div`
  order: 3;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  background: ${colors.helenLightBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${mediaBreakpointUpMd`
    order: 2;
    bottom: -1.5rem;
  `};
`;

const PriceDisclaimer = styled.div`
  font-size: ${props =>
    props.large ? fonts.helenSizes.base : fonts.helenSizes.smaller};
  white-space: pre-wrap;
  font-weight: 300;

  padding-top: 1rem;
`;

const Title = styled(Heading6)`
  font-size: ${fonts.helenSizes.base};
  letter-spacing: -0.67px;
  align-self: flex-start;
  color: ${colors.night};
  text-transform: none;
`;
const SubTitle = styled(Heading6)`
  font-size: ${fonts.helenSizes.smaller};
  white-space: pre-wrap;
  font-weight: 300;
  margin-bottom: 3rem;
  text-transform: none;
`;

const AgeSelectionTitle = styled(Heading6)`
  font-size: 1rem;
  letter-spacing: -0.67px;
  margin-bottom: 1rem;
  color: ${colors.night};
  text-transform: none;
`;

const AgeSelectionContainer = styled.div``;

const ApartmentRow = styled.div`
  display: flex;
  justify-content: ${props => (props.allApartments ? 'center' : 'flex-start')};
  line-height: normal;
  margin-left: ${props => (props.allApartments ? '0' : '0.8125rem')};
`;

const ApartmentText = styled.div`
  align-self: center;
  color: ${colors.helenTypeColorLight};
  font-size: ${fonts.helenSizes.base};
  line-height: ${fonts.helenSizes.base};
  font-weight: bold;
  margin-left: 0.35rem;
  margin-top: 0.15rem;
`;

const PriceContainer = styled.div`
  padding: 1rem;
  border-bottom: ${({ small }) =>
    small ? 'none' : `0.5px solid ${colors.helenDivider}`};
  padding-bottom: 1rem;
`;
const PriceTag = styled.div`
  color: ${colors.night};
  font-size: ${({ small }) =>
    small ? fonts.helenSizes.base : fonts.helenSizes.calculatorPriceTag};
  font-weight: bold;
`;

const PriceTitle = styled(Heading6)`
  font-size: ${({ small }) =>
    small ? fonts.helenSizes.small : fonts.helenSizes.base};

  letter-spacing: -0.67px;
  align-self: flex-start;
  color: ${colors.night};
  text-transform: none;
`;

const QuotationArrow = styled.img`
  position: relative;
  width: 32px;
`;

const AnnualInterval = styled.span`
  line-height: 24px;
  font-size: ${fonts.helenSizes.smaller};
  font-weight: 350;
`;
const SmallPrint = styled.span`
  line-height: 24px;
  font-size: ${fonts.helenSizes.smaller};
`;

// eslint-disable-next-line react/prop-types
export const PriceBox = ({ small, title, price, unit, vat, subText }) => (
  <PriceContainer small={small}>
    <PriceTitle small={small}>{title}</PriceTitle>
    <PriceTag small={small}>
      {price} {unit}
      <AnnualInterval>{vat}</AnnualInterval>
    </PriceTag>
    <SmallPrint>{subText}</SmallPrint>
  </PriceContainer>
);

const formatTooltip = ({ allApartments, sliderTooltipApartments }) => value => {
  return (
    <Fragment>
      <ApartmentRow allApartments={allApartments}>
        <ApartmentText>
          {value} {sliderTooltipApartments}
        </ApartmentText>
      </ApartmentRow>
    </Fragment>
  );
};

const ageOptions = constructionDecades => [
  { value: 'unknown', name: 'Ei tiedossa' },
  ...constructionDecades.map(decade => ({
    value: decade,
    name: decade.toString(),
  })),
];

const CalculatorSection = ({
  apartmentCountTitle,
  apartmentCountSubtitle,
  calculateMeterCount,
  constructionDecade,
  constructionDecades,
  decadeSelectionTitle,
  defaultApartmentCount,
  minApartmentCount,
  // onGaEvent,
  setApartmentCount,
  setConstructionDecade,
  sliderTooltipApartments,
  sliderTooltipMeters,
  savingsTitle,
  savingsSecondaryTitle,
  annualSavings,
  savingPotentialSubtext,
  savingsPlaceholder,
  scrollRef,
  priceGroups,
}) => {
  return (
    <Container>
      <Boxy leftSide>
        <Title>{apartmentCountTitle}</Title>
        <SubTitle>{apartmentCountSubtitle}</SubTitle>
        <PriceSliderContainer
          calculateMeterCount={calculateMeterCount}
          defaultValue={defaultApartmentCount}
          formatTooltip={formatTooltip({
            calculateMeterCount,
            sliderTooltipApartments,
            sliderTooltipMeters,
          })}
          maxValue={priceGroups[priceGroups.length - 1].maxApartmentCount}
          minValue={minApartmentCount}
          onChangeValue={value => {
            setApartmentCount(value);
            // onGaEvent({
            //   action: 'Select apartment count',
            //   category: 'Quotation form',
            //   label: value.toString(),
            //   value,
            // });
          }}
        />
        <AgeSelectionContainer>
          <AgeSelectionTitle>{decadeSelectionTitle}</AgeSelectionTitle>
          <Select
            defaultValue={constructionDecade.name}
            selectedItem={constructionDecade}
            theme={themes.helenBrandInputTheme}
            onChange={value => {
              setConstructionDecade(value);
              // onGaEvent({
              //   action: 'Select construction decade',
              //   category: 'Quotation form',
              //   label: value.toString(),
              //   value: Number(value),
              // });
            }}
            options={ageOptions(constructionDecades)}
          />
        </AgeSelectionContainer>
      </Boxy>
      <ArrowDown
        onClick={() => {
          const iframeScrollRef = scrollRef?.current?.offsetTop || 500;
          let domain;
          if (window.location.origin.includes('localhost')) {
            domain = window.location.origin;
          } else {
            domain = !window.location.origin.includes('dev.enneapi.io')
              ? 'https://www.helen.fi'
              : 'https://prep.helen.fi';
          }

          try {
            window.parent.postMessage(
              { name: 'scrollframe', iframeScrollRef },
              domain,
            );
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        }}
      >
        <QuotationArrow src={ArrowSvg} />
      </ArrowDown>
      <Boxy noDecade={constructionDecade.value === 'unknown'}>
        {constructionDecade.value === 'unknown' ? (
          <PriceContainer small>
            <PriceTitle>{savingsTitle}</PriceTitle>
            <PriceDisclaimer large>{savingsPlaceholder}</PriceDisclaimer>
          </PriceContainer>
        ) : (
          <>
            <PriceBox
              title={savingsTitle}
              price={annualSavings}
              unit="€"
              vat={' /vuosi (Alv 24 %) '}
            />
            <PriceBox
              title={savingsSecondaryTitle}
              price={5 * annualSavings}
              unit="€ "
              vat={' (Alv 24 %) '}
            />

            <PriceDisclaimer>{savingPotentialSubtext}</PriceDisclaimer>
          </>
        )}
      </Boxy>
    </Container>
  );
};

CalculatorSection.propTypes = {
  apartmentCountTitle: string.isRequired,
  apartmentCountSubtitle: string.isRequired,
  calculateMeterCount: func.isRequired,
  constructionDecade: shape({
    name: string,
    value: string,
  }).isRequired,
  constructionDecades: arrayOf(string).isRequired,
  decadeSelectionTitle: string.isRequired,
  defaultApartmentCount: number.isRequired,
  minApartmentCount: number.isRequired,
  // onGaEvent: func.isRequired,
  setApartmentCount: func.isRequired,
  setConstructionDecade: func.isRequired,
  sliderTooltipApartments: string.isRequired,
  sliderTooltipMeters: string.isRequired,
  savingsTitle: string.isRequired,
  savingsSecondaryTitle: string.isRequired,
  annualSavings: number.isRequired,
  savingPotentialSubtext: string.isRequired,
  savingsPlaceholder: string.isRequired,
  scrollRef: oneOfType([func, shape({})]).isRequired,
  priceGroups: arrayOf(
    shape({
      maxApartmentCount: number,
      baseFee: number,
      monthlyFee: number,
    }),
  ).isRequired,
};

export default CalculatorSection;
