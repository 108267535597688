import React from 'react';
import { string } from 'prop-types';
import TextColumn from '../TextColumn';
import { Section, SectionTitle, SectionText, SectionImage } from '../Section';

const HowSection = ({ howText, howTitle, howImage }) => (
  <Section>
    <TextColumn>
      <SectionTitle>{howTitle}</SectionTitle>
      <SectionText dangerouslySetInnerHTML={{ __html: howText }} />
    </TextColumn>
    <SectionImage src={howImage} alt={howTitle} />
  </Section>
);

HowSection.propTypes = {
  howText: string.isRequired,
  howTitle: string.isRequired,
  howImage: string.isRequired,
};

export default HowSection;
