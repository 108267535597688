import {
  UPDATE_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
} from '../../actions/index.js';

const initialState = {
  value: null,
};

export default (state = initialState, action) => {
  const { type, value } = action;

  if (type === UPDATE_SEARCH_PARAMETERS || type === CLEAR_SEARCH_PARAMETERS) {
    return {
      ...state,
      value,
    };
  }

  return state;
};
