import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';

import { withAuth } from '../../lib/Auth/shallow';
import VerticalNavbar from '../../components/VerticalNavbar';
import {
  Add,
  Clock,
  Faq,
  Pin,
  Profile as ProfileIcon,
  Users,
} from '../../components/VerticalNavbar/Icons';
import { toggleNavigation } from '../../actions';
import roles from '../../lib/user-roles';

const navItems = [
  {
    id: 'places',
    name: 'Kohteet',
    icon: Pin,
    enabled: true,
  },
  {
    id: 'alerts',
    name: 'Hälytykset',
    icon: Clock,
    enabled: true,
  },
  {
    id: 'create',
    name: 'Lisää uusi...',
    icon: Add,
    enabled: ({ 'https://raportointi.enne.helen.fi/role': role }) =>
      roles[role] > roles.read_only,
  },
  {
    id: 'internal',
    name: 'Hallinta',
    icon: Users,
    enabled: ({ 'https://raportointi.enne.helen.fi/role': role }) =>
      roles[role] === roles.super_admin,
  },
  {
    id: 'admin/users',
    name: 'Käyttäjät',
    icon: Users,
    enabled: ({ 'https://raportointi.enne.helen.fi/role': role }) =>
      roles[role] === roles.admin,
  },
  {
    id: 'faq',
    name: 'Ohjeet',
    icon: Faq,
    enabled: true,
  },
  {
    id: 'profile',
    name: 'Oma tili',
    icon: ProfileIcon,
    enabled: true,
  },
];

const mapStateToProps = (
  { Navigation: { open }, Profile },
  { auth, location },
) => ({
  Profile,
  navItems,
  selectedItem: location.pathname.split('/')[1] || 'home',
  isAuthenticated: auth.isAuthenticated(),
  auth,
  open,
});

const mapDispatchToProps = dispatch => ({
  toggleNavigation: () => dispatch(toggleNavigation()),
});

export default compose(
  withRouter,
  withAuth,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withApollo,
)(VerticalNavbar);
