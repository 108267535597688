import { Component } from 'react';
import { func, node, shape, oneOfType } from 'prop-types';
import Auth from './auth';
import AuthPropTypes from './authPropTypes';

const PropTypes = {
  children: oneOfType([node, func]).isRequired,
  history: shape({}),
  dispatch: func,
};

const DefaultProps = {
  dispatch: null,
  history: null,
};

class AuthProvider extends Component {
  constructor(props) {
    super();
    const { history, dispatch } = props;

    if (!history) {
      throw new Error("You must pass prop 'history' to AuthProvider");
    }

    if (!dispatch) {
      throw new Error("You must pass prop 'dispatch' to AuthProvider");
    }

    this.auth = new Auth(history, dispatch);
  }

  getChildContext() {
    return {
      auth: this.auth,
    };
  }

  render() {
    const { children: renderFnOrChildren } = this.props;

    if (typeof renderFnOrChildren === 'function') {
      return renderFnOrChildren({ auth: this.auth });
    }

    return renderFnOrChildren;
  }
}

AuthProvider.propTypes = PropTypes;
AuthProvider.defaultProps = DefaultProps;

AuthProvider.childContextTypes = {
  auth: AuthPropTypes.isRequired,
};

export default AuthProvider;
