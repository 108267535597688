import { Box, Flex } from '@rebass/grid';
import { Formik } from 'formik';
import isEmpty from 'lodash.isempty';
import { func, shape, string } from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import Yup from 'yup';

import Button from '../../HelenButton';

import Input from '../../HelenInput';
import Select from '../../HelenSelect';
import TextArea from '../../HelenTextArea';

import { colors, themes } from '../../../constants/styles';

const mediaBreakpoint = 850;
const formBreakpoint = 640;

const Container = styled.div``;

const Form = styled.form`
  align-items: left;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${props => props.heightProp || 'auto'};
  max-width: ${props => props.maxWidthProp || '100%'};
  overflow: ${props => props.overflowProp || 'initial'};
  width: ${props => props.widthProp || 'auto'};
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;

    button {
      margin-right: 0;
      width: 100%;

      + button {
        margin-top: 1rem;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  align-self: center;
  margin-right: 1rem !important;
  min-width: 204px;
  border-radius: 25px;
  align-self: center;
  background-color: ${props =>
    !props.outline ? colors.helenBackground : colors.white};
  color: ${props =>
    props.outline ? colors.helenBackground : colors.white} !important;
  border: ${props =>
    props.outline
      ? `1px solid ${colors.helenDivider}`
      : '1px solid transparent'};
  &:hover,
  &:active {
    background-color: ${props =>
      !props.outline ? colors.helenPrimaryHover : colors.helenSecondaryHover};
    border-color: ${props =>
      props.outline ? colors.helenBorder : colors.helenBackground};
    color: ${colors.helenBackground};
    text-decoration: none;
  }
  @media (max-width: ${mediaBreakpoint}px) {
    align-self: flex-start;
    margin-right: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
`;

const Error = styled.div`
  color: ${colors.helenBrandDangerColor};
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.5rem;
`;

const boxWidth = [1, 0.5, 1 / 3];
const boxSpacing = [0, 29];
const boxPadding = ['14px', '24px'];

const getBoxPadding = (idx, width) => {
  if (width === 1) {
    return { pl: 0, pr: 0, pb: boxPadding };
  }

  if ((idx + 1) % (1 / width) === 0) {
    return { pl: 0, pr: 0, pb: boxPadding };
  }
  return { pl: 0, pr: boxSpacing, pb: boxPadding };
};

const getButtonText = ({
  isSubmitting,
  status,
  submitButtonText,
  submittedButtonText,
  submittingButtonText,
}) => {
  if (isSubmitting) {
    return submittingButtonText;
  }

  if (status && status === 'submitted') {
    return submittedButtonText;
  }

  return submitButtonText;
};

const renderField = ({ setFieldValue, type, ...rest }) => {
  switch (type) {
    case 'select':
      return (
        <Select
          {...rest}
          theme={themes.helenBrandInputTheme}
          onChange={e => {
            setFieldValue(rest.id, e.value);
          }}
        />
      );
    case 'textarea':
      return <TextArea {...rest} />;
    default:
      return (
        <Input type={type} {...rest} theme={themes.helenBrandInputTheme} />
      );
  }
};

renderField.propTypes = {
  setFieldValue: func.isRequired,
  type: string,
};

renderField.defaultProps = {
  type: '',
};

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: 'manager',
  address: '',
  zip: '',
  info: '',
};

const validationSchema = info =>
  Yup.object().shape({
    firstName: Yup.string().required(info.firstName.errorMessage),
    lastName: Yup.string().required(info.lastName.errorMessage),
    email: Yup.string()
      .email(info.email.errorMessage)
      .required(info.email.errorMessage),
    phone: Yup.string().required(info.phone.errorMessage),
    role: Yup.string().required(info.role.errorMessage),
    address: Yup.string().required(info.address.errorMessage),
    zip: Yup.string().required(info.zip.errorMessage),
    info: Yup.string(),
  });

const FormSection = ({
  backButtonText,
  fieldInfo,
  goBack,
  initialValues,
  // onGaEvent,
  onSubmit,
  submitButtonText,
  submitError,
  submittedButtonText,
  submittingButtonText,
  ...props
}) => (
  <Container>
    <Formik
      initialValues={initialValues || defaultValues}
      isInitialValid
      onSubmit={onSubmit}
      validationSchema={validationSchema(fieldInfo)}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        status,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Flex
            alignItems="stretch"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            {Object.keys(fieldInfo).map((id, i) => {
              const {
                defaultValue,
                optionsKey,
                type,
                width,
                ...rest
              } = fieldInfo[id];

              return type !== 'empty' ? (
                <Box
                  key={id}
                  width={width || boxWidth}
                  {...getBoxPadding(i, width)}
                >
                  {renderField({
                    ...rest,
                    defaultValue: values[id] || defaultValue,
                    error: touched[id] && errors[id],
                    id,
                    name: id,
                    onBlur: handleBlur,
                    onChange: handleChange,
                    options: optionsKey && props[optionsKey],
                    setFieldValue,
                    touched: touched[id],
                    type,
                  })}
                </Box>
              ) : (
                <MediaQuery key={id} minWidth={formBreakpoint}>
                  <Box width={boxWidth} {...getBoxPadding(i)} />
                </MediaQuery>
              );
            })}
          </Flex>
          {// FIXME: This should be customizable
          submitError && (
            <Error>Tapahtui virhe, yritä myöhemmin uudelleen</Error>
          )}
          <BottomRow>
            <ButtonGroup>
              <StyledButton
                outline
                onClick={e => {
                  e.preventDefault();
                  // onGaEvent({
                  //   action: 'Navigate back to price calculation',
                  //   category: 'Quotation form',
                  // });
                  goBack(values);
                }}
              >
                {backButtonText}
              </StyledButton>
              <StyledButton
                disabled={!isValid || isSubmitting || !isEmpty(errors)}
                type="submit"
              >
                {getButtonText({
                  isSubmitting,
                  status,
                  submitButtonText,
                  submittedButtonText,
                  submittingButtonText,
                })}
              </StyledButton>
            </ButtonGroup>
          </BottomRow>
        </Form>
      )}
    />
  </Container>
);

FormSection.propTypes = {
  backButtonText: string.isRequired,

  fieldInfo: shape({}).isRequired,
  goBack: func.isRequired,
  initialValues: shape({}),
  // onGaEvent: func.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  submitError: string,
  submittedButtonText: string.isRequired,
  submittingButtonText: string.isRequired,
  setFieldValue: func,
  type: string,
};

FormSection.defaultProps = {
  initialValues: null,
  submitError: null,
  setFieldValue: undefined,
  type: undefined,
};

export default FormSection;
