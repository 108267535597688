import React from 'react';

const ErrorIcon = () => (
  <svg width="20" height="17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.77 14.875L10.954.58c-.462-.773-1.463-.773-1.925 0L.214 14.875c-.54.889 0 2.125.962 2.125h17.631c.963 0 1.54-1.236.963-2.125zm-9.778-.966a1.136 1.136 0 0 1-1.155-1.159c0-.657.5-1.16 1.155-1.16.654 0 1.155.503 1.155 1.16 0 .657-.5 1.16-1.155 1.16zm1.155-3.477c0 .232-.154.386-.385.386h-1.54c-.231 0-.385-.154-.385-.386V5.409c0-.232.154-.386.385-.386h1.54c.23 0 .385.154.385.386v5.023z"
      fill="#FFF"
    />
  </svg>
);

export default ErrorIcon;
