import React, { useState } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { mediaBreakpointDownXs } from 'styled-bootstrap-responsive-breakpoints';

import CloseIcon from '../CloseIcon';

import {
  helenAltBrandColor,
  helenAltBrandColorHover,
  helenTypeColor,
} from '../../../../constants/styles-old';

const Container = styled.div`
  background-color: #dfe0ec;
  border-radius: 3px;
  border: 2px solid #dfe0ec;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.5rem;
  padding: 1rem 1.375rem;

  ${mediaBreakpointDownXs`
    padding: 0.5rem; 0.6875rem;
  `};
`;

const Content = styled.div``;

const CloseButton = styled.div`
  cursor: pointer;
  height: 100%;

  svg {
    fill: ${helenAltBrandColor};
  }

  &:hover svg {
    fill: ${helenAltBrandColorHover};
  }
`;

const Title = styled.div`
  color: ${helenTypeColor};
  font-size: 0.875rem;
  font-weight: 500;
`;

const Description = styled.div`
  color: ${helenTypeColor};
  font-size: 0.875rem;
  font-weight: 300;
  margin-top: 0.375rem;
  letter-spacing: -0.35px;

  a {
    color: ${helenAltBrandColor};
    font-weight: 400;
  }

  a:hover {
    color: ${helenAltBrandColorHover};
  }
`;

const Notification = ({ description, title }) => {
  const [isClosed, setIsClosed] = useState(false);

  if (isClosed) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </Content>
      <CloseButton
        role="button"
        onClick={() => {
          setIsClosed(true);
        }}
      >
        <CloseIcon alt="Close" />
      </CloseButton>
    </Container>
  );
};

Notification.propTypes = {
  description: string.isRequired,
  title: string.isRequired,
};

export default Notification;
