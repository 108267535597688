import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { func } from 'prop-types';
import gql from 'graphql-tag';
import idx from 'idx.macro';

import { successToast } from '../../Toastr';

import showError from '../../../lib/ShowError';
import EditObservationStationForm from '../../EditObservationStationForm';

import ObservationStationsQuery from '../../../containers/ObservationStationsList/ObservationStationsQuery';

export const getAdjustmentCurveProps = ({
  ownProps,
  data: { loading, adjustmentCurveChange },
}) => ({
  ...ownProps,
  loading,
  data: !loading ? idx(adjustmentCurveChange, _ => _.nodes) || [] : [],
});

export const getAdjustmentCurveOptions = ({ heatingGroupId }) => ({
  variables: {
    heatingGroupId,
  },
  notifyOnNetworkStatusChange: true,
});
const updateObservationStationActivity = gql`
  mutation updateObservationStationByFmisid($fmisid: Int!, $active: Boolean!) {
    updateObservationStationByFmisid(
      input: { fmisid: $fmisid, observationStationPatch: { active: $active } }
    ) {
      observationStation {
        fmisid
        active
      }
    }
  }
`;

const handleSubmit = ({ observationStations, closeModal, gqlUpdate }) => async (
  { ...rest },
  { setSubmitting },
) => {
  const changed = rest.observationStations.filter(({ fmisid, active }) =>
    observationStations.find(
      ({ fmisid: orgId, active: orgActive }) =>
        fmisid === orgId && active !== orgActive,
    ),
  );

  Promise.all(
    changed.map(({ fmisid, active }) => {
      return gqlUpdate({
        variables: {
          fmisid,
          active,
        },
        refetchQueries: [
          {
            query: ObservationStationsQuery,
            variables: {},
          },
        ],
      });
    }),
  )
    .then(res => {
      successToast(`${res?.length || 0} sääasema(a) muutettu`);
      closeModal();
    })
    .catch(err => {
      showError(err);
    })
    .finally(() => {
      setSubmitting(false);
    });
};

const ComponentWithMutations = ({ closeModal, ...rest }) => {
  const { loading, data } = useQuery(ObservationStationsQuery);
  const [editObservationActivity] = useMutation(
    updateObservationStationActivity,
  );

  return (
    <EditObservationStationForm
      {...rest}
      observationStations={data?.stations?.nodes}
      loading={loading}
      isSubmitting={false}
      buttonText={`Päivitä muutokset`}
      onSubmit={handleSubmit({
        observationStations: data?.stations?.nodes,
        closeModal,
        gqlUpdate: editObservationActivity,
      })}
    />
  );
};

ComponentWithMutations.propTypes = {
  closeModal: func.isRequired,
};
export default ComponentWithMutations;
