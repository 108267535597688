import React from 'react';

export default props => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M26 13c0 2.341-.585 4.508-1.756 6.5a13.136 13.136 0 0 1-4.744 4.744C17.508 25.414 15.341 26 13 26s-4.508-.585-6.5-1.756A13.136 13.136 0 0 1 1.756 19.5C.586 17.508 0 15.341 0 13s.585-4.508 1.756-6.5A13.136 13.136 0 0 1 6.5 1.756C8.492.586 10.659 0 13 0s4.508.585 6.5 1.756A13.136 13.136 0 0 1 24.244 6.5C25.414 8.492 26 10.659 26 13zM13.367 4.298c-1.398 0-2.604.297-3.617.892-.944.524-1.782 1.345-2.516 2.463a.525.525 0 0 0-.105.446.661.661 0 0 0 .262.393l1.835 1.363a.492.492 0 0 0 .42.13.574.574 0 0 0 .419-.235c.559-.664.995-1.118 1.31-1.363a2.813 2.813 0 0 1 1.677-.524c.595 0 1.145.166 1.652.498.506.332.76.742.76 1.232 0 .384-.158.716-.472.996-.21.174-.612.437-1.206.786l-.105.052c-.873.49-1.485.926-1.834 1.31a3.314 3.314 0 0 0-.944 2.36v.21c0 .174.061.323.184.445.122.122.27.183.445.183h2.936a.607.607 0 0 0 .445-.183.607.607 0 0 0 .184-.446v-.052c0-.28.14-.524.42-.734.174-.14.506-.35.995-.629.909-.559 1.555-1.048 1.94-1.468.664-.733.996-1.66.996-2.778 0-.978-.297-1.878-.892-2.7a6.24 6.24 0 0 0-2.306-1.939c-.944-.472-1.905-.708-2.883-.708zm-.367 13c-.664 0-1.232.236-1.704.708a2.322 2.322 0 0 0-.707 1.704c0 .664.236 1.232.707 1.703.472.472 1.04.708 1.704.708.664 0 1.232-.236 1.704-.708.471-.471.707-1.04.707-1.703 0-.664-.236-1.232-.707-1.704A2.322 2.322 0 0 0 13 17.298z"
      fillRule="evenodd"
    />
  </svg>
);
