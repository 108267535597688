import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
import { array, bool, element, func, oneOfType, string } from 'prop-types';

import {
  helenButtonThemes,
  helenComponentRadius,
  helenFontFamilySansSerif,
} from '../../constants/styles-old';

const PropTypes = {
  children: oneOfType([element, string, array]).isRequired,
  disabled: bool,
  fullWidth: bool,
  hash: bool,
  link: bool,
  protocolLink: bool,
  scroll: func,
};

const DefaultProps = {
  disabled: false,
  fullWidth: false,
  hash: false,
  link: false,
  protocolLink: false,
  scroll: null,
};

const StyledButton = styled.button`
  background-color: ${props =>
    !props.outline ? props.theme.backgroundColor : 'white'};
  border-radius: ${helenComponentRadius};
  border: ${props =>
    props.outline
      ? `2px solid ${props.theme.backgroundColor}`
      : '2px solid transparent'};

  color: ${props =>
    props.outline ? props.theme.backgroundColor : props.theme.color} !important;
  cursor: pointer;
  display: inline-block;
  font-family: ${helenFontFamilySansSerif};
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  line-height: 1.15;
  margin: 0;
  outline: none;
  padding: 0.875rem 1.75rem;
  pointer-events: ${props => (props.disabled ? 'none' : 'inherit')};
  position: relative;
  text-align: center;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
  }
  &:hover,
  &:active {
    background-color: ${props =>
      !props.outline ? props.theme.hover : 'white'};
    border-color: ${props =>
      props.outline ? props.theme.hover : 'transparent'};
    color: ${props => (props.outline ? props.theme.hover : props.theme.color)};
    text-decoration: none;
  }
  &:focus {
    outline: 0;
  }
  &:disabled {
    background-color: ${props => props.theme.disabled};
    border: none;
    color: ${props => props.theme.disabledColor};
  }
  &:disabled:hover,
  &:disabled:active {
    background: ${props => props.theme.disabled};
    border: none;
    color: ${props => props.theme.disabledColor};
    text-decoration: none;
  }
`;

const StyledLink = styled(
  StyledButton.withComponent(({ outline, ...props }) => <Link {...props} />),
)`
  flex: 1;
`;

const StyledProtocolLink = styled(StyledButton.withComponent('a')).attrs({
  target: '_blank',
})`
  flex: 1;
`;

const getTheme = ({ danger, secondary }) => {
  if (danger) {
    return helenButtonThemes.danger;
  }

  if (secondary) {
    return helenButtonThemes.secondary;
  }

  return helenButtonThemes.primary;
};

const Button = ({
  children,
  fullWidth,
  hash,
  link,
  protocolLink,
  scroll,
  ...rest
}) => {
  const { secondary, danger, ...otherProps } = rest;

  if (protocolLink) {
    return (
      <ThemeProvider theme={getTheme({ secondary, danger })}>
        <StyledProtocolLink
          style={{ width: fullWidth ? '100%' : 'auto' }}
          {...otherProps}
        >
          {children}
        </StyledProtocolLink>
      </ThemeProvider>
    );
  }

  if (link) {
    return (
      <ThemeProvider theme={getTheme({ secondary, danger })}>
        <StyledLink
          style={{ width: fullWidth ? '100%' : 'auto' }}
          {...otherProps}
        >
          {children}
        </StyledLink>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={getTheme({ secondary, danger })}>
      <StyledButton fullWidth={fullWidth} {...rest}>
        {children}
      </StyledButton>
    </ThemeProvider>
  );
};

Button.propTypes = PropTypes;
Button.defaultProps = DefaultProps;

export default Button;
