import React from 'react';
import { arrayOf, func, shape, string, bool } from 'prop-types';
import styled from 'styled-components';
import { mediaBreakpointUpMd } from 'styled-bootstrap-responsive-breakpoints';
import {
  white,
  brandColor,
  black,
  fontSizeSmall,
  fontFamilyNarrow,
} from '../../constants/styles-old';

const PropTypes = {
  legend: string,
  options: arrayOf(
    shape({
      name: string,
      value: string,
    }),
  ).isRequired,
  onChange: func.isRequired,
  selectedValue: string,
  forceColumn: bool,
  id: string.isRequired,
  disabled: bool,
};

const DefaultProps = {
  legend: null,
  forceColumn: false,
  selectedValue: null,
  disabled: false,
};

export const Fieldset = styled.div``;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${mediaBreakpointUpMd`
    flex-direction: ${props => (!props.forceColumn ? 'row' : 'column')};
  `};
`;

const RadioWrapper = styled.div`
  ${mediaBreakpointUpMd`
    &:not(:first-of-type) {
      padding-left: ${props => (!props.forceColumn ? '1rem' : '0')};
    }
  `};
`;

const Label = styled.label`
  color: ${props => props.theme.dropdownItemColor};
`;

const Radio = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    opacity: 0;
    z-index: -1;

    + ${Label} {
      position: relative;
      padding-left: 28px;
      cursor: ${props => (props.disabled ? `not-allowed` : `pointer`)}
      line-height: 20px;
      display: inline-block;
      color: ${black};
      font-family: ${fontFamilyNarrow};
      font-size: ${fontSizeSmall};
      font-weight: 400;
      user-select: none;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 23px;
        height: 23px;
        border: 2px solid ${brandColor};
        border-radius: 100%;
      }

      &:after {
        content: '';
        width: 11px;
        height: 11px;
        background: ${brandColor};
        position: absolute;
        top: 6px;
        left: 6px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }

  &:checked + ${Label}:after {
    opacity: 1;
  }

  &:not(:checked) {
    + ${Label} {
      color: ${black};

      &:before {
        background-color: ${white};
        border: 2px solid ${props => props.theme.borderColor};
      }
      &:after {
        opacity: 0;
      }
    }
  }
`;

const Legend = styled.legend`
  font-family: ${fontFamilyNarrow};
  font-weight: 700;
`;

const RadioButtonGroup = ({
  legend,
  options,
  onChange,
  forceColumn,
  id,
  selectedValue,
  disabled,
}) => (
  <Fieldset>
    {legend && <Legend>{legend}</Legend>}
    <ButtonGroup forceColumn={forceColumn}>
      {options.map(({ name: label, value }) => (
        <RadioWrapper key={value} forceColumn={forceColumn}>
          <Radio
            type="radio"
            id={value}
            name={id}
            disabled={disabled}
            onChange={onChange}
            checked={
              selectedValue
                ? selectedValue === value
                : value === options[0].value
            }
            value={value}
          />
          <Label htmlFor={value} forceColumn={forceColumn}>
            {label}
          </Label>
        </RadioWrapper>
      ))}
    </ButtonGroup>
  </Fieldset>
);

RadioButtonGroup.propTypes = PropTypes;
RadioButtonGroup.defaultProps = DefaultProps;

export default RadioButtonGroup;
