import React from 'react';
import {
  bool,
  func,
  arrayOf,
  shape,
  number,
  string,
  oneOfType,
} from 'prop-types';

import { withFormik } from 'formik';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import isEmpty from 'lodash.isempty';
import Input from '../Input';
import MapPicker from '../MapPicker';
import Select from '../Select';
import Button from '../Button';
import TextArea from '../TextArea';
import AddressInput from '../AddressInput';
import DownshiftInput from '../DownshiftInput';
import DatePicker from '../DatePicker';
// eslint-disable-next-line import/no-named-as-default
import TextButton from '../TextButton';
import DeviceInput from '../DeviceInput';

import { colors, fonts } from '../../constants/styles';

export const formCustomStatuses = {
  submitted: 'submitted',
};

// const Buttons = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   height: 100%;
//   margin-top: 1.625rem;

//   button:not(:first-of-type) {
//     margin-left: 0.25rem;
//   }

//   button:not(:last-of-type) {
//     margin-right: 0.25rem;
//   }

//   ${mediaBreakpointDownXs`
//     flex-direction: column;

//     button {
//       margin: 0.25rem 0 !important;
//     }
//   `};
// `;

const PropTypes = {
  touched: shape({}),
  errors: shape({}),
  dirty: bool,
  isSubmitting: bool,
  handleSubmit: func,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  buttonText: string.isRequired,
  formFields: arrayOf(shape({})),
  height: oneOfType([number, string]),
  width: oneOfType([number, string]),
  maxWidth: oneOfType([number, string]),
  buttonCyAttribute: string,
  hasWriteAccess: bool,
};

const StyledTextButton = styled(TextButton)`
  min-height: 43px;
`;
const StyledText = styled.div`
  color: ${colors.typeColor};
  font-size: ${fonts.base};
  text-align: center;
  margin-bottom: 1rem;
`;

const DefaultProps = {
  touched: null,
  errors: null,
  dirty: false,
  isSubmitting: false,
  handleSubmit: () => {},
  formFields: [],
  height: '100%',
  width: '100%',
  maxWidth: '100%',
  buttonCyAttribute: null,
  hasWriteAccess: true,
};

const renderElementPropTypes = {
  selectOptions: shape({
    zones: arrayOf(
      shape({
        value: number,
        name: string,
        disabled: bool,
      }),
    ),
  }).isRequired,
  selectOptionsIds: string,
  type: string,
  loading: bool,
};

const renderElementsDefaultProps = {
  selectOptionsIds: null,
  type: null,
  loading: false,
};

const renderElement = ({
  selectOptions,
  selectOptionsIds,
  type,
  loading,
  ...rest
}) => {
  switch (type) {
    case 'select':
      return (
        <Select
          {...rest}
          options={selectOptions[selectOptionsIds]}
          onChange={(e) => {
            rest.setFieldValue(rest.id, e.value);
            // TODO: fix to update locations list if update
            // const fieldIdToUpdate = rest.refetchOnChange;
            // if (fieldIdToUpdate) {
            //   const fieldToUpdate = rest?.initialValues?.formFields?.find(
            //     val => val.id === fieldIdToUpdate,
            //   );
            //   fieldToUpdate.refetch(e);
            //   rest.setFieldValue(fieldIdToUpdate, null);
            //   rest.resetForm({ [fieldIdToUpdate]: '' });
            // }
          }}
        />
      );
    case 'textarea':
      return <TextArea {...rest} />;
    case 'map':
      return <MapPicker {...rest} />;
    case 'date':
      return (
        <DatePicker
          {...rest}
          onChange={(e) => {
            rest.setFieldValue(rest.id, e && e.toDate());
          }}
        />
      );
    case 'link':
      return (
        <StyledTextButton
          key={`link-${rest.id}`}
          role="button"
          onClick={rest.onClickProp}
        >
          {rest.label}
        </StyledTextButton>
      );
    case 'address':
      return <AddressInput {...rest} />;
    case 'device':
      return <DeviceInput {...rest} />;
    case 'downshiftInput':
      return <DownshiftInput selectOptionsIds={selectOptionsIds} {...rest} />;
    case 'plainText':
      return <StyledText key={`plainText-${rest.id}`}>{rest.label}</StyledText>;
    default:
      return <Input type={type} {...rest} />;
  }
};

renderElement.propTypes = renderElementPropTypes;
renderElement.defaultProps = renderElementsDefaultProps;

const StyledBox = styled(Box)`
  min-height: 86px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  height: ${(props) => props.heightProp || 'auto'};
  width: 600px;
  overflow: ${(props) => props.overflowProp || 'initial'};
  max-width: ${(props) => props.maxWidthProp || '100%'};
`;

const createButton = (ownProps) => (fields) => {
  const { id, onClickProp, onClickValue, label, ...rest } = fields;
  const onClick = ownProps[onClickProp];
  const deleteId = onClickValue;

  return (
    <Button
      key={id}
      onClick={(e) => {
        e.preventDefault();
        onClick(deleteId);
      }}
      {...rest}
    >
      {label}
    </Button>
  );
};

const getBoxPadding = ({ boxWidth, i, padding }) => {
  if (padding) {
    return padding;
  }

  if (boxWidth === 1) {
    return { pl: 0, pr: 0 };
  }

  if (boxWidth === 1 / 3) {
    if (i % 2 === 0) {
      return { pl: 0, pr: 1 };
    }
  }

  if (i % 2 === 0) {
    return { pl: 0, pr: 1 };
  }

  return { pl: 1, pr: 0 };
};

const getButtonText = ({
  buttonText,
  submittingButtonText,
  submittedButtonText,
  isSubmitting,
  status,
}) => {
  if (status === formCustomStatuses.submitted) {
    return submittedButtonText || buttonText;
  }

  if (isSubmitting) {
    return submittingButtonText || buttonText;
  }

  return buttonText;
};

const showOnlyFilter =
  (userRole) =>
  ({ showOnly = [] }) =>
    showOnly.length === 0 || showOnly.includes(userRole);

const Form = ({
  height,
  width,
  maxWidth,
  touched,
  errors,
  dirty,
  isSubmitting,
  handleSubmit,
  handleBlur,
  handleChange,
  buttonCyAttribute,
  formFields,
  hasWriteAccess,
  // eslint-disable-next-line react/prop-types
  userRole,
  ...ownProps
}) => {
  const heightWithUnits = typeof height === 'string' ? height : `${height}px`;
  const widthWithUnits = typeof width === 'string' ? width : `${width}px`;
  const maxWidthWithUnits =
    typeof maxWidth === 'string' ? maxWidth : `${maxWidth}px`;
  const overflow = typeof height === 'string' ? 'visible' : 'scroll';

  const { formFieldsWithoutButtons, buttons, links } = formFields.reduce(
    (acc, field) => {
      if (field.id === 'deleteButton' || field.id === 'secondaryButton') {
        acc.buttons.push(field);
      } else if (field.type === 'link' || field.type === 'plainText') {
        acc.links.push(field);
      } else {
        acc.formFieldsWithoutButtons.push(field);
      }

      return acc;
    },
    { formFieldsWithoutButtons: [], links: [], buttons: [] },
  );

  return (
    <StyledForm
      heightProp={heightWithUnits}
      widthProp={widthWithUnits}
      maxWidthProp={maxWidthWithUnits}
      overflowProp={overflow}
      onSubmit={handleSubmit}
      noValidate
    >
      <Flex flexWrap="wrap" alignItems="stretch">
        {formFieldsWithoutButtons
          .filter(showOnlyFilter(userRole))
          .map(({ id, width: boxWidth, padding, ...rest }, i) => (
            <StyledBox
              key={id}
              width={boxWidth}
              pb={3}
              {...getBoxPadding({
                boxWidth,
                i,
                padding,
              })}
            >
              {renderElement({
                error: touched[id] && errors[id],
                width,
                ...rest,
                ...ownProps,
                name: id,
                id,
                onBlur: handleBlur,
                onChange: handleChange,
              })}
            </StyledBox>
          ))}
      </Flex>
      <Flex
        key="links-key"
        width={1}
        px={0}
        pt={3}
        pb={2}
        style={{ flexWrap: 'wrap' }}
      >
        {links
          .filter(showOnlyFilter(userRole))
          .map(({ id, width: boxWidth, padding, ...rest }) =>
            renderElement({
              width,
              ...rest,
              ...ownProps,
              name: id,
              id,
            }),
          )}
      </Flex>
      <Flex
        key="buttons-key"
        width={1}
        px={0}
        pt={3}
        pb={2}
        style={{ justifyContent: 'space-between', alignItems: 'stretch' }}
      >
        <>
          {buttons.filter(showOnlyFilter(userRole)).map(createButton(ownProps))}

          <Button
            type="submit"
            disabled={
              !dirty || !isEmpty(errors) || isSubmitting || !hasWriteAccess
            }
            data-cy={buttonCyAttribute || 'submit-button'}
            width="0.33"
          >
            {getButtonText({ ...ownProps, isSubmitting })}
          </Button>
        </>
      </Flex>
    </StyledForm>
  );
};

Form.propTypes = PropTypes;
Form.defaultProps = DefaultProps;

export default (validationSchema) =>
  withFormik({
    mapPropsToValues: (props) => ({ ...props }),
    enableReinitialize: true,
    validationSchema,
    handleSubmit: ({ handleSubmit, ...rest }, { setSubmitting, setStatus }) => {
      handleSubmit(rest, setSubmitting, setStatus);
    },
  })(Form);
