import React from 'react';

const Profile = props => (
  <svg
    width="21px"
    height="27px"
    viewBox="0 0 21 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs />
    <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="NAV-1" transform="translate(-30.000000, -280.000000)">
        <g id="NAV">
          <path
            d="M30.57126,307 C30.5242081,306.508205 30.5,306.007636 30.5,305.5 C30.5,300.837266 32.5423824,296.770812 35.5698881,294.622211 C37.007132,295.496476 38.6947638,296 40.5,296 C42.3052362,296 43.992868,295.496476 45.4301119,294.622211 C48.4576176,296.770812 50.5,300.837266 50.5,305.5 C50.5,306.007636 50.4757919,306.508205 50.42874,307 L30.57126,307 Z M40.5,293 C36.9101491,293 34,290.089851 34,286.5 C34,282.910149 36.9101491,280 40.5,280 C44.0898509,280 47,282.910149 47,286.5 C47,290.089851 44.0898509,293 40.5,293 Z"
            id="Profile"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Profile;
