import styled from 'styled-components';
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints';

const TextColumn = styled.div`
  width: 50%;

  ${mediaBreakpointDownMd`
    width: 100%;
  `};
`;

TextColumn.displayName = 'TextColumn';

export default TextColumn;
