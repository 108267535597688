/**
 * Export only propperties without auth0 reference for SSR
 */
export const ID_TOKEN = 'id_token';
export const getIdToken = () => window.localStorage.getItem(ID_TOKEN);
export { default as withAuth } from './withAuth';
export {
  adminAccessValidator,
  superAdminAccessValidator,
  writeAccessValidator,
} from './validators';
export { default as PropTypes } from './authPropTypes';
