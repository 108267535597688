import React from 'react';

const BackIcon = props => (
  <svg width="35" height="26" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#8D6EC8" strokeWidth="2" fill="none" fillRule="evenodd">
      <path d="M14 25L2 12.776 14 1" />
      <path d="M4 13h30" strokeLinecap="square" />
    </g>
  </svg>
);

export default BackIcon;
