import React from 'react';

const Logo = () => (
  <svg
    width="44px"
    height="32px"
    viewBox="0 0 44 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="NAV-extended" transform="translate(-18.000000, -745.000000)">
        <g id="Helen-logo" transform="translate(18.000000, 745.000000)">
          <polygon
            id="Fill-15-Copy-15"
            fill="#FFC800"
            points="13 -3.55271368e-15 0 16 13 16"
          />
          <polygon
            id="Fill-15-Copy-15"
            fill="#FFC800"
            points="25 -3.55271368e-15 12 16 25 16"
          />
          <polygon
            id="Fill-15-Copy-15"
            fill="#FFC800"
            points="37 -3.55271368e-15 24 16 37 16"
          />
          <polygon
            id="Fill-15-Copy-15"
            fill="white"
            transform="translate(37.500000, 24.000000) scale(-1, -1) translate(-37.500000, -24.000000) "
            points="44 16 31 32 44 32"
          />
          <polygon
            id="Fill-15-Copy-15"
            fill="white"
            transform="translate(25.500000, 24.000000) scale(-1, -1) translate(-25.500000, -24.000000) "
            points="32 16 19 32 32 32"
          />
          <polygon
            id="Fill-15-Copy-15"
            fill="white"
            transform="translate(13.500000, 24.000000) scale(-1, -1) translate(-13.500000, -24.000000) "
            points="20 16 7 32 20 32"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
