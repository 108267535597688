import PiwikPro from '@piwikpro/react-piwik-pro';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './lib/classlist-shim';

import Modal from 'react-modal';
import { createElement } from 'react';
import { render, hydrate } from 'react-dom';
import 'react-virtualized/styles.css';
import './index.css';
import './toastr.css';
import withStore from './store';

PiwikPro.initialize(
  process.env.REACT_APP_PIWIK_PRO_ID,
  'https://webanalytics.helen.fi',
);

Modal.setAppElement('#root');

if (process.env.NODE_ENV === 'production') {
  hydrate(createElement(withStore), document.getElementById('root'));
} else {
  render(createElement(withStore), document.getElementById('root'));
}
