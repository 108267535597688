import React, { Fragment } from 'react';
import { string, arrayOf, shape, bool, instanceOf, func } from 'prop-types';
import { ApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {
  mediaBreakpointUpLg,
  mediaBreakpointDownSm,
  mediaBreakpointUpMd,
  mediaBreakpointUpSm,
} from 'styled-bootstrap-responsive-breakpoints';
import Scrollbar from 'react-scrollbars-custom';
import { PropTypes as AuthPropTypes } from '../../lib/Auth/shallow';
import { Logout, Logo } from './Icons';

import {
  fontFamilySansSerif,
  fontSizeMini,
  fontSizeSmall,
  NavBackground,
  NavIconColor,
  NavHoverIconColor,
  NavActiveIconColor,
  navigationListHightlight,
  white,
} from '../../constants/styles-old';

const PropTypes = {
  selectedItem: string.isRequired,
  navItems: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      icon: func.isRequired,
    }).isRequired,
  ).isRequired,
  auth: AuthPropTypes.isRequired,
  isAuthenticated: bool.isRequired,
  client: instanceOf(ApolloClient).isRequired,
  open: bool.isRequired,
  Profile: shape({}),
  toggleNavigation: func.isRequired,
};

const DefaultProps = {
  Profile: null,
};

const Nav = styled.nav`
  user-select: none;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: column;
  background: ${NavBackground};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: ${(props) => (props.open ? '270px' : '80px')};
  min-width: 400px;
  max-width: 80%;
  z-index: 9999;
  transform: translateX(${(props) => (props.open ? '0' : '-100%')});
  justify-content: space-between;
  -webkit-overflow-scrolling: touch;

  ${mediaBreakpointDownSm`
    padding-top: 4rem;
  `};

  ${mediaBreakpointUpMd`
    position: fixed;
    top: 0;
    left: 0;
    min-width: 5rem;
    max-width: 5rem;
    top: auto;
    right: auto;
    transform: translateX(0);
  `};
`;

const HideNavArea = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 9997;

  ${mediaBreakpointUpLg`
    display: none;
  `};
`;

const IconLabel = styled.div`
  display: none;

  ${mediaBreakpointUpMd`
    display: block;
    font-weight: 500;
    font-size: ${fontSizeMini};
    line-height: 1rem;
    margin-top: 0.5rem;
    white-space: nowrap;
  `};
`;

const Items = styled.ul`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-wrap: nowrap;
  width: 400px;
  list-style: none;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
`;

export const Item = styled.li`
  background: ${({ isSelected }) =>
    isSelected ? navigationListHightlight : 'transparent'};
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  min-height: 4rem;

  a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 0px;

    ${mediaBreakpointUpSm`
      padding: 10px;
    `};
  }

  a span {
    display: flex;
    font-size: 0.875rem;
  }

  ${mediaBreakpointUpMd`
      &:nth-last-of-type(2),
      &:last-of-type {
        min-height: 4rem;
      }
      
      min-height: 5.625rem;
  `};

  ${mediaBreakpointUpLg`
    a {
      justify-content: left;
    }
  `};

  svg {
    display: flex;
    margin: auto;
    fill: ${({ isSelected }) =>
      isSelected ? NavActiveIconColor : NavIconColor};
  }

  &:hover,
  &:focus {
    svg {
      fill: ${({ isSelected }) =>
        isSelected ? NavActiveIconColor : NavHoverIconColor};
    }

    ${IconLabel} {
      color: ${white};
    }
  }

  span {
    color: ${({ isSelected }) =>
      isSelected ? NavActiveIconColor : NavHoverIconColor};
  }

  &:hover span {
    text-decoration: none;
    color: ${({ isSelected }) =>
      isSelected ? NavActiveIconColor : NavHoverIconColor};
  }
`;

const Spacer = styled.li`
  display: flex;
  flex-grow: 1;
`;

const StyledLink = styled(({ navigationLink, ...rest }) => (
  <NavLink {...rest} />
))`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: ${NavIconColor};
  text-decoration: none;
  ${(props) => props.navigationLink && 'flex: 1; justify-content: left;'};
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const LogoItem = styled(Item)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Name = styled.span`
  font-size: ${fontSizeSmall};
  font-family: ${fontFamilySansSerif};
  text-align: left;
  margin-left: 0.625rem;
`;

const ItemsWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: space-between;

  & .track {
    background-color: rgba(255, 255, 255, 0.1) !important;

    & .thumbY {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
  }

  & .content {
    display: flex;
  }
`;

const IconWrapper = styled.div`
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  height: auto;

  svg#logout {
    fill: ${NavIconColor};
  }

  &:hover,
  &:focus {
    svg#logout {
      fill: ${NavHoverIconColor};
    }
  }

  ${mediaBreakpointUpSm`
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  `};
`;

const LogoIconWrapper = styled.div`
  margin-left: 0.45rem;
  width: 44px;
  height: 32px;
`;

const onLogOut = (auth, client) => (event) => {
  event.preventDefault();
  client.resetStore();
  auth.logout();
};

const VerticalNavbar = ({
  selectedItem,
  navItems,
  isAuthenticated,
  auth,
  client,
  open,
  Profile,
  toggleNavigation,
}) => {
  const filteredNavItems = navItems.filter((item) => {
    if (typeof item.enabled === 'function') {
      return item.enabled(
        Profile && Profile.features ? Profile : { features: {} },
      );
    }

    return item.enabled;
  });

  return (
    <Fragment>
      <Nav amountOfItems={filteredNavItems.length} open={open}>
        <ItemsWrapper>
          <Scrollbar
            style={{ width: '100%', height: '100%', color: 'red', flex: 1 }}
            noScrollX
          >
            <Items>
              {filteredNavItems.map(({ icon: IconComponent, name, id }) => (
                <Item
                  key={id}
                  isSelected={
                    selectedItem === id || id.split('/').includes(selectedItem)
                  }
                >
                  <StyledLink
                    to={`/${id}`}
                    navigationLink
                    onClick={toggleNavigation}
                  >
                    <IconWrapper>
                      <IconComponent />
                      <IconLabel>{name}</IconLabel>
                    </IconWrapper>
                    {name && <Name>{name}</Name>}
                  </StyledLink>
                </Item>
              ))}
              <Spacer />
              {isAuthenticated && (
                <Item className="logout-item">
                  <StyledLink to="/logout" onClick={onLogOut(auth, client)}>
                    <IconWrapper>
                      <Logout />
                      <IconLabel>Ulos</IconLabel>
                    </IconWrapper>
                    <Name>Kirjaudu ulos</Name>
                  </StyledLink>
                </Item>
              )}
              <LogoItem>
                <StyledLink to="/">
                  <LogoIconWrapper>
                    <Logo />
                  </LogoIconWrapper>
                </StyledLink>
              </LogoItem>
            </Items>
          </Scrollbar>
        </ItemsWrapper>
      </Nav>
      {open && <HideNavArea onClick={toggleNavigation} />}
    </Fragment>
  );
};

VerticalNavbar.propTypes = PropTypes;
VerticalNavbar.defaultProps = DefaultProps;

export default VerticalNavbar;
