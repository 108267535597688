export const TOGGLE_NAVIGATION = '@@navigation/TOGGLE_NAVIGATION';
export const SET_NAVIGATION_FILTER_VALUE =
  '@@navigation/SET_NAVIGATION_FILTER_VALUE';

export const toggleNavigation = () => ({
  type: TOGGLE_NAVIGATION,
});

export const setNavigationFilterValue = filter => ({
  type: SET_NAVIGATION_FILTER_VALUE,
  filter,
});
