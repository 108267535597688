import React from 'react';

const AddIcon = props => (
  <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      transform="rotate(-45 11.5 11.5)"
      stroke="#9076BE"
      fill="none"
      fillRule="evenodd"
    >
      <circle cx="11.5" cy="11.5" r="11" />
      <g strokeLinecap="square" strokeWidth="2">
        <path d="M8.438 8.438l6.187 6.187M14.625 8.438l-6.188 6.187" />
      </g>
    </g>
  </svg>
);

export default AddIcon;
