import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';
import Address from './Address/index.js';
import Search from './Search/index.js';
import Profile from './Profile/index.js';
import Navigation from './Navigation/index.js';

export default () =>
  combineReducers({
    Address,
    Search,
    Profile,
    Navigation,
    toastr,
  });
