import React from 'react';

const ChevronUpIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" {...props}>
    <g fill="#393939" fillRule="evenodd">
      <path d="M1.915 8.843L.5 7.43 7.57.358l1.415 1.414z" />
      <path d="M14.642 7.429l-1.414 1.414-7.071-7.07L7.57.357z" />
    </g>
  </svg>
);

export default ChevronUpIcon;
