import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';

import { rowRenderer } from '../RowAndCellRenderer';

import { colors } from '../../../constants/styles';

// prevent cyclic dependecy
const ViewTable = React.lazy(() => import('../ViewTable'));

const PropTypes = {
  push: func.isRequired,
};

const IsActiveCell = styled.div`
  align-items: center;
  align-self: center;
  border-radius: 3px;
  color: ${colors.typeColor};
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: left;
  margin: 0 auto;
`;

const ObservationStationsTable = ({ push, ...props }) => (
  <ViewTable
    widthColumnCount={16}
    headerPaddingTop="1rem"
    rowRenderer={row =>
      rowRenderer({
        ...row,
        cursor: 'pointer',
      })
    }
    {...props}
    render={({ size, cellRenderer, Column }) => [
      <Column
        label="FMIid"
        dataKey="fmisid"
        key="fmisid"
        width={size(4)}
        cellRenderer={cellRenderer}
      />,
      <Column
        label="Havaintoasema"
        dataKey="name"
        key="name"
        width={size(4)}
        cellRenderer={cellRenderer}
      />,

      <Column
        label="Aktiivinen"
        dataKey="active"
        key="name"
        width={size(4)}
        cellRenderer={({ rowData: { active } }) => (
          <IsActiveCell>{active ? '✓' : ''}</IsActiveCell>
        )}
      />,
    ]}
  />
);

ObservationStationsTable.propTypes = PropTypes;

export default ObservationStationsTable;
