import React from 'react';

const SuccessIcon = () => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm-4.917-9.762l3.688 3.45 6.146-5.75-1.739-1.625-4.407 4.122-1.95-1.823-1.738 1.626z"
      fill="#FFF"
    />
  </svg>
);

export default SuccessIcon;
