import { func, number, shape, string, arrayOf } from 'prop-types';
import React, { useState, useReducer, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { keyframes, ThemeProvider } from 'styled-components';
import {
  mediaBreakpointDownXs,
  mediaBreakpointUpSm,
  mediaBreakpointUpMd,
} from 'styled-bootstrap-responsive-breakpoints';

import FormSection from './FormSection';
import CalculatorSection from './CalculatorSection';
import TopSection from './TopSection';
import ThanksSection from './ThanksSection';
import Teaser from './Teaser';
import { Ingress } from '../HelenHeading';
import { fonts, colors } from '../../constants/styles';

import {
  calculateSavings,
  calculateMeterCount,
  meterOptions,
  roundUp,
} from './calculations';

import { calculateMonthlyPrice, findPriceGroup } from '../../lib/math-helpers';

import { KIINTEISTOVAHTI_SERVICE_TYPES } from '../../constants/kiinteistovahti-service-types';

const slideIn = fromX => keyframes`
  from {
    transform: translateX(${fromX});
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = toX => keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(${toX});
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  max-width: 512px;
  font-family: ${fonts.families.helenFontFamilySansSerif};

  ${mediaBreakpointUpSm`
    max-width: 938px;
  `};
`;

const Contact = styled(Ingress)`
  align-self: center;
  color: ${colors.white};
  font-size: ${fonts.helenSizes.base};
  line-height: 1.5rem;
  justify-content: center;
  display: flex;
  flex-direction: column;

  a {
    color: ${colors.white};
    cursor: pointer;
    font-weight: bold;
    align-self: center;
    &:hover {
      text-decoration: underline;
    }
    &: hover;
  }
`;

const Headline = styled.h2`
  font-family: ${fonts.families.helenFontFamilySansSerif};
  font-size: ${fonts.helenSizes.headline};
  text-align: center;
  font-weight: bold;

  width: 100%;
  color: ${({ nobackground }) => (nobackground ? 'black' : 'white')};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => (props.noMarginBottom ? '0' : '2rem')};
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;

  ${mediaBreakpointUpMd`
    padding-left: 0;
    padding-right: 0;
  `};
`;

const Description = styled.p`
  font-size: ${fonts.helenSizes.small};
  text-align: center;
  font-weight: 500;
  line-height: 150%;
  max-width: 42rem;
  width: 100%;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  ${mediaBreakpointUpMd`
    padding-left: 0;
    padding-right: 0;
  `};
`;

const Box = styled.div`
  padding: 2rem 2.25rem;
  background: ${colors.white};

  ${mediaBreakpointDownXs`
    padding: 1rem 1.125rem;
  `};
`;

const FormBox = styled(Box)`
  background: ${colors.helenFormBackground};
`;
const Flex = styled.div`
  display: flex;
  background: ${colors.helenBackground};
  justify-content: center;
  padding: 2rem 0 2rem;
`;

const TeaserBox = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${mediaBreakpointUpMd`
    align-items: stretch;
    padding: 2rem 0;
    flex-direction: row;
  `};
`;

const animDuration = 500;

const Page = styled.div`
  background: ${colors.helenBackground};
  padding-top: 74px;
  padding-bottom: 74px;
  height: auto;
  left: 0;
  position: absolute;

  width: 100%;
  top: 0;

  &.page-left-enter {
    animation: ${slideIn('-100%')} ${animDuration}ms forwards,
      ${fadeIn} ${animDuration}ms ease-in;
  }
  &.page-left-exit {
    animation: ${slideOut('-100%')} ${animDuration}ms forwards,
      ${fadeOut} ${animDuration}ms ease-out;
  }
  &.page-right-enter {
    animation: ${slideIn('100%')} ${animDuration}ms forwards,
      ${fadeIn} ${animDuration}ms ease-in;
  }
  &.page-right-exit {
    animation: ${slideOut('100%')} ${animDuration}ms forwards,
      ${fadeOut} ${animDuration}ms ease-out;
  }
`;
const FormContent = styled(Content)`
  background: ${colors.helenFormBackground};
`;
const Page1 = ({
  calculatorPageTitle,
  calculatorPageSubtitle,
  step,
  selectedItem,
  selectedAutoItem,
  dispatch,
  monthlyPrice,
  teaserSectionTitle,
  baseFee,
  meterBaseFee,
  ...props
}) => {
  const scrollRef = useRef();
  return (
    <Page>
      <>
        <Content>
          <Headline nobackground> {calculatorPageTitle}</Headline>
          <Description>{calculatorPageSubtitle}</Description>
          <CalculatorSection {...props} scrollRef={scrollRef} />
        </Content>
        <Headline ref={scrollRef}>{teaserSectionTitle}</Headline>
        <Content>
          <TeaserBox>
            <Teaser
              {...props}
              dispatch={dispatch}
              selectedItem={selectedItem}
              monthlyPrice={monthlyPrice}
              baseFee={baseFee}
              meterBaseFee={meterBaseFee}
            />
          </TeaserBox>
        </Content>
      </>
    </Page>
  );
};

const Page2 = ({ formPageTitle, formPageSubtitle, step, ...props }) => (
  <Page>
    <FormContent>
      <TopSection title={formPageTitle} subtitle={formPageSubtitle} />
      <FormBox>
        <FormSection {...props} />
      </FormBox>
      <Flex>
        <Contact dangerouslySetInnerHTML={{ __html: props.contactText }} />
      </Flex>
    </FormContent>
  </Page>
);

const Page3 = ({ step, ...props }) => (
  <Page>
    <Content>
      <Box>
        <ThanksSection {...props} />
      </Box>
    </Content>
  </Page>
);

const getPageClassName = (currentPage, nextPage, prevPage) => {
  if (prevPage < currentPage || nextPage < currentPage) {
    return 'page-right';
  }

  if (prevPage > currentPage || nextPage > currentPage) {
    return 'page-left';
  }

  return 'page-left';
};

const initialState = ({
  savingsPercentage,
  defaultApartmentCount,
  apartmentCount,
  buildingInfo,
  constructionDecade,
  heatingPercentage,
  heatingUnitPrice,
  priceGroups,
  meterBaseFee,
}) => {
  const meterSelect = meterOptions(defaultApartmentCount);
  const meterCount = roundUp(defaultApartmentCount, meterSelect[0].value);

  const { monthlyFee: monthlyBaseFee, baseFee } = findPriceGroup(
    priceGroups,
    defaultApartmentCount,
  );

  return {
    apartmentCount: defaultApartmentCount,
    options: meterOptions(defaultApartmentCount),
    constructionDecade: { name: 'Ei tiedossa', value: 'unknown' },
    annualSavings: calculateSavings({
      apartmentCount,
      buildingInfo,
      constructionDecade,
      heatingPercentage,
      heatingUnitPrice,
      savingsPercentage,
    }),
    monthlyPrice: calculateMonthlyPrice({
      monthlyBaseFee,
      monthlyMeterPrice: 0,
    })({ meterCount }),
    selectedItem: meterSelect[0],
    selectedAutoItem: meterSelect[0],
    meterCount,
    monthlyBaseFee,
    baseFee,
    meterBaseFee,
  };
};

const reducer = defaultValues => (state, action) => {
  switch (action.type) {
    case 'sliderChange': {
      const { monthlyFee: monthlyBaseFee, baseFee } = findPriceGroup(
        defaultValues.priceGroups,
        action.apartmentCount,
      );

      const options = meterOptions(action.apartmentCount);
      const selectedItem = options.find(
        i => i.value === state.selectedItem.value,
      );
      const meterCount = roundUp(action.apartmentCount, selectedItem.value);

      return {
        ...state,
        apartmentCount: action.apartmentCount,
        options,
        annualSavings: calculateSavings({
          ...defaultValues,
          apartmentCount: action.apartmentCount,
          constructionDecade: state.constructionDecade.value,
        }),
        monthlyPrice: calculateMonthlyPrice({
          monthlyBaseFee,
          monthlyMeterPrice: 0,
        })({ meterCount }),
        monthlyBaseFee,
        selectedItem,
        meterCount,
        baseFee,
      };
    }

    case 'selectConstructionDecade':
      return {
        ...state,
        annualSavings: calculateSavings({
          ...defaultValues,
          apartmentCount: state.apartmentCount,
          constructionDecade: action.constructionDecade.value,
        }),
        constructionDecade: action.constructionDecade,
      };
    default:
      throw new Error(
        `Reducer was called with non-existing type ${action.type} `,
      );
  }
};

const QuotationForm = props => {
  const { buildingInfo, handleSubmit, monthlyMeterPrice } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const [{ nextPage, prevPage, serviceType }, setPageHelpers] = useState({
    nextPage: 1,
    prevPage: 1,
    serviceType: KIINTEISTOVAHTI_SERVICE_TYPES.FIXED,
  });

  const [state, dispatch] = useReducer(reducer(props), initialState(props));
  const [formValues, setFormValues] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  return (
    <TransitionGroup>
      <CSSTransition
        key={currentPage}
        timeout={animDuration}
        classNames={getPageClassName(currentPage, nextPage, prevPage)}
      >
        <ThemeProvider theme={{}}>
          {currentPage === 1 && (
            <Page1
              annualSavings={state.annualSavings}
              calculateMeterCount={calculateMeterCount}
              constructionDecades={Object.keys(buildingInfo)}
              onSubmit={value => {
                setPageHelpers({
                  nextPage: 2,
                  prevPage: 1,
                  serviceType: value,
                });
                setCurrentPage(2);

                try {
                  let domain;
                  if (window.location.origin.includes('localhost')) {
                    domain = window.location.origin;
                  } else {
                    domain = !window.location.origin.includes('dev.enneapi.io')
                      ? 'https://www.helen.fi'
                      : 'https://prep.helen.fi';
                  }
                  window.parent.postMessage({ name: 'pagechange' }, domain);
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.error(err);
                }
              }}
              setApartmentCount={value =>
                dispatch({ type: 'sliderChange', apartmentCount: value })
              }
              dispatch={dispatch}
              setConstructionDecade={value =>
                dispatch({
                  type: 'selectConstructionDecade',
                  constructionDecade: value,
                })
              }
              {...props}
              {...state}
            />
          )}
          {currentPage === 2 && (
            <Page2
              goBack={values => {
                setFormValues(values);
                setSubmitError(null);
                setPageHelpers({
                  nextPage: 1,
                  prevPage: 2,
                  serviceType,
                });
                setCurrentPage(1);
              }}
              initialValues={formValues}
              onSubmit={async (values, formikBag) => {
                try {
                  await handleSubmit({
                    monthlyMeterPrice,
                    values,
                    ...state,
                    serviceType,
                    meterCount: state.meterCount,
                    monthlyBaseFee: state.monthlyBaseFee,
                  });
                  formikBag.setStatus('submitted');
                  setSubmitError(null);
                  setPageHelpers({
                    nextPage: 3,
                    prevPage: 2,
                    serviceType,
                  });
                  setCurrentPage(3);
                } catch (err) {
                  formikBag.setSubmitting(false);
                  setSubmitError(err.message);
                }
              }}
              submitError={submitError}
              {...props}
            />
          )}
          {currentPage === 3 && (
            <Page3
              goBack={() => {
                setFormValues(null);
                setPageHelpers({
                  nextPage: 1,
                  prevPage: 3,
                  serviceType: KIINTEISTOVAHTI_SERVICE_TYPES.FIXED,
                });
                setCurrentPage(1);
              }}
              {...props}
            />
          )}
        </ThemeProvider>
      </CSSTransition>
    </TransitionGroup>
  );
};

QuotationForm.propTypes = {
  allMetersCheckboxLabel: string.isRequired,
  allMetersText: string.isRequired,
  buildingInfo: shape({}).isRequired,
  buttonText: string.isRequired,
  defaultApartmentCount: number.isRequired,
  handleSubmit: func.isRequired,
  heatingPercentage: number.isRequired,
  heatingUnitPrice: number.isRequired,
  minApartmentCount: number.isRequired,
  monthlyMeterPrice: number.isRequired,
  priceTextAnnual: string.isRequired,
  priceTextMonthly: string.isRequired,
  savingsPercentage: number.isRequired,
  sliderTooltipApartments: string.isRequired,
  sliderTooltipMeters: string.isRequired,
  calculatorPageTitle: string.isRequired,
  priceGroups: arrayOf(
    shape({
      maxApartmentCount: number,
      baseFee: number,
      monthlyFee: number,
    }),
  ).isRequired,
};

Page1.propTypes = {
  calculatorPageTitle: string.isRequired,
  calculatorPageSubtitle: string.isRequired,
  step: string.isRequired,
  selectedItem: shape({}).isRequired,
  selectedAutoItem: shape({}).isRequired,
  dispatch: func.isRequired,
  monthlyPrice: number.isRequired,
  baseFee: number.isRequired,
  meterBaseFee: number.isRequired,
  teaserSectionTitle: string.isRequired,
  priceGroups: arrayOf(
    shape({
      maxApartmentCount: number,
      baseFee: number,
      monthlyFee: number,
    }),
  ).isRequired,
};

Page2.propTypes = {
  formPageTitle: string.isRequired,
  formPageSubtitle: string.isRequired,
  step: string.isRequired,
  contactText: string.isRequired,
};

Page3.propTypes = {
  step: string.isRequired,
};

export default QuotationForm;
