/* eslint-disable react/no-array-index-key */

import { Formik } from 'formik';
import isEmpty from 'lodash.isempty';
import { arrayOf, func, shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints';
import Yup from 'yup';

import BackIcon from './BackIcon';

import Button from '../../HelenButton';
import Checkbox from '../../HelenCheckbox';
import { Heading1 } from '../../HelenHeading';

import { housingCompanyFields, ordererFields } from '../FormSection';

import {
  helenAltBrandColor,
  helenAltBrandColorHover,
  helenBrandDangerColor,
  helenFontFamilySansSerif,
  helenGrayLight,
  helenTypeColorLight,
} from '../../../constants/styles-old';

import { colors } from '../../../constants/styles';
import { roundToDecimalsLocale } from '../../../lib/math-helpers';

const Container = styled.div`
  background-color: ${colors.helenBackground};
  font-family: ${helenFontFamilySansSerif};
  padding-top: 10rem;
  padding-bottom: 5rem;
`;

const Content = styled.div`
  background-color: white;
  margin: 0 auto;
  max-width: 958px;
  padding: 5rem;

  ${mediaBreakpointDownMd`
    padding: 1.5rem 1rem;
  `};
`;

const BackIconContainer = styled.div`
  align-self: center;
  cursor: pointer;
  position: relative;
  right: 8rem;
  top: -4rem;

  ${mediaBreakpointDownMd`
    right: 0;
  `};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3.5rem;
`;

const TitleBallot = styled.div`
  align-self: center;
  background-color: #ffc807;
  border-radius: 50%;
  color: white;
  flex-shrink: 0;
  font-size: 1.25rem;
  font-weight: 700;
  height: 2rem;
  margin-left: -27px;
  padding-left: 10px;
  padding-top: 1px;
  width: 2rem;
`;

const TitleText = styled(Heading1)`
  align-self: center;
  color: ${helenTypeColorLight};
  font-size: 1.875rem;
  font-weight: 700;
  margin: 0.15rem 0rem 0rem 1.25rem;
`;

const SubSection = styled.div``;

const SubSectionTitle = styled.div`
  color: ${helenTypeColorLight};
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: ${props => props.maxHeight}px;

  ${mediaBreakpointDownMd`
    height: auto;
  `};
`;

const Field = styled.div`
  margin-bottom: 1.125rem;
  width: 400px;

  ${mediaBreakpointDownMd`
    max-width: 400px;
    width: auto;
  `};
`;

const FieldLabel = styled.div`
  color: ${helenTypeColorLight};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
`;

const FieldValue = styled.div`
  color: ${helenTypeColorLight};
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;

  ul {
    margin-block-start: 5px;
    margin-bottom: 0;
    padding-inline-start: 1.125rem;
  }

  li {
    margin-block-end: 0.1875rem;
    margin-block-start: 0.1875rem;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${helenGrayLight};
  margin-top: 1rem;
  padding-top: 1rem;
`;

const Subtext = styled.div`
  color: ${helenTypeColorLight};
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
`;

const ConfirmButton = styled(Button)`
  margin-top: 2rem;
  min-width: 180px;
  background-color: ${colors.helenBackground};
  border-radius: 25px;
  align-self: center;
  &:hover,
  &:active {
    background-color: ${props =>
      !props.outline ? colors.helenPrimaryHover : colors.helenSecondaryHover};
    border-color: ${props =>
      props.outline ? colors.helenBorder : colors.helenBackground};
    color: ${colors.helenBackground};
    text-decoration: none;
  }
`;

const Form = styled.form``;

const AcceptTermsCheckbox = styled(Checkbox)`
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;

  span {
    color: ${helenTypeColorLight} !important;
    font-size: 0.875rem !important;
    font-weight: 300 !important;
    letter-spacing: 0px !important;
    line-height: normal !important;
    margin-left: 0px !important;
    padding-top: 1px !important;

    a {
      color: ${helenAltBrandColor};
    }

    a:hover {
      color: ${helenAltBrandColorHover};
    }
  }
`;

const TextAlert = styled.div`
  color: ${helenBrandDangerColor};
`;

const transformValue = (id, value) => {
  if (id === 'apartmentCount') {
    return `${value} asuntoa`;
  }

  if (id === 'meterCount') {
    return `${value} mittaria`;
  }

  if (id === 'isHelenCustomer') {
    return value ? 'Kyllä' : 'Ei';
  }

  return value;
};

const SummarySection = ({
  data,
  formFields,
  goBack,
  onSubmit,
  roleOptions,
  submitError,
  summaryCheckboxError,
  summaryCheckboxLabel,
  summaryFields,
  summarySections,
  summarySubmitButtonText,
  summarySubtext,
  summaryTitle,
}) => {
  const {
    heatingGroupId,
    isHelenCustomer,
    meterCount,
    baseFee,
    meterBaseFee,
    monthlyBaseFee,
  } = data;
  const users = [
    { email: data.ordererEmail, role: data.ordererRole },
    ...data.otherUsers,
  ];

  const annualPrice = monthlyBaseFee * 12;

  const subSections = [
    {
      fields: housingCompanyFields(formFields),
      id: 'housingCompany',
      maxHeight: 250,
      title: summarySections.housingCompany.title,
    },
    {
      fields: [
        {
          id: 'ordererFullName',
          label: summaryFields.ordererFullName.label,
          value: `${data.ordererFirstName} ${data.ordererLastName}`,
        },
        ...ordererFields(formFields, roleOptions)
          .filter(
            f => f.id !== 'ordererFirstName' && f.id !== 'ordererLastName',
          )
          .map(f => {
            if (f.id === 'ordererRole') {
              const role = roleOptions.find(o => o.value === data.ordererRole);
              return {
                id: 'ordererRoleName',
                label: f.label,
                value: role && role.name,
              };
            }
            return f;
          }),
      ],
      id: 'orderer',
      maxHeight: 250,
      title: summarySections.orderer.title,
    },
    {
      fields: users.map((u, idx) => {
        const role = roleOptions.find(o => o.value === u.role);
        return {
          id: idx,
          label: role && role.name,
          value: u.email,
        };
      }),
      id: 'users',
      maxHeight: Math.ceil(users.length / 2.0) * 60,
      title: summarySections.users.title,
    },
    {
      fields: [
        {
          id: 'companyId',
          label: formFields.companyId.label,
        },
        heatingGroupId && {
          id: 'heatingGroupId',
          label: formFields.heatingGroupId.label,
        },

        {
          id: 'prices',
          valueAllowHtml: true,
          label: summaryFields.prices.label,
          value: summaryFields.prices.value
            .replace('$annualPrice', annualPrice)
            .replace('$monthlyPrice', roundToDecimalsLocale(monthlyBaseFee))
            .replace(
              '$monthlyPriceAlv',
              roundToDecimalsLocale(monthlyBaseFee * 1.24),
            )
            .replace('$baseFee', roundToDecimalsLocale(baseFee))
            .replace(
              '$baseFeeAlv',
              roundToDecimalsLocale(Number(baseFee) * 1.24),
            )
            .replace(
              '$meterBaseFee',
              roundToDecimalsLocale(Number(meterBaseFee) * meterCount),
            )
            .replace(
              '$meterBaseFeeAlv',
              roundToDecimalsLocale(Number(meterBaseFee) * meterCount * 1.24),
            ),
        },
        {
          id: 'contractPeriod',
          label: summaryFields.contractPeriod.label,
          value: summaryFields.contractPeriod.value,
        },
        {
          id: 'priceIncludes',
          label: summaryFields.priceIncludes.label,
          value: summaryFields.priceIncludes.value.replace(
            '$meterCount',
            meterCount,
          ),
          valueAllowHtml: true,
        },
        isHelenCustomer && {
          id: 'helenBillingMethod',
          label: summaryFields.helenBillingMethod.label,
          value: summaryFields.helenBillingMethod.value,
        },

        !isHelenCustomer && {
          id: 'billingMethod',
          label: summaryFields.billingMethod.label,
          value: summaryFields.billingMethod.value,
        },
        {
          id: 'billingAddress',
          label: formFields.billingAddress.label,
        },
      ].filter(Boolean),
      id: 'billing',
      maxHeight: 500,
      title: summarySections.billing.title,
    },
  ];

  return (
    <Container>
      <Content>
        <TitleContainer>
          <BackIconContainer>
            <BackIcon onClick={() => goBack()} alt="Back" />
          </BackIconContainer>
          <TitleBallot>2</TitleBallot>
          <TitleText>{summaryTitle}</TitleText>
        </TitleContainer>
        {subSections.map(({ fields, id: sectionId, maxHeight, title }) => (
          <SubSection key={sectionId}>
            <SubSectionTitle>{title}</SubSectionTitle>
            <Fields maxHeight={maxHeight}>
              {fields
                .filter(field => field.type !== 'empty')
                .map(({ id, label, value, valueAllowHtml }) => (
                  <Field key={id}>
                    {label ? <FieldLabel>{label}</FieldLabel> : null}
                    {valueAllowHtml ? (
                      <FieldValue dangerouslySetInnerHTML={{ __html: value }} />
                    ) : (
                      <FieldValue>
                        {value || transformValue(id, data[id]) || '-'}
                      </FieldValue>
                    )}
                  </Field>
                ))}
            </Fields>
            <Divider />
          </SubSection>
        ))}

        <Formik
          initialValues={{ acceptTerms: false }}
          isInitialValid
          validationSchema={Yup.object().shape({
            acceptTerms: Yup.boolean().oneOf([true], summaryCheckboxError),
          })}
          onSubmit={onSubmit}
          render={({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <AcceptTermsCheckbox
                defaultValue={false}
                error={touched.acceptTerms && errors.acceptTerms}
                id="acceptTerms"
                label={summaryCheckboxLabel}
                name="acceptTerms"
                onBlur={handleBlur}
                onChange={handleChange}
                touched={touched.acceptTerms}
              />
              {// FIXME: This should be customizable
              submitError && (
                <TextAlert role="alert">
                  Tapahtui virhe, yritä myöhemmin uudelleen
                </TextAlert>
              )}
              {summarySubtext && <Subtext>{summarySubtext}</Subtext>}
              <ConfirmButton
                disabled={!isValid || isSubmitting || !isEmpty(errors)}
                secondary
                type="submit"
              >
                {summarySubmitButtonText}
              </ConfirmButton>
            </Form>
          )}
        />
      </Content>
    </Container>
  );
};

SummarySection.propTypes = {
  data: shape({}).isRequired,
  formFields: shape({}).isRequired,
  goBack: func.isRequired,
  onSubmit: func.isRequired,
  roleOptions: arrayOf(shape({})).isRequired,
  submitError: string,
  summaryCheckboxError: string.isRequired,
  summaryCheckboxLabel: string.isRequired,
  summaryFields: shape({}).isRequired,
  summarySections: shape({}).isRequired,
  summarySubmitButtonText: string.isRequired,
  summarySubtext: string.isRequired,
  summaryTitle: string.isRequired,
};

SummarySection.defaultProps = {
  submitError: null,
};

export default SummarySection;
