import React, { Fragment } from 'react';
import { string, shape, arrayOf } from 'prop-types';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import {
  mediaBreakpointDownLg,
  mediaBreakpointDownMd,
  mediaBreakpointDownSm,
  mediaBreakpointDownXs,
  sm,
  xl,
} from 'styled-bootstrap-responsive-breakpoints';
import PrismicDOM from 'prismic-dom';
import HelenLogo from '../../../assets/helen-logo.svg';
import HelenLogoNoText from '../../../assets/helen-logo-only.svg';
import Brand from '../../../assets/brand.svg';
import Button from '../../Button';
import {
  appBackgroundColor,
  buttonThemes,
  fontFamilyNarrow,
  fontSizeH4,
  typeColor,
  landingPageMargin,
  landingPageMarginSmall,
  massiveScreenBreakpoint,
} from '../../../constants/styles-old';
import TextColumn from '../TextColumn';

const { RichText } = PrismicDOM;

const ImagePropType = shape({
  dimensions: shape({}).isRequired,
  alt: string,
  url: string.isRequired,
});

const TextPropType = arrayOf(
  shape({
    type: string,
    text: string,
    spans: arrayOf(shape({})),
  }),
);

const Logo = styled.img`
  position: absolute;
  left: ${landingPageMarginSmall}rem;
  top: ${landingPageMarginSmall}rem;
  z-index: 1;
`;

Logo.displayName = 'Logo';

const ButtonGroup = styled.div`
  > a {
    width: 200px !important;
  }
`;

ButtonGroup.displayName = 'ButtonGroup';

const LoginButton = styled(Button)`
  margin-right: 1rem;

  ${mediaBreakpointDownSm`
    margin-bottom: 1rem;
  `};
`;

LoginButton.displayName = 'LoginButton';

const BrandLogoWrapper = styled.div`
  margin-bottom: 2.25rem;
  width: 387px;

  ${mediaBreakpointDownLg`
    width: 340px;
  `};
`;

const BrandLogo = styled.img`
  width: 100%;
`;

BrandLogo.displayName = 'BrandLogo';

const ScreenReaderH1 = styled.h1`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

ScreenReaderH1.displayName = 'ScreenReaderH1';

const HeroBackgroundImage = styled.div`
  left: 0;
  right: 0;
  position: absolute;
  width: 100vw;
  height: 800px;
  background-image: ${({ backgroundImages }) =>
    backgroundImages.map(val => `url(${val.url})`).join(',')};
  background-color: ${appBackgroundColor};
  background-repeat: no-repeat;
  background-position: right 5rem center, right 8rem bottom 3rem,
    right -5rem top;
  background-size: 600px auto, 600px auto, contain;

  ${mediaBreakpointDownLg`
    background-position: 60vw center, 55vw 35.5rem, 50vw top;
  `};

  ${mediaBreakpointDownXs`
    display: none;
  `};
`;

const HeroSection = styled.section`
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  padding-left: ${landingPageMargin}rem;
  padding-right: ${landingPageMargin}rem;
  background-color: ${appBackgroundColor};

  ${mediaBreakpointDownMd`
    padding-left: ${landingPageMarginSmall}rem;
    padding-right: ${landingPageMarginSmall}rem;
  `};

  ${mediaBreakpointDownXs`
    min-height: auto;
  `};
`;

const HeroText = styled.p`
  margin-bottom: 2.25rem;
  font-family: ${fontFamilyNarrow};
  font-size: ${fontSizeH4};
  color: ${typeColor};
  max-width: 500px;
`;

const HeroTextColumnWrapper = styled(TextColumn)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  z-index: 1;
  width: 100%;
  max-width: ${xl}px;
  min-height: 600px;

  @media (min-width: ${massiveScreenBreakpoint}px) {
    margin-left: auto;
    margin-right: auto;
  }

  ${mediaBreakpointDownXs`
    min-height: auto;
  `};
`;

const HeroTextColumn = styled.div`
  display: block;
  width: 100%;

  ${mediaBreakpointDownMd`
    width: 60%;
  `};

  ${mediaBreakpointDownXs`
    width: 100%;
  `};
`;

const Hero = ({
  mainImage,
  dropshadowImage,
  bgImage,
  loginButtonText,
  heroText,
  heroTitle,
  heroButtonText,
}) => (
  <Fragment>
    <MediaQuery maxWidth={sm - 1}>
      {matches => (
        <Logo src={matches ? HelenLogoNoText : HelenLogo} alt="Helen" />
      )}
    </MediaQuery>
    <HeroSection>
      <HeroTextColumnWrapper>
        <HeroTextColumn>
          <ScreenReaderH1>{RichText.asText(heroTitle)}</ScreenReaderH1>
          <BrandLogoWrapper>
            <BrandLogo src={Brand} alt={RichText.asText(heroTitle)} />
          </BrandLogoWrapper>
          <HeroText
            dangerouslySetInnerHTML={{ __html: RichText.asText(heroText) }}
          />
          <ButtonGroup>
            <LoginButton link to={'/login'}>
              {RichText.asText(loginButtonText)}
            </LoginButton>
            <Button
              outline
              theme={buttonThemes.dark}
              protocolLink
              href="mailto:asiakaspalvelu@kiinteistovahti.helen.fi"
            >
              {RichText.asText(heroButtonText)}
            </Button>
          </ButtonGroup>
        </HeroTextColumn>
      </HeroTextColumnWrapper>
      <HeroBackgroundImage
        backgroundImages={[mainImage, dropshadowImage, bgImage]}
      />
    </HeroSection>
  </Fragment>
);

Hero.propTypes = {
  mainImage: ImagePropType.isRequired,
  dropshadowImage: ImagePropType.isRequired,
  bgImage: ImagePropType.isRequired,
  loginButtonText: TextPropType.isRequired,
  heroText: TextPropType.isRequired,
  heroTitle: TextPropType.isRequired,
  heroButtonText: TextPropType.isRequired,
};

export default Hero;
