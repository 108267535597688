import gql from 'graphql-tag';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Mutation, Query } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createGlobalStyle } from 'styled-components';
import uuidv4 from 'uuid/v4';
import PrismicDOM from 'prismic-dom';

import QuotationForm from '../../components/QuotationForm';
import { helenFontFaces } from '../../constants/styles-old';
// import withAnalytics from '../../lib/Analytics';
import linkResolver from '../../lib/link-resolver';

/* eslint-disable-next-line no-unused-vars, import/order */
import { iframeResizerContentWindow } from 'iframe-resizer';

const LoadFonts = createGlobalStyle`${helenFontFaces}`;

const getQuotationFormContentQuery = gql`
  query getQuotationFormContent {
    allQuotationForms: allQuotation_forms {
      edges {
        node {
          calculatorPageTitle: calculator_page_title
          calculatorPageSubtitle: calculator_page_subtitle
          apartmentCountTitle: apartment_count_title
          apartmentCountSubtitle: apartment_count_subtitle
          sliderTooltipApartments: slider_tooltip_apartments
          sliderTooltipMeters: slider_tooltip_meters
          meterCountTitle: meter_count_title
          defaultMetersText: default_meters_text
          defaultMetersCheckboxLabel: default_meters_checkbox_label
          allMetersText: all_meters_text
          allMetersCheckboxLabel: all_meters_checkbox_label
          decadeSelectionTitle: decade_selection_title
          buildings {
            decade
            volume
            consumption
          }
          priceTitle: price_title
          savingsTitle: savings_title
          savingsSecondaryTitle: savings_secondary_title
          savingsText: savings_text
          savingsPlaceholder: savings_placeholder
          priceTextAnnual: price_text_annual
          priceTextMonthly: price_text_monthly
          priceSubtext: price_subtext
          buttonText: button_text
          defaultApartmentCount: default_apartment_count
          minApartmentCount: min_apartment_count
          maxApartmentCount: max_apartment_count
          monthlyBaseFee: monthly_base_fee
          monthlyBaseFeeAuto: monthly_base_fee_auto
          monthlyMeterPrice: monthly_meter_price
          heatingPercentage: heating_percentage
          heatingUnitPrice: heating_unit_price
          savingsPercentage: savings_percentage
          teaserSectionTitle: teaser_title
          teaserCardTitle: teaser_card_title
          teaserCardTitleAuto: teaser_card_title_auto
          teaserCardSubtitle: teaser_card_subtitle
          teaserCardSubtitleAuto: teaser_card_subtitle_auto
          teaserCardTextContent: teaser_card_text_content
          teaserCardTextContentAuto: teaser_card_text_content_auto
          teaserPriceTitle: teaser_price_title
          teaserDialogBodyText: teaser_dialog_body_text
          teaserDialogTitleText: teaser_dialog_title_text
          teaserDialogCloseText: teaser_dialog_close_text
          savingPotentialSubtext: saving_potential_subtext
          priceGroups: price_groups {
            maxApartmentCount: price_group_max_apartment_count
            baseFee: price_group_base_fee
            monthlyFee: price_group_monthly_fee
          }
          meterBaseFee: meter_base_fee
          step

          formPageTitle: form_page_title
          formPageSubtitle: form_page_subtitle
          fields {
            defaultValue: default_value
            errorMessage: error_message
            id
            info
            label
            optionsKey: options_key
            placeholder
            required
            type
            width
          }
          roleOptions: role_options {
            value
            name
          }
          backButtonText: back_button_text
          submitButtonText: submit_button_text
          submittingButtonText: submitting_button_text
          submittedButtonText: submitted_button_text
          contactText: contact_text

          thanksPageTitle: thanks_page_title
          thanksPageSubtitle: thanks_page_subtitle
          thanksPageContactText: thanks_page_contact_text
          thanksPageButtonText: thanks_page_button_text
        }
      }
    }
  }
`;

const createQuotationMutation = gql`
  mutation createQuotation($quotation: QuotationInput!) {
    createQuotation(input: { quotation: $quotation })
      @rest(type: "Quotation", path: "/order/quotation", method: "POST") {
      address
      apartmentCount
      email
      expiresAt
      firstName
      info
      lastName
      baseFee
      meterBaseFee
      meterCount
      monthlyPrice
      phone
      role
      uuid
      zip
    }
  }
`;

const getBuildingInfo = buildings =>
  buildings.reduce((acc, curr) => {
    acc[curr.decade] = {
      volume: curr.volume,
      consumption: curr.consumption,
    };
    return acc;
  }, {});

const getFieldInfo = fields =>
  fields.reduce((acc, curr) => {
    acc[curr.id] = {
      defaultValue: curr.defaultValue,
      errorMessage: curr.errorMessage,
      info: curr.info,
      label: curr.label,
      optionsKey: curr.optionsKey,
      placeholder: curr.placeholder,
      required: curr.required === 'true',
      type: curr.type,
      width: curr.width ? Number(curr.width) : [1, 0.5, 1 / 3],
    };
    return acc;
  }, {});

const getRoleOptions = roles =>
  roles.map(({ value, name }) => ({
    value,
    name,
  }));

const mapData = ({
  allQuotationForms: {
    edges: [
      {
        node: {
          buildings,
          defaultApartmentCount,
          fields,
          heatingPercentage,
          heatingUnitPrice,
          labels,
          // maxApartmentCount,
          meterBaseFee,
          minApartmentCount,
          // monthlyBaseFee,
          // monthlyBaseFeeAuto,
          monthlyMeterPrice,
          roleOptions,
          savingsPercentage,
          teaserSectionTitle,
          teaserCardTitle,
          teaserCardTitleAuto,
          teaserCardSubtitle,
          teaserCardSubtitleAuto,
          teaserCardTextContent,
          teaserCardTextContentAuto,
          teaserPriceTitle,
          teaserDialogBodyText,
          teaserDialogTitleText,
          teaserDialogCloseText,
          savingPotentialSubtext,
          priceGroups,
          ...rest
        },
      },
    ],
  },
  // onGaEvent,
}) => {
  return {
    ...rest,
    buildingInfo: getBuildingInfo(buildings),
    defaultApartmentCount: Number(defaultApartmentCount),
    fieldInfo: getFieldInfo(fields),
    heatingPercentage: Number(heatingPercentage),
    heatingUnitPrice: Number(heatingUnitPrice),
    minApartmentCount: Number(minApartmentCount),
    monthlyMeterPrice: Number(monthlyMeterPrice),
    // onGaEvent,
    roleOptions: getRoleOptions(roleOptions),
    savingsPercentage: Number(savingsPercentage),
    teaserSectionTitle: teaserSectionTitle[0].text,
    teaserCardTitle: teaserCardTitle[0].text,
    teaserCardTitleAuto: teaserCardTitleAuto[0].text,
    teaserCardSubtitle: teaserCardSubtitle[0].text,
    teaserCardSubtitleAuto: teaserCardSubtitleAuto[0].text,
    teaserCardTextContent,
    teaserCardTextContentAuto,
    teaserDialogBodyText: PrismicDOM.RichText.asHtml(
      teaserDialogBodyText,
      linkResolver,
    ),
    teaserDialogTitleText,
    teaserDialogCloseText,
    teaserPriceTitle: teaserPriceTitle[0].text,
    savingPotentialSubtext,
    priceGroups,
    meterBaseFee,
  };
};

const handleSubmit = (
  createQuotation,
  // onGaEvent
) => async ({
  annualSavings,
  apartmentCount,
  constructionDecade,
  meterCount,
  baseFee,
  meterBaseFee,
  monthlyBaseFee,
  monthlyMeterPrice,
  serviceType,
  values: { address, email, firstName, info, lastName, phone, role, zip },
}) => {
  const quotation = {
    address,
    annualSavings,
    apartmentCount,
    constructionDecade: constructionDecade.value,
    email,
    expiresAt: moment()
      .add(60, 'day')
      .format(),
    firstName,
    info,
    lastName,
    meterCount,
    baseFee,
    meterBaseFee,
    monthlyBaseFee,
    monthlyMeterPrice,
    phone,
    role,
    serviceType,
    uuid: uuidv4(),
    zip,
  };

  try {
    await createQuotation({ variables: { quotation } });

    // onGaEvent({
    //   action: 'Submit quotation form',
    //   category: 'Quotation form',
    // });
  } catch (err) {
    throw err;
  }
};

export default compose(
  withRouter,
  // withAnalytics,
)(ownProps => {
  // const { onGaEvent } = ownProps;

  return (
    <Query query={getQuotationFormContentQuery}>
      {({ loading, data, error }) => {
        if (loading || error) {
          return null;
        }

        return (
          <Mutation mutation={createQuotationMutation}>
            {createQuotation => (
              <Fragment>
                <LoadFonts />
                <QuotationForm
                  handleSubmit={handleSubmit(
                    createQuotation,
                    // onGaEvent
                  )}
                  {...mapData({ ...data, ...ownProps })}
                />
              </Fragment>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
});
