import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  fontSizeSmall,
  red,
  brandColor,
  fontFamilySansSerif,
} from '../../constants/styles-old';

export const TextButton = styled.span.attrs({
  role: 'button',
})`
  color: ${props => (props.danger ? red : brandColor)};
  cursor: pointer;
  font-size: ${fontSizeSmall};
  font-family: ${fontFamilySansSerif};

  &:hover,
  &:focus {
    color: ${props => (props.danger ? red : brandColor)};
    text-decoration: underline;
  }
`;

export const TextLinkButton = TextButton.withComponent(Link);
export const TextAbsoluteLinkButton = TextButton.withComponent('a');

export default TextButton;
