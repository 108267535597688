import React, { Fragment } from 'react';
import {
  number,
  oneOf,
  oneOfType,
  shape,
  string,
  bool,
} from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import { colors, fonts } from '../../constants/styles';

const PropTypes = {
  autoComplete: string,
  error: string,
  id: oneOfType([string, number]),
  info: string,
  label: string,
  theme: shape({}),
  title: string,
  type: oneOf(['text', 'password', 'email', 'number']),
  values: shape({}),
  className: string,
  // onGaEvent: func,
  // onGaPageView: func,
  // onGaSet: func,
  required: bool,
};

const DefaultProps = {
  autoComplete: 'on',
  error: null,
  id: null,
  info: null,
  label: null,
  theme: colors.helenInputTheme,
  title: null,
  type: 'text',
  values: null,
  className: '',
  // onGaEvent: () => {},
  // onGaPageView: () => {},
  // onGaSet: () => {},
  required: false,
};

const icons = {
  error:
    'PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0VFMjY0NSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNLjc4MiAxMS41OTZsMi4xMjIgMi4xMjJMMTQuMjE4IDIuNDA0IDEyLjA5Ni4yODJ6Ii8+PHBhdGggZD0iTS43ODIgMi40MDRMMi45MDQuMjgybDExLjMxNCAxMS4zMTQtMi4xMjIgMi4xMjJ6Ii8+PC9nPjwvc3ZnPg==',
  success:
    'PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzRFQUQ2RCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNNi43MzIgMTIuMzI1bC0yLjEyLTIuMUwxNC41MS40MjRsMi4xMiAyLjF6Ii8+PHBhdGggZD0iTTguODUzIDEwLjIyNmwtMi4xMiAyLjEtNi4zNjUtNi4zIDIuMTIxLTIuMXoiLz48L2c+PC9zdmc+',
  info:
    'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTdweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTcgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjIgKDY3MTQ1KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5Hcm91cDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTVFlMRUdVSURFIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iRk9STVMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05NTQuMDAwMDAwLCAtMzQyMy4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5NTQuMDAwMDAwLCAzNDIzLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbC0yIiBmaWxsPSIjMTAwMTY5IiBjeD0iOC41IiBjeT0iOC41IiByPSI4LjUiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTcuNjQ5LDUuNDc3IEw3LjY0OSw0LjA1OCBMOS4yNzcsNC4wNTggTDkuMjc3LDUuNDc3IEw3LjY0OSw1LjQ3NyBaIE03LjY4MiwxMiBMNy42ODIsNi4xMDQgTDkuMjQ0LDYuMTA0IEw5LjI0NCwxMiBMNy42ODIsMTIgWiIgaWQ9ImkiIGZpbGw9IiNGRkZGRkYiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
};

const backgroundImage = ({ error, required, touched }) => {
  if (!required || !touched) {
    return null;
  }

  return error ? icons.error : icons.success;
};

export const StyledInput = styled.input`
  background-color: ${props => props.theme.backgroundColor};

  background-image: ${props =>
    `url(data:image/svg+xml;base64,${backgroundImage(props)})`};

  background-position: 97% center;
  background-repeat: no-repeat;

  border-color: ${props => props.theme.borderColor};

  border-radius: ${props => props.theme?.inputComponentRadius};
  border-style: solid;
  border-width: ${props => (props.error ? '2px' : '1px')};
  caret-color: ${props => props.theme.caretColor};
  color: ${props => props.theme.inputColor};
  display: inline-block;
  font-family: ${fonts.families.helenFontFamilySansSerif};
  font-size: ${fonts.helenSizes.base};
  font-weight: 400;
  line-height: 1.5;

  padding: ${props =>
    props.error ? '0.625rem 0.9375rem' : '0.6875rem 0.9375rem'};

  width: 100%;

  &:focus,
  &:active {
    background-color: ${props => props.theme.backgroundColorFocus};
    border-color: ${props => props.theme.borderColorFocus};
    border-width: 2px;

    padding: 0.625rem 0.9375rem;
  }

  &:disabled {
    background: ${props => props.theme.backgroundColorDisabled};
    border-color: ${props => props.theme.borderColor};
    border-width: 1px;
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${props => props.theme.placeholderColor};
  }
`;

export const StyledLabel = styled.label`
  color: ${props =>
    props.disabled ? props.theme.labelColorDisabled : props.theme.labelColor};

  font-family: ${fonts.families.helenFontFamilySansSerif};
  font-size: ${fonts.helenSizes.base};
  font-weight: 500;
`;

export const Message = styled.div`
  background-color: ${props =>
    props.error ? props.theme.errorMessageBackgroundColor : 'transparent'};

  color: ${props =>
    props.error ? props.theme.errorMessageColor : props.theme.labelColor};

  font-family: ${fonts.families.helenFontFamilySansSerif};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.3;
  margin-top: 0.125rem;
  padding-top: 0.5rem;
`;

export const Asterisk = styled.span`
  color: ${colors.night};
  display: inline-block;
  margin-left: 0.25rem;
`;

const Info = styled.span`
  color: ${colors.helenTypeColor};
  font-weight: 300;
  font-size: 0.875rem;
  display: inline-block;
  margin-left: 0.5rem;
`;

const InfoIcon = styled.span`
  background: url(data:image/svg+xml;base64,${icons.info}) no-repeat;
  display: inline-block;
  height: 19px;
  margin-left: 0.25rem;
  vertical-align: middle;
  width: 17px;
`;

const HelenInput = ({
  autoComplete,
  className,
  error,
  id,
  info,
  label,
  // onGaEvent, // styled-components@v4 doesn't like these
  // onGaPageView, // styled-components@v4 doesn't like these
  // onGaSet, // styled-components@v4 doesn't like these
  required,
  theme = colors.helenInputTheme,
  title,
  type,
  values,
  ...rest
}) => (
  <div className={className}>
    <ThemeProvider theme={theme}>
      <Fragment>
        {label && (
          <StyledLabel
            disabled={rest.disabled}
            error={error}
            hidden={rest.hidden || false}
            htmlFor={id}
          >
            {label}
            {required ? <Asterisk>*</Asterisk> : null}
            {info ? (
              <Fragment>
                <Info>{info}</Info>
                <InfoIcon />
              </Fragment>
            ) : null}
          </StyledLabel>
        )}
        <StyledInput
          autoComplete={autoComplete}
          error={error}
          id={id}
          required={required}
          type={type}
          {...rest}
        />
        {error && <Message error={error}>{error}</Message>}
      </Fragment>
    </ThemeProvider>
  </div>
);

HelenInput.propTypes = PropTypes;
HelenInput.defaultProps = DefaultProps;

export default HelenInput;
