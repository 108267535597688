import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import {
  appBackgroundColor,
  typeColor,
  typeLightColor,
  fontFamilySansSerif,
} from '../../constants/styles-old';
import emptyStateIcon from '../../assets/empty-state-icon.svg';
import Button from '../Button';

const propTypes = {
  icon: string,
  title: string,
  description: string,
  link: string,
  linkText: string,
};

const defaultProps = {
  icon: emptyStateIcon,
  title: 'Ei dataa',
  description: null,
  link: null,
  linkText: null,
};

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  font-family: ${fontFamilySansSerif};
  text-align: center;
  background: ${appBackgroundColor};
  justify-content: center;
`;

const Image = styled.img`
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-width: 350px;
`;

const Description = styled.p`
  color: ${typeLightColor};
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 2.25rem;
`;

const Title = styled.h2`
  font-weight: 400;
  color: ${typeColor};
  font-size: 1.75rem;
  font-weight: 300;
  margin-bottom: 1rem;
`;

const EmptyStatePage = ({ icon, title, description, link, linkText }) => (
  <Container>
    <EmptyWrapper>
      {icon && <Image src={icon} alt={title} />}
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {link && (
        <div>
          <Button link to={link}>
            {linkText}
          </Button>
        </div>
      )}
    </EmptyWrapper>
  </Container>
);

EmptyStatePage.propTypes = propTypes;
EmptyStatePage.defaultProps = defaultProps;

export default EmptyStatePage;
