import styled from 'styled-components';
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints';

import {
  helenFontSizeH1,
  fontSizeH4,
  helenFontSizeNormal,
  helenTypeColorDark,
  helenTypeColorLighter,
} from '../../constants/styles-old';

const Heading1 = styled.h1`
  color: ${helenTypeColorDark};
  font-size: ${helenFontSizeH1};
  font-weight: 400;
  line-height: normal;
  white-space: pre-wrap;
  word-wrap: break-word;

  ${mediaBreakpointDownSm`
    font-size: 1.4375rem;
  `};
`;

const Heading6 = styled.h6`
  color: ${helenTypeColorLighter};
  font-size: ${fontSizeH4};
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Ingress = styled.h6`
  color: ${helenTypeColorDark};
  font-size: ${helenFontSizeNormal};
  font-weight: 400;
  line-height: 1.4;
  text-transform: none;
  white-space: pre-wrap;
  word-wrap: break-word;

  ${mediaBreakpointDownSm`
    font-size: 0.9375rem;
  `};
`;

export { Heading1, Heading6, Ingress };
