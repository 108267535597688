import React, { Fragment } from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';
import { appBackgroundColor } from '../../constants/styles-old';
import Hero from './Hero';
import WhySection from './WhySection';
import HowSection from './HowSection';
import UsageSection from './UsageSection';
import CallToAction from './CallToAction';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${appBackgroundColor};
`;

const LandingPage = ({ data: { index } }) => (
  <Container>
    <Hero {...index} />
    {process.env.REACT_APP_ENABLE_FULL_FRONTPAGE && (
      <Fragment>
        <WhySection {...index} />
        <HowSection {...index} />
        <UsageSection {...index} />
        <CallToAction {...index} />
      </Fragment>
    )}
  </Container>
);

LandingPage.propTypes = {
  data: shape({
    index: shape({}).isRequired,
  }).isRequired,
};

export default LandingPage;
