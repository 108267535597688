import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { string, bool, element, oneOfType, array, func } from 'prop-types';

import {
  buttonThemes,
  fontFamilySansSerif,
  fontSizeSmall,
} from '../../constants/styles-old';

const PropTypes = {
  children: oneOfType([element, string, array]).isRequired,
  link: bool,
  disabled: bool,
  fullWidth: bool,
  hash: bool,
  scroll: func,
  protocolLink: bool,
};

const DefaultProps = {
  link: false,
  disabled: false,
  fullWidth: false,
  hash: false,
  scroll: null,
  protocolLink: false,
};

const StyledButton = styled.button`
  background-color: ${props =>
    !props.outline ? props.theme.backgroundColor : 'transparent'};
  color: ${props =>
    props.outline ? props.theme.backgroundColor : props.theme.color};
  border: 2px solid
    ${props =>
      props.outline || props.neutral
        ? props.theme.backgroundColor
        : 'transparent'};
  font-size: ${fontSizeSmall};
  font-family: ${fontFamilySansSerif};
  font-weight: 500;
  margin: 0;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  line-height: 1.5;
  outline: none;
  position: relative;
  transition: all 0.3s;
  padding: ${props =>
    props.size === 'small' ? '0.55rem 1rem' : '0.75rem 3rem'};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  pointer-events: ${props => (props.disabled ? 'none' : 'inherit')};
  user-select: none;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
  }

  &:hover {
    background-color: ${props =>
      !props.outline ? props.theme.hover : 'transparent'};
    border: 2px solid
      ${props =>
        props.outline || props.neutral ? props.theme.hover : 'transparent'};
    color: ${props => (props.outline ? props.theme.hover : props.theme.color)};
    text-decoration: none;
  }

  &:active {
    background-color: ${props =>
      !props.outline ? props.theme.focus : 'transparent'};
    border: 2px solid
      ${props =>
        props.outline || props.neutral ? props.theme.focus : 'transparent'};
  }

  &:focus {
    outline: 0;
  }

  &:not([href]):not([tabindex]),
  &:not([href]):not([tabindex]):hover {
    color: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.disabled
        ? props.theme.disabledColor
        : props.outline
        ? props.theme.backgroundColor
        : props.theme.color};
    text-decoration: none;
  }

  &:disabled {
    background-color: ${props => props.theme.disabled};
    border: 2px solid transparent;
  }

  &:disabled:hover,
  &:disabled:active {
    background: ${props => props.theme.disabled};
    color: ${props => props.theme.disabledColor};
    border: 2px solid transparent;
    text-decoration: none;
  }
`;

const StyledLink = styled(
  StyledButton.withComponent(({ danger, neutral, outline, ...props }) => (
    <Link {...props} />
  )),
)`
  flex: 1;
`;

const StyledProtocolLink = styled(StyledButton.withComponent('a')).attrs({
  target: '_blank',
})`
  flex: 1;
`;

const StyledHashLink = styled(
  StyledButton.withComponent(({ outline, ...props }) => (
    <HashLink {...props} />
  )),
)`
  flex: 1;
`;

const getTheme = ({ danger, neutral }) => {
  if (danger) {
    return buttonThemes.danger;
  }

  if (neutral) {
    return buttonThemes.neutral;
  }

  return buttonThemes.primary;
};

const Button = ({
  children,
  link,
  fullWidth,
  hash,
  scroll,
  protocolLink,
  ...rest
}) => {
  if (hash) {
    return (
      <ThemeProvider theme={getTheme({ ...rest })}>
        <StyledHashLink
          style={{ width: fullWidth ? '100%' : 'auto' }}
          scroll={scroll}
          {...rest}
        >
          {children}
        </StyledHashLink>
      </ThemeProvider>
    );
  }

  if (protocolLink) {
    return (
      <ThemeProvider theme={getTheme({ ...rest })}>
        <StyledProtocolLink
          style={{ width: fullWidth ? '100%' : 'auto' }}
          {...rest}
        >
          {children}
        </StyledProtocolLink>
      </ThemeProvider>
    );
  }

  if (link) {
    return (
      <ThemeProvider theme={getTheme({ ...rest })}>
        <StyledLink style={{ width: fullWidth ? '100%' : 'auto' }} {...rest}>
          {children}
        </StyledLink>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={getTheme({ ...rest })}>
      <StyledButton fullWidth={fullWidth} {...rest}>
        {children}
      </StyledButton>
    </ThemeProvider>
  );
};

Button.propTypes = PropTypes;
Button.defaultProps = DefaultProps;

export default Button;
