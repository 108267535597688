import React, { Fragment } from 'react';
import {
  bool,
  string,
  number,
  oneOf,
  oneOfType,
  shape,
  // func,
} from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import {
  fontFamilySansSerif,
  fontSizeSmall,
  brandDangerColor,
  ComponentRadius,
  inputThemes,
} from '../../constants/styles-old';

const PropTypes = {
  label: string,
  id: oneOfType([string, number]),
  type: oneOf(['text', 'password', 'email', 'number']),
  error: string,
  theme: shape({}),
  values: shape({}),
  title: string,
  autoComplete: string,
  icon: bool,
  // onGaSet: func,
  // onGaEvent: func,
  // onGaPageView: func,
  className: string,
};

const DefaultProps = {
  className: '',
  label: null,
  id: null,
  type: 'text',
  error: null,
  theme: inputThemes.light,
  values: null,
  title: null,
  autoComplete: 'on',
  icon: false,
  // onGaSet: () => {},
  // onGaEvent: () => {},
  // onGaPageView: () => {},
};

export const StyledInput = styled.input`
  color: ${props => props.theme.inputColor};
  border: 1px solid
    ${props => (props.error ? brandDangerColor : props.theme.borderColor)};
  border-radius: ${ComponentRadius};
  padding: 0.5925rem 0.5rem 0.5925rem 0.75rem;
  font-size: ${fontSizeSmall};
  font-family: ${fontFamilySansSerif};
  font-weight: 600;
  width: 100%;
  line-height: 1.5;
  display: inline-block;
  background-color: ${props => props.theme.backgroundColor};

  &:focus,
  &:active {
    outline: 0;
    background-color: ${props => props.theme.backgroundColorFocus};
    border-color: ${props => props.theme.focusBorderColor};
  }
  &:disabled {
    cursor: not-allowed;
    background: ${props => props.theme.backgroundColorDisabled};
  }
  &::placeholder {
    color: ${props => props.theme.placeholderColor};
  }
`;

export const StyledLabel = styled.label`
  color: ${props => (props.error ? brandDangerColor : props.theme.labelColor)};
  font-weight: 400;
  font-size: 0.75rem;
  font-family: ${fontFamilySansSerif};
`;

export const Message = styled.div`
  background-color: ${props =>
    props.error ? props.theme.errorMessageBackgroundColor : 'transparent'};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-family: ${fontFamilySansSerif};
  color: ${props =>
    props.error ? props.theme.errorMessageTextColor : props.theme.labelColor};
  font-size: ${fontSizeSmall};
`;

const Input = ({
  theme = inputThemes.light,
  label,
  id,
  type,
  error,
  values,
  title,
  autoComplete,
  // onGaSet,
  // onGaEvent,
  // onGaPageView,
  icon,
  className,
  ...rest
}) => (
  <div className={className}>
    <ThemeProvider theme={theme}>
      <Fragment>
        {label && (
          <StyledLabel error={error} hidden={rest.hidden || false} htmlFor={id}>
            {label}
          </StyledLabel>
        )}
        <StyledInput
          id={id}
          type={type}
          error={error}
          autoComplete={autoComplete}
          {...rest}
        />
        {error && <Message error={error}>{error}</Message>}
      </Fragment>
    </ThemeProvider>
  </div>
);

Input.propTypes = PropTypes;
Input.defaultProps = DefaultProps;

export default Input;
