// FIXME: this file should only use imports from color
const colorHover = '#FAF9FB';
const brandColor = '#8F75BE';
const typeColor = '#4A4A4A';
const violet = '#252831';
const violetBright = '#8F73C0';
const dividerColor = '#EAEAEA';
const brandColorHover = '#8169AC';
const brandColorFocus = '#67548A';
const brandDangerColor = '#DE5957';
const brandDangerColorHover = '#BC4847';
const brandDangerColorFocus = '#A53D3C';
const brandNeutralColor = '#ECECEC';

const brandNeutralColorFocus = '#A7A5A5';
const disabledColor = '#F5F5F5';
const disabledTypeColor = '#9B9B9B';

const white = '#fff';
const violetLight = '#30333E';
const navigationListBaseColor = '#1E232E';
const RowColor = '#DBDEE5';
const RowColorActive = white;
const RowBackgroundColor = violet;
const RowBackgroundColorAlt = navigationListBaseColor;
const RowHoverFocus = colorHover;
const RowSelectedColor = violetBright;
const RowBorderBottom = `0px`;

const RowColorInverted = typeColor;
const RowColorActiveInverted = typeColor;
const RowBackgroundColorInverted = white;
const RowHoverFocusInverted = colorHover;
const RowSelectedColorInverted = violetBright;
const RowBorderBottomInverted = dividerColor;

const themes = {
  helenBrandInputTheme: {
    backgroundColor: '#FFFFFF',
    backgroundColorDisabled: '#F2F2F2',
    borderColor: '#caccce',
    borderColorDanger: '#EE2645',
    borderColorDisabled: '#caccce',
    borderColorFocus: '#100169',
    caretColor: '#568EF7',
    dropdownItemColor: '#393939',
    dropdownItemColorHover: '#46484A',
    dropdownItemBackcolorHover: '#F0F8FF',
    errorMessageColor: '#EE2645',
    inputColor: '#393939',
    labelColor: '#000000',
    labelColorDisabled: '#AEAEAE',
    placeholderColor: '#CFCFCF',
    inputComponentRadius: '5px',
    helenBackground: '',
  },
  helenInputTheme: {
    backgroundColor: '#FFFFFF',
    backgroundColorDisabled: '#F2F2F2',
    borderColor: '#393939',
    borderColorDanger: '#EE2645',
    borderColorDisabled: '#ECECEC',
    borderColorFocus: '#568EF7',
    caretColor: '#568EF7',
    dropdownItemColor: '#393939',
    dropdownItemColorHover: '#FF419A',
    errorMessageColor: '#EE2645',
    inputColor: '#393939',
    labelColor: '#000000',
    labelColorDisabled: '#AEAEAE',
    placeholderColor: '#CFCFCF',
  },
  inputThemes: {
    light: {
      containerColor: '#242935',
      inputColor: '#4A4A4A',
      labelColor: '#9B9B9B',
      placeholderColor: '#9B9B9B',
      backgroundColor: '#F5F5F5',
      backgroundColorDisabled: '#F5F5F5',
      backgroundColorFocus: '#FFFFFF',
      focusBorderColor: '#4A90E2',
      borderColor: '#F5F5F5',
      errorMessageBackgroundColor: 'transparent',
      errorMessageTextColor: '#DE5957',
      dropdownColor: '#4A4A4A',
      dropdownColorHover: '#4A4A4A',
      dropdownBackgroundColor: '#FFFFFF',
      dropdownBackgroundColorHover: '#F5F5F5',
      dropdownItemBackgroundColor: '#FFFFFF',
      dropdownItemBackgroundColorHover: '#F5F5F5',
    },
    dark: {
      containerColor: '#242935',
      inputColor: '#4A4A4A',
      labelColor: '#F5F5F5',
      placeholderColor: '#F5F5F5',
      backgroundColor: '#3D414C',
      backgroundColorDisabled: '#3D414C',
      backgroundColorFocus: '#F5F5F5',
      borderColor: '#3D414C',
      focusBorderColor: '#F5F5F5',
      errorMessageBackgroundColor: '#DE5957',
      errorMessageTextColor: '#F5F5F5',
      dropdownColor: '#9B9B9B',
      dropdownColorHover: '#FFFFFF',
      dropdownBackgroundColor: '#1D2027',
      dropdownItemBackgroundColor: '#242935',
      dropdownItemBackgroundColorHover: '#414B62',
    },
  },
  buttonThemes: {
    primary: {
      backgroundColor: brandColor,
      hover: brandColorHover,
      focus: brandColorFocus,
      color: white,
      hoverColor: white,
      disabled: disabledColor,
      disabledColor: disabledTypeColor,
    },
    danger: {
      backgroundColor: brandDangerColor,
      hover: brandDangerColorHover,
      focus: brandDangerColorFocus,
      color: white,
      hoverColor: white,
      disabled: disabledColor,
      disabledColor: disabledTypeColor,
    },
    neutral: {
      backgroundColor: white,
      hover: brandNeutralColor,
      focus: brandNeutralColorFocus,
      color: typeColor,
      hoverColor: typeColor,
      disabled: disabledColor,
      disabledColor: disabledTypeColor,
    },
  },
  tableThemes: {
    light: {
      color: typeColor,
      headerColor: typeColor,
      backgroundColor: white,
      rowBackgroundColor: RowBackgroundColorInverted,
      rowBackgroundColorAlt: RowBackgroundColorInverted,
      rowSelectedColor: RowSelectedColorInverted,
      rowHoverFocus: RowHoverFocusInverted,
      rowColor: RowColorInverted,
      rowColorActive: RowColorActiveInverted,
      rowBorderBottom: RowBorderBottomInverted,
    },
    dark: {
      color: white,
      headerColor: white,
      backgroundColor: violetLight,
      rowBackgroundColor: RowBackgroundColor,
      rowBackgroundColorAlt: RowBackgroundColorAlt,
      rowSelectedColor: RowSelectedColor,
      rowHoverFocus: RowHoverFocus,
      rowColor: RowColor,
      rowColorActive: RowColorActive,
      rowBorderBottom: RowBorderBottom,
    },
  },
};

export default themes;
