import React, { useState } from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { Dialog } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import CloseIcon from '../../OrderForm/FormSection/CloseIcon';
import { fonts } from '../../../constants/styles';

const Button = styled.button`
  display: inline-flex;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  top: -10px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  background: none;
  border: none;
  top: 1rem;
  right: 1rem;
  height: 24px;
  width: 24px;
  padding: 0;

  > span:first-of-type {
    top: 2px;
    left: 2px;
  }
`;

const DialogTitle = styled.h2`
  font-family: ${fonts.families.helenFontFamilySansSerif};
  font-size: ${fonts.sizes.base};
  font-weight: bold;
`;

const DialogBody = styled.p`
  font-family: ${fonts.families.helenFontFamilySansSerif};
  font-size: ${fonts.sizes.small};
  margin-bottom: 0;
`;

const Icon = () => (
  <svg width="17" height="18" fill="none">
    <circle cx="8.5" cy="8.5" r="8.5" fill="#100169" />
    <path
      d="M7.338 10.752H8.682L8.836 9.646C10.166 9.324 11.258 8.568 11.258 6.902V6.888C11.258 5.152 10.026 4.088 8.136 4.088C6.862 4.088 5.924 4.62 5.168 5.39L6.316 6.776C6.764 6.314 7.338 5.936 8.066 5.936C8.794 5.936 9.256 6.314 9.256 6.944V6.972C9.256 7.728 8.612 8.232 7.142 8.358L7.072 8.428L7.338 10.752ZM6.904 14H9.074V11.844H6.904V14Z"
      fill="#fff"
    />
  </svg>
);

const MeterSelectDisclosure = ({
  dialogTitleText,
  dialogBodyText,
  dialogCloseText,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <React.Fragment>
      <Button onClick={open}>
        <Icon />
      </Button>
      {showDialog ? (
        <Dialog
          aria-label={dialogTitleText}
          isOpen={showDialog}
          onDismiss={close}
          style={{ position: 'relative', margin: '45vh auto 10vh' }}
        >
          <CloseButton type="button" className="close-button" onClick={close}>
            <VisuallyHidden>{dialogCloseText}</VisuallyHidden>
            <CloseIcon aria-hidden />
          </CloseButton>
          <DialogTitle>{dialogTitleText}</DialogTitle>
          <DialogBody dangerouslySetInnerHTML={{ __html: dialogBodyText }} />
        </Dialog>
      ) : null}
    </React.Fragment>
  );
};

MeterSelectDisclosure.propTypes = {
  dialogTitleText: string,
  dialogBodyText: string,
  dialogCloseText: string,
};

MeterSelectDisclosure.defaultProps = {
  dialogTitleText: '',
  dialogBodyText: '',
  dialogCloseText: 'Sulje',
};

export default MeterSelectDisclosure;
