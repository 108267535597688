export const calculateSavings = ({
  apartmentCount,
  buildingInfo,
  constructionDecade,
  heatingPercentage,
  heatingUnitPrice,
  savingsPercentage,
}) => {
  if (!buildingInfo[constructionDecade]) {
    return 0;
  }

  const apartmentVolume = buildingInfo[constructionDecade].volume;
  const consumptionPerUnit = buildingInfo[constructionDecade].consumption;

  const totalConsumption = consumptionPerUnit * apartmentVolume;
  const heatingConsumption = totalConsumption * heatingPercentage;
  const heatingPrice = (heatingConsumption / 1000.0) * heatingUnitPrice;
  const apartmentSavings = heatingPrice * savingsPercentage;

  return Math.floor(apartmentSavings * apartmentCount);
};

export const calculateMeterCount = (apartmentCount, coverage = 0.3) =>
  Math.ceil(apartmentCount * coverage);

export const roundUp = (apartmentCount, multiplier) => {
  return Math.ceil(apartmentCount * multiplier, 10);
};

export const meterOptions = apartmentCount =>
  [
    { value: 0.3, name: roundUp(apartmentCount, 0.3), coverage: '30' },
    { value: 0.5, name: roundUp(apartmentCount, 0.5), coverage: '50' },
    { value: 0.7, name: roundUp(apartmentCount, 0.7), coverage: '70' },
    { value: 1, name: roundUp(apartmentCount, 1), coverage: '100' },
  ].map(meter => {
    return {
      value: meter.value,
      name: `<b>${meter.name} kpl</b> ${meter.coverage} % asuntojen määrästä`,
    };
  });
