import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import { compose, renderComponent, branch } from 'recompose';
import { AbsoluteLoadingSpinner } from '../../components/LoadingSpinner';
import LandingPageComponent from '../../components/LandingPage';

const ErrorState = () => <Redirect to={'/login'} />;

const errorWhileError = isError => branch(isError, renderComponent(ErrorState));

const enhanceError = errorWhileError(({ data: { error } }) => error);

const spinnerWhileLoading = isLoading =>
  branch(isLoading, renderComponent(AbsoluteLoadingSpinner));

const enhanceSpinner = spinnerWhileLoading(
  ({ data: { loading, index } }) => !index || loading,
);

const heroFragment = gql`
  fragment heroFragment on Index {
    mainImage: main_image
    dropshadowImage: dropshadow
    bgImage: bg_image
    heroTitle: title
    heroText: text
    heroButtonText: button_text
  }
`;

const QUERY = gql`
  query indexQuery {
    index(uid: "index", lang: "fi") {
      ...heroFragment
      loginButtonText: login_button_text
    }
  }
  ${heroFragment}
`;

export default compose(
  graphql(QUERY),
  enhanceError,
  enhanceSpinner,
)(LandingPageComponent);
