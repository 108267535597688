import React, { Fragment } from 'react';
import { shape, string } from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import {
  helenComponentRadius,
  helenFontFamilySansSerif,
  helenFontSizeNormal,
  helenInputTheme,
  helenTypeColor,
  helenTypeColorDark,
  white,
} from '../../constants/styles-old';
import { colors } from '../../constants/styles';

const PropTypes = {
  id: string.isRequired,
  label: string.isRequired,
  theme: shape({}),
};

const DefaultProps = {
  theme: helenInputTheme,
};

const pukeGreen = 'rgb(78, 173, 109)';

const CheckboxWithLabel = styled.label`
  margin-bottom: 0rem;
  margin-right: 1rem;
`;

const CheckboxLabel = styled.span``;

const CheckboxInput = styled.input`
  &:checked,
  &:not(:checked) {
    left: -9999px;
    position: absolute;

    + ${CheckboxLabel} {
      color: ${({ disabled }) =>
        disabled
          ? colors.helenInputTheme.labelColorDisabled
          : helenTypeColorDark};
      cursor: pointer;
      display: inline-block;
      font-family: ${helenFontFamilySansSerif};
      font-size: ${helenFontSizeNormal};
      font-weight: 500;
      letter-spacing: -0.25px;
      line-height: 1.25;
      margin-left: 0.25rem;
      padding-left: 24px;
      position: relative;
      user-select: none;

      &:before {
        background: ${white};
        border-radius: ${helenComponentRadius};
        border: 1px solid ${helenTypeColor};
        content: '';
        height: 13px;
        left: 0;
        position: absolute;
        top: 3px;
        width: 13px;
      }

      &:after {
        background: ${pukeGreen};
        border: 2px solid #fff;
        border-right: none;
        border-top: none;
        color: ${white};
        content: '';
        font-family: ${helenFontFamilySansSerif};
        font-size: 0.9rem;
        font-weight: 700;
        height: 5px;
        left: 2px;
        position: absolute;
        top: 6px;
        transform: rotate(-45deg);
        width: 9px;
      }
    }
  }

  &:checked + ${CheckboxLabel} {
    &:before {
      background: ${pukeGreen};
      border-width: 0px;
    }

    &:after {
      opacity: 1;
    }
  }

  &:not(:checked) {
    + ${CheckboxLabel} {
      &:hover:before {
        background: #eeeeee;
      }

      &:after {
        opacity: 0;
      }
    }
  }
`;

export const Message = styled.div`
  background-color: ${props =>
    props.error ? props.theme.errorMessageBackgroundColor : 'transparent'};

  color: ${props =>
    props.error
      ? props.theme.errorMessageColor
      : props.theme.errorMessageColor};

  font-family: ${helenFontFamilySansSerif};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.3;
  margin-top: 0.125rem;
  padding-top: 0.5rem;
`;

// eslint-disable-next-line react/prop-types
const Checkbox = ({ className, error, id, label, theme, ...rest }) => (
  <div className={className} key={id}>
    <ThemeProvider theme={theme}>
      <Fragment>
        <CheckboxWithLabel htmlFor={id}>
          <CheckboxInput id={id} {...rest} type="checkbox" />
          <CheckboxLabel
            {...rest}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </CheckboxWithLabel>
        {error && <Message error={error}>{error}</Message>}
      </Fragment>
    </ThemeProvider>
  </div>
);

Checkbox.propTypes = PropTypes;
Checkbox.defaultProps = DefaultProps;

export default Checkbox;
