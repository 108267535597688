import React from 'react';
import styled from 'styled-components';
import { func, number, string } from 'prop-types';
import { mediaBreakpointUpMd } from 'styled-bootstrap-responsive-breakpoints';

import Button from '../../HelenButton';
import { fonts, colors } from '../../../constants/styles';
import { Heading6 } from '../../HelenHeading';
import MeterSelectDialog from '../MeterSelectDialog';
import '@reach/dialog/styles.css';
import { KIINTEISTOVAHTI_SERVICE_TYPES } from '../../../constants/kiinteistovahti-service-types';
import { PriceBox } from '../CalculatorSection';
import { roundToDecimalsLocale } from '../../../lib/math-helpers';

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: ${colors.white};
  margin-bottom: 1rem;
  width: 100%;

  margin-left: 1rem;
  margin-right: 1rem;

  ${mediaBreakpointUpMd`
    flex-direction: row;
    min-height: 393px;
    max-width: 938px; 
  `};
`;

const TeaserWrapper = styled.div`
  flex: 1 1 100%;
  max-width: 100%;
  background: ${colors.white};
  padding: ${props => props.padding || '2.5rem'};
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.noDecade ? 'flex-start' : 'space-between'};
  min-height: 393px;
  order: ${props => (props.leftSide ? 1 : 2)};

  box-shadow: ${props =>
    !props.leftSide ? '0px 2px 10px rgba(0, 0, 0, 0.15)' : 'none'};
  ${mediaBreakpointUpMd`
    order: ${props => (props.leftSide ? 1 : 3)};
    flex-basis: 50%;
    max-width: 50%;
  `};
`;

const Headings = styled.div`
  h3,
  h4 {
    font-weight: bold;
  }
`;

const ProductName = styled.h3`
  font-size: ${fonts.helenSizes.teaserHeading};
  line-height: 2rem;
  margin-bottom: 1rem;
`;

const CardContent = styled.div`
  flex-basis: 1 0 0%;
  font-family: ${fonts.families.helenFontFamilySansSerif};
  font-weight: 300;
  color: ${colors.night};
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${colors.helenDivider};
  line-height: 1.5rem;
`;

const MeterSelectionContainer = styled.div`
  flex-basis: 0 0 0%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const MeterSelectionTitle = styled(Heading6)`
  display: flex;
  flex-basis: 1 0 0%;
  font-size: 1rem;
  letter-spacing: -0.67px;
  margin-bottom: 1rem;
  text-transform: none;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;
const PriceBoxTitle = styled.div`
  font-size: ${fonts.helenSizes.base};
  font-weigt: 400;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const Divider = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 1px;
  background: ${colors.helenDivider};
`;

const CtaButton = styled(Button)`
  width: 50%;
  background-color: ${colors.helenBackground};
  border-radius: 25px;
  align-self: center;
  &:hover,
  &:active {
    background-color: ${props =>
      !props.outline ? colors.helenPrimaryHover : colors.helenSecondaryHover};
    border-color: ${props =>
      props.outline ? colors.helenBorder : colors.helenBackground};
    color: ${colors.helenBackground};
    text-decoration: none;
  }
`;

const Teaser = ({
  // onGaEvent,
  onSubmit,
  meterCountTitle,
  buttonText,
  baseFee,
  meterBaseFee,
  monthlyPrice,
  teaserCardTitle,
  teaserCardTextContent,
  teaserPriceTitle,
  teaserDialogBodyText,
  teaserDialogTitleText,
  teaserDialogCloseText,
  meterCount,
}) => {
  return (
    <Card>
      <TeaserWrapper leftSide>
        <Headings>
          <ProductName
            style={{ fontFamily: fonts.families.helenFontFamilySansSerif }}
          >
            {teaserCardTitle}
          </ProductName>
        </Headings>
        <CardContent>{teaserCardTextContent}</CardContent>
        <MeterSelectionContainer>
          <MeterSelectionTitle>
            <span>{meterCountTitle}</span>
            <MeterSelectDialog
              dialogBodyText={teaserDialogBodyText}
              dialogTitleText={teaserDialogTitleText}
              dialogCloseText={teaserDialogCloseText}
            />
          </MeterSelectionTitle>
          <CardContent>
            {meterCount} asuntoa (30% asuntojen määrästä)
          </CardContent>
        </MeterSelectionContainer>
      </TeaserWrapper>

      <TeaserWrapper>
        <PriceBox
          title={teaserPriceTitle}
          price={roundToDecimalsLocale(monthlyPrice, 2)}
          unit="€ / kk"
          vat={' (Alv 0 %) '}
          subText={`${roundToDecimalsLocale(
            monthlyPrice * 1.24,
          )} € / kk (Alv 24% )`}
        />
        <PriceBoxTitle>Palvelun aloitusmaksut:</PriceBoxTitle>
        <PriceContainer>
          <PriceBox
            small
            title={`Anturit`}
            price={roundToDecimalsLocale(meterBaseFee * meterCount, 2)}
            unit="€"
            vat={' (Alv 0 %) '}
            subText={`${roundToDecimalsLocale(
              meterBaseFee * meterCount * 1.24,
            )} € (Alv 24% )`}
          />
          <Divider />
          <PriceBox
            small
            title={`Käyttöönotto`}
            price={roundToDecimalsLocale(baseFee, 2)}
            unit="€"
            vat={' (Alv 0 %) '}
            subText={`${roundToDecimalsLocale(baseFee * 1.24)} € (Alv 24% )`}
          />
        </PriceContainer>
        <CtaButton
          onClick={() => {
            onSubmit(KIINTEISTOVAHTI_SERVICE_TYPES.FIXED);
            // onGaEvent({
            //   action: 'Navigate to form section',
            //   category: 'Quotation form',
            // });
          }}
        >
          {buttonText}
        </CtaButton>
      </TeaserWrapper>
    </Card>
  );
};

Teaser.propTypes = {
  // onGaEvent: func.isRequired,
  onSubmit: func.isRequired,
  meterCountTitle: string.isRequired,
  buttonText: string.isRequired,
  // serviceTypeAuto: bool,

  monthlyPrice: number.isRequired,
  teaserCardTitle: string.isRequired,

  teaserCardTextContent: string.isRequired,

  teaserPriceTitle: string.isRequired,
  teaserDialogBodyText: string,
  teaserDialogTitleText: string,
  teaserDialogCloseText: string,
  baseFee: number.isRequired,
  meterBaseFee: number.isRequired,
  meterCount: number.isRequired,
};

Teaser.defaultProps = {
  // serviceTypeAuto: false,
  teaserDialogBodyText: '',
  teaserDialogTitleText: '',
  teaserDialogCloseText: 'Sulje',
};

export default Teaser;
