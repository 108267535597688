import React from 'react';
import AuthPropTypes from './authPropTypes';

export default function withAuth(WrappedComponent) {
  const AuthWrapper = (props, context) => (
    <WrappedComponent {...props} {...context} />
  );

  AuthWrapper.contextTypes = {
    auth: AuthPropTypes.isRequired,
  };

  return AuthWrapper;
}
