import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import {
  mediaBreakpointDownMd,
  mediaBreakpointDownSm,
  xl,
} from 'styled-bootstrap-responsive-breakpoints';
import styled from 'styled-components';
import {
  fontFamilyNarrow,
  fontsizeSpotlight,
  fontSizeNormal,
  landingPageMargin,
  landingPageMarginSmall,
  massiveScreenBreakpoint,
  typeColor,
} from '../../../constants/styles-old';
import { SectionTitle } from '../Section';

const UsageSectionContainer = styled.section`
  position: relative;
  min-height: 22rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${landingPageMargin}rem;
  margin-right: ${landingPageMargin}rem;
  justify-content: space-around;
  max-width: ${xl}px;

  @media (min-width: ${massiveScreenBreakpoint}px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  ${mediaBreakpointDownMd`
    margin-left: ${landingPageMarginSmall}rem;
    margin-right: ${landingPageMarginSmall}rem;
    padding-top: ${landingPageMargin / 2}rem;
    padding-bottom: ${landingPageMargin / 2}rem;
    flex-direction: column;
    align-items: flex-start;
  `};
`;

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
  width: 100%;

  ${mediaBreakpointDownSm`
    flex-direction: column;
    margin-bottom: ${landingPageMargin}rem;
  `};
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaBreakpointDownSm`
    &:not(:last-of-type) {
      margin-bottom: ${landingPageMargin / 2}rem;
    }
  `};
`;

const StatsTitle = styled.h3`
  font-family: ${fontFamilyNarrow};
  font-size: ${fontsizeSpotlight};
  font-weight: 900;
  color: ${typeColor};
  margin-bottom: 0;
`;

const StatsText = styled.span`
  font-family: ${fontFamilyNarrow};
  font-size: ${fontSizeNormal};
  color: ${typeColor};
`;

const UsageTitle = styled(SectionTitle)`
  width: 35%;

  ${mediaBreakpointDownMd`
    width: 100%;
  `};
`;

const UsageSection = ({ usageTitle, usage }) => (
  <UsageSectionContainer>
    <UsageTitle>{usageTitle}</UsageTitle>
    <Stats>
      {usage.groups.map(({ title, text }) => (
        <Stat key={text}>
          <StatsTitle>{title}</StatsTitle>
          <StatsText>{text}</StatsText>
        </Stat>
      ))}
    </Stats>
  </UsageSectionContainer>
);

UsageSection.propTypes = {
  usageTitle: string.isRequired,
  usage: arrayOf(
    shape({
      title: string,
      text: string,
    }),
  ).isRequired,
};

export default UsageSection;
