import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import {
  mediaBreakpointDownMd,
  mediaBreakpointDownXs,
  xl,
} from 'styled-bootstrap-responsive-breakpoints';
import CallToActionForm from '../../../containers/CallToActionForm';
import TextColumn from '../TextColumn';
import { SectionText, SectionTitle } from '../Section';
import {
  brandColor,
  landingPageMargin,
  landingPageMarginSmall,
} from '../../../constants/styles-old';

const CTAWrapper = styled.div`
  background-image: url('${props => props.backgroundImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${brandColor};
  padding-left: ${landingPageMargin}rem;
  padding-right: ${landingPageMargin}rem;
  padding-top: ${landingPageMargin / 2}rem;
  padding-bottom: ${landingPageMargin / 2}rem;

  ${mediaBreakpointDownMd`
    padding-left: ${landingPageMarginSmall}rem;
    padding-right: ${landingPageMarginSmall}rem;
  `};
`;

const CTAContainer = styled.div`
  background-color: white;
  padding-left: ${landingPageMargin}rem;
  padding-right: ${landingPageMargin}rem;
  padding-top: ${landingPageMargin / 2}rem;
  padding-bottom: ${landingPageMargin / 2}rem;
  display: flex;
  flex-direction: row;
  max-width: ${xl}px;
  margin-left: auto;
  margin-right: auto;

  ${mediaBreakpointDownMd`
    flex-direction: column;
  `};

  ${mediaBreakpointDownXs`
    flex-direction: column;
    padding-left: ${landingPageMarginSmall}rem;
    padding-right: ${landingPageMarginSmall}rem;
  `};
`;

const CTAFormWrapper = styled.div`
  margin-left: ${landingPageMargin / 2}rem;
  width: 50%;

  ${mediaBreakpointDownMd`
    margin-left: 0;
    width: 100%;
  `};
`;

const CTATextColumn = styled(TextColumn)`
  ${mediaBreakpointDownMd`
    text-align: center;
  `};
`;

const CallToAction = ({ ctaTitle, ctaImage, ctaText, ...props }) => (
  <CTAWrapper backgroundImage={ctaImage} id="contact">
    <CTAContainer>
      <CTATextColumn>
        <SectionTitle>{ctaTitle}</SectionTitle>
        <SectionText dangerouslySetInnerHTML={{ __html: ctaText }} />
      </CTATextColumn>
      <CTAFormWrapper>
        <CallToActionForm {...props} />
      </CTAFormWrapper>
    </CTAContainer>
  </CTAWrapper>
);

CallToAction.propTypes = {
  ctaTitle: string.isRequired,
  ctaImage: string.isRequired,
  ctaText: string.isRequired,
};

export default CallToAction;
