import React from 'react';
import styled from 'styled-components';
import { bool, string, func, shape, oneOfType, number } from 'prop-types';
import { StyledInput, StyledLabel, Message } from '../HelenInput';
import { colors } from '../../constants/styles';

const PropTypes = {
  defaultValue: string,
  disabled: bool,
  error: string,
  id: oneOfType([string, number]),
  label: string,
  onBlur: func,
  onChange: func,
  onFocus: func,
  placeholder: string,
  required: bool,
  theme: shape({}),
};

const DefaultProps = {
  defaultValue: '',
  disabled: false,
  error: null,
  id: null,
  label: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  placeholder: null,
  required: false,
  theme: colors.helenInputTheme,
};

const StyledTextArea = StyledInput.withComponent('textarea');

const Container = styled.div`
  textarea {
    min-height: 100px;
  }
`;

const Asterisk = styled.span`
  color: ${colors.night};
  display: inline-block;
  margin-left: 0.25rem;
`;

const HelenTextArea = ({
  defaultValue,
  disabled,
  error,
  id,
  label,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  theme = colors.helenInputTheme,
}) => (
  <Container>
    {label ? (
      <StyledLabel error={error} htmlFor={id} theme={theme}>
        <span>{label}</span>
        {required ? <Asterisk>*</Asterisk> : null}
      </StyledLabel>
    ) : null}

    <StyledTextArea
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      id={id}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      theme={theme}
    />
    {error ? <Message error={error}>{error}</Message> : null}
  </Container>
);

HelenTextArea.propTypes = PropTypes;
HelenTextArea.defaultProps = DefaultProps;

export default HelenTextArea;
