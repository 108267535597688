import { func, number, shape } from 'prop-types';
import React, { useState } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import styled, { createGlobalStyle } from 'styled-components';

import FormSection from './FormSection';
import HeroSection from './HeroSection';
import SummarySection from './SummarySection';
import ThanksSection from './ThanksSection';

import LogoSource from '../../assets/helen-logo-white.svg';
import { helenFontFamilySansSerif } from '../../constants/styles-old';
// // import withAnalytics from '../../lib/Analytics';
import { KIINTEISTOVAHTI_SERVICE_TYPES } from '../../constants/kiinteistovahti-service-types';
import { colors } from '../../constants/styles';

const BodyColor = createGlobalStyle`
  body {
    background-color: ${colors.helenBackground};
  }
`;

const Container = styled.div`
  font-family: ${helenFontFamilySansSerif};
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  padding-left: 1.5rem;
  z-index: 100;
`;

const Logo = styled.img`
  height: 55px;
  width: 177px;
`;

const animDuration = 500;

const defaultValues = {
  apartmentCount: '',
  billingAddress: '',
  companyId: '',
  heatingGroupId: '',
  housingCompanyAddress: '',
  housingCompanyCity: '',
  housingCompanyName: '',
  housingCompanyZip: '',
  isHelenCustomer: 0,
  managerName: '',
  meterCount: '',
  ordererAddress: '',
  ordererCity: '',
  ordererEmail: '',
  ordererFirstName: '',
  ordererLastName: '',
  ordererPhone: '',
  ordererRole: 'manager',
  ordererZip: '',
  otherUsers: [{ email: '', role: 'board' }],
  serviceType: KIINTEISTOVAHTI_SERVICE_TYPES.FIXED,
};

const PageContainer = styled.div`
  background: ${colors.helenBackground};
  position: relative;
  width: 100%;
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;

  .carousel-swap-left-leave,
  .carousel-swap-right-leave {
    transition: transform ${animDuration}ms ease-in-out,
      opacity ${animDuration}ms ease-out;
    transform: translate(0, 0);
    opacity: 1;
  }

  .carousel-swap-left-leave-active {
    transform: translate(-100%, 0);
    opacity: 0;
  }

  .carousel-swap-right-leave-active {
    transform: translate(100%, 0);
    opacity: 0;
  }

  .carousel-swap-left-enter {
    transition: transform ${animDuration}ms ease-in-out,
      opacity ${animDuration}ms ease-in;
    transform: translate(100%, 0);
    opacity: 0;
  }

  .carousel-swap-right-enter {
    transition: transform ${animDuration}ms ease-in-out,
      opacity ${animDuration}ms ease-in;
    transform: translate(-100%, 0);
    opacity: 0;
  }

  .carousel-swap-left-enter-active,
  .carousel-swap-right-enter-active {
    opacity: 1;
    transform: translate(0, 0);
  }

  .carousel-swap-left-height,
  .carousel-swap-right-height {
    transition: height ${animDuration}ms ease-in-out;
  }
`;

const Page = styled.div`
  width: 100%;
`;

const Page1 = props => (
  <Page>
    <HeroSection {...props} />
    <FormSection {...props} />
  </Page>
);

const Page2 = props => (
  <Page>
    <SummarySection {...props} />
  </Page>
);

const Page3 = props => (
  <Page>
    <ThanksSection {...props} />
  </Page>
);

const getTransitionName = ({ nextPage, prevPage }) => {
  if (prevPage > nextPage) {
    return 'carousel-swap-right';
  }

  return 'carousel-swap-left';
};

const transformData = ({ isHelenCustomer, otherUsers, ...data }) => ({
  ...data,
  isHelenCustomer: Boolean(isHelenCustomer),
  otherUsers: otherUsers.filter(u => u.email),
});

const transformDataBack = ({ isHelenCustomer, ...data }) => ({
  ...data,
  isHelenCustomer: Number(isHelenCustomer),
});

const OrderForm = ({
  handleSubmit,
  initialValues,
  //  onGaPageView,
  ...props
}) => {
  const mergedValues = {
    ...defaultValues,
    ...initialValues,
  };

  const [{ nextPage, prevPage }, setPageHelpers] = useState({
    nextPage: 1,
    prevPage: 1,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [currentValues, setValues] = useState(mergedValues);
  const [submitError, setSubmitError] = useState(null);
  return (
    <Container>
      <BodyColor />
      <PageContainer>
        <LogoContainer>
          <Logo src={LogoSource} alt="helen-logo" />
        </LogoContainer>
        <ReactCSSTransitionReplace
          transitionName={getTransitionName({ prevPage, nextPage })}
          transitionEnterTimeout={animDuration * 2}
          transitionLeaveTimeout={animDuration * 2}
        >
          <div key={currentPage}>
            {currentPage === 1 && (
              <Page1
                initialValues={currentValues}
                isInitialValid
                onSubmit={rawData => {
                  const data = transformData(rawData);
                  const {
                    meterCount,
                    baseFee,
                    meterBaseFee,
                    monthlyBaseFee,
                  } = data;
                  const monthlyPrice = meterCount * monthlyBaseFee;
                  const annualPrice = monthlyPrice * 12;
                  setValues({
                    ...data,
                    annualPrice,
                    monthlyBaseFee,
                    monthlyPrice,
                    baseFee,
                    meterBaseFee,
                  });
                  setPageHelpers({
                    nextPage: 2,
                    prevPage: 1,
                  });
                  setCurrentPage(2);
                  // onGaPageView('/order?page=1');
                  window.scrollTo(0, 0);
                }}
                {...props}
              />
            )}
            {currentPage === 2 && (
              <Page2
                data={currentValues}
                goBack={() => {
                  setValues(transformDataBack(currentValues));
                  setSubmitError(null);
                  setPageHelpers({
                    nextPage: 1,
                    prevPage: 2,
                  });
                  // onGaPageView('/order?page=2');
                  setCurrentPage(1);
                }}
                onSubmit={async (_, formikBag) => {
                  try {
                    await handleSubmit(currentValues);
                    setSubmitError(null);
                    setPageHelpers({
                      nextPage: 3,
                      prevPage: 2,
                    });
                    setCurrentPage(3);
                    // onGaPageView('/order?page=3');
                    window.scrollTo(0, 0);
                  } catch (err) {
                    formikBag.setSubmitting(false);
                    setSubmitError(err.message);
                  }
                }}
                submitError={submitError}
                {...props}
              />
            )}
            {currentPage === 3 && <Page3 {...props} />}
          </div>
        </ReactCSSTransitionReplace>
      </PageContainer>
    </Container>
  );
};

OrderForm.propTypes = {
  handleSubmit: func.isRequired,
  initialValues: shape({}),
  monthlyBaseFee: number.isRequired,
  // onGaPageView: func.isRequired,
  baseFee: number.isRequired,
  meterBaseFee: number.isRequired,
};

OrderForm.defaultProps = {
  initialValues: {},
};

// export default withAnalytics(OrderForm);
export default OrderForm;
