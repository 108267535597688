/* eslint-disable import/prefer-default-export */
import fonts from './fonts';

const headerStyle = {
  fontWeight: 500,
  fontSize: fonts.sizes.smaller,
  lineHeight: '15px',
  textTransform: 'none',
  fontFamily: fonts.families.fontFamilySansSerif,
  letterSpacing: 'initial',
  marginBottom: '5px',
};

export default {
  headerStyle,
};
