import React from 'react';

const Add = props => (
  <svg
    width="28px"
    height="27px"
    viewBox="0 0 28 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs />
    <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="NAV-1" transform="translate(-29.000000, -217.000000)">
        <g id="NAV">
          <path
            d="M51,236 L51,233 L49,233 L49,236 L46,236 L46,238 L49,238 L49,241 L51,241 L51,238 L54,238 L54,236 L51,236 Z M41.9355196,233 C41.4775737,233.921499 41.1729216,234.932621 41.0549288,236 L29,236 L29,233 L41.9355196,233 Z M29,217 L51,217 L51,220 L29,220 L29,217 Z M29,225 L51,225 L51,228 L29,228 L29,225 Z M50,244 C46.1340068,244 43,240.865993 43,237 C43,233.134007 46.1340068,230 50,230 C53.8659932,230 57,233.134007 57,237 C57,240.865993 53.8659932,244 50,244 Z"
            id="Add"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Add;
