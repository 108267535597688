import React, { useEffect, useState } from 'react';
import qs from 'qs';
import cookies from 'js-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import styled from 'styled-components';
import linkResolver from '../../lib/link-resolver';
import EmptyStatePage from '../../components/EmptyStatePage';
import { appBackgroundColor } from '../../constants/styles-old';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${appBackgroundColor};
`;

const apiEndpoint = process.env.REACT_APP_PRISMIC_API_URL;

const Preview = () => {
  const { search } = useLocation();
  const [error, setError] = useState(false);
  const history = useHistory();
  const { token } = qs.parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    const doRedirect = async () => {
      if (!token) {
        return;
      }
      try {
        const api = await Prismic.getApi(apiEndpoint);
        const url = await api.previewSession(token, linkResolver);
        cookies.set(Prismic.previewCookie, token, { expires: 7, path: '' });
        history.push(url);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setError(true);
      }
    };

    doRedirect();
  }, [token, history]);

  return (
    <Container>
      <EmptyStatePage
        title={
          error || !token
            ? 'Esikatselu epäonnistui'
            : 'Esikatselua valmistellaan'
        }
        description={
          error || !token
            ? 'Käynnistä esikatselu uudestaan Prismic:n kautta'
            : 'Ole hyvä ja odota hetki...'
        }
      />
    </Container>
  );
};

export default Preview;
