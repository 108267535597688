import React, { useState } from 'react';
import { bool, string, func, shape, number, oneOfType } from 'prop-types';
import { Dialog } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import styled, { ThemeProvider } from 'styled-components';
import { QrReader } from 'react-qr-reader';

import FieldInput from '../FieldInput';
import { fonts } from '../../constants/styles';
import { inputThemes } from '../../constants/styles-old';
import Button from '../Button';

const PropTypes = {
  onChange: func.isRequired,
  placeholder: string,
  setFieldTouched: func.isRequired,
  setFieldValue: func.isRequired,
  id: oneOfType([string, number]).isRequired,
  name: string,
  label: string,
  error: string,
  theme: shape({}),
  defaultValue: string,
  disabled: bool,
  onSelect: func,
  type: string,
};

const DefaultProps = {
  placeholder: null,
  label: null,
  error: null,
  name: null,
  theme: inputThemes.light,
  defaultValue: '',
  disabled: false,
  onSelect: null,
  type: null,
};

const CloseButton = styled(Button)`
  position: absolute;
  background: none;
  border: none;
  top: 1rem;
  right: 1rem;
  height: 24px;
  width: 24px;
  padding: 0;

  > span:first-of-type {
    top: 2px;
    left: 2px;
  }
`;

const DialogTitle = styled.h2`
  font-family: ${fonts.families.helenFontFamilySansSerif};
  font-size: ${fonts.sizes.base};
  font-weight: bold;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const DeviceInputBase = ({ theme, type, disabled, setFieldValue, ...rest }) => {
  const [isQrReaderOpen, setQrReaderOpen] = useState(false);
  const [data, setData] = useState(undefined);

  return (
    <ThemeProvider theme={theme}>
      <Row>
        <FieldInput type={'text'} {...rest} />
        <Button
          disabled={disabled}
          size="small"
          onClick={() => {
            setQrReaderOpen(true);
          }}
        >
          {'Lue QR'}
        </Button>
      </Row>
      {isQrReaderOpen ? (
        <Dialog
          aria-label={'Lue QR koodi'}
          isOpen={isQrReaderOpen}
          onDismiss={() => setQrReaderOpen(false)}
          style={{
            position: 'relative',
            margin: '30px auto auto',
            maxWidth: '300px',
            minWidth: '300px',
          }}
        >
          <CloseButton
            type="button"
            className="close-button"
            onClick={() => setQrReaderOpen(false)}
          >
            <VisuallyHidden>{'dialogCloseText'}</VisuallyHidden>
          </CloseButton>
          <DialogTitle>{'Lue QR koodi'}</DialogTitle>
          <QrReader
            constraints={{ facingMode: 'environment', focusMode: 'continuous' }}
            onResult={(result, error) => {
              if (result) {
                setData(result?.text?.split(',')[0].replace(/^0+/, ''));
                setFieldValue(
                  rest.id,
                  result?.text?.split(',')[0].replace(/^0+/, ''),
                );
                setQrReaderOpen(false);
              }

              if (error) {
                console.error(error);
              }
            }}
            style={{ width: '100%' }}
          />
          <p>{data}</p>
        </Dialog>
      ) : null}
    </ThemeProvider>
  );
};

DeviceInputBase.propTypes = PropTypes;
DeviceInputBase.defaultProps = DefaultProps;

export default DeviceInputBase;
