import React, { Fragment } from 'react';
import { bool, func, number } from 'prop-types';
import StyledModal from '../StyledModal';
import EditObservationsList from '../Tables/ObservationStationsList/EditObservationsList';

const PropTypes = {
  closeModal: func,
  isOpen: bool,
  targetTemperature: number,
};

const DefaultProps = {
  closeModal: () => {},
  isOpen: false,
  targetTemperature: 21.0,
};

const EditObservationStationsModal = ({ isOpen, closeModal, ...rest }) => {
  const label = 'FMI sääasemat';
  return (
    <StyledModal
      closeButtonMarginTop="1.85rem"
      closeModal={closeModal}
      contentLabel={label}
      contentPadding="1rem 0rem"
      contentWidth="58rem"
      isOpen={isOpen}
      render={({ ModalTitle }) => (
        <Fragment>
          <ModalTitle key="ObservationStationsModalTitle">
            <span>{label}</span>
          </ModalTitle>
          <EditObservationsList closeModal={closeModal} {...rest} />
        </Fragment>
      )}
      titlePadding="1rem 2rem 1.5rem 2rem"
    />
  );
};

EditObservationStationsModal.propTypes = PropTypes;
EditObservationStationsModal.defaultProps = DefaultProps;

export default EditObservationStationsModal;
