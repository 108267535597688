export const getTabSlug = location => {
  if (!location || !location.pathname) {
    return null;
  }
  const { pathname } = location;
  let tabSlug = null;

  try {
    const [, , , , , lastSlug] = pathname.split('/');

    if (lastSlug) {
      tabSlug = lastSlug;
    }
  } catch (e) {
    tabSlug = null;
  }

  return tabSlug;
};

export const getStaircasesPath = staircases =>
  (staircases || '-').toLowerCase();

export default { getTabSlug };
