// This is needed because IE11 does not support classList for SVG elements
// https://github.com/Pomax/react-onclickoutside#ie-does-not-support-classlist-for-svg-elements

if (!('classList' in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, 'classList', {
    get() {
      return {
        contains: className =>
          this.className.baseVal.split(' ').indexOf(className) !== -1,
      };
    },
  });
}
