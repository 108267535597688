import { Router, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { node, func, oneOfType } from 'prop-types';
import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { LoadScript } from '@react-google-maps/api';
import { AuthProvider } from '../lib/Auth';
import HistoryStackContext from '../lib/HistoryStackContext';
import { ApolloProvider, client } from './apollo-client-setup';
import getStore from './get-store';
import AppContainer from '../containers/App';
import { appBackgroundColor } from '../constants/styles-old';

const history = createBrowserHistory({});
const store = getStore(history);
const { dispatch } = store;
const libraries = ['geometry', 'drawing', 'places'];
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const HistoryProvider = ({ children }) => {
  const domLocation = useLocation();
  const [locationHistory, setLocationHistory] = useState([
    domLocation.pathname,
  ]);

  history.listen(location => {
    const isRefresh =
      locationHistory[locationHistory.length - 1] === location.pathname;
    const isRootUrl = location.pathname.split('/').filter(Boolean).length === 1;
    const isBackNavigating =
      locationHistory[locationHistory.length - 2] === location.pathname;

    if (isRefresh) {
      return;
    }

    if (isRootUrl) {
      setLocationHistory([]);
    }

    if (isBackNavigating) {
      const newHistory = [...locationHistory];
      newHistory.pop();
      setLocationHistory(newHistory);
      return;
    }

    setLocationHistory([...locationHistory, location.pathname]);
  });

  return (
    <HistoryStackContext.Provider value={locationHistory}>
      {children}
    </HistoryStackContext.Provider>
  );
};

HistoryProvider.propTypes = {
  children: oneOfType([node, func]).isRequired,
};

export default props => {
  return (
    <AuthProvider history={history} dispatch={dispatch}>
      {({ auth }) => {
        const clientWithAuth = client(auth);

        return (
          <LoadScript
            id="script-loader"
            libraries={libraries}
            googleMapsApiKey={apiKey}
            version="3.40"
            loadingElement={
              <div
                style={{
                  backgroundColor: appBackgroundColor,
                  height: '100%',
                  width: '100%',
                }}
              />
            }
          >
            <ApolloProvider client={clientWithAuth}>
              <Provider store={store}>
                <Router history={history}>
                  <HelmetProvider>
                    <HistoryProvider>
                      <AppContainer {...props} />
                    </HistoryProvider>
                  </HelmetProvider>
                </Router>
              </Provider>
            </ApolloProvider>
          </LoadScript>
        );
      }}
    </AuthProvider>
  );
};
