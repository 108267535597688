import roles from '../user-roles';

export const superAdminAccessValidator = ({
  'https://raportointi.enne.helen.fi/role': role,
}) => roles[role] > roles.admin;

export const adminAccessValidator = ({
  'https://raportointi.enne.helen.fi/role': role,
}) => roles[role] === roles.admin;

export const writeAccessValidator = ({
  'https://raportointi.enne.helen.fi/role': role,
}) => roles[role] > roles.read_only;
